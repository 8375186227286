<div class="modal-body">
  <app-loading
    [hidden]="!viewModel.loadingOpts?.isLoading"
    [options]="viewModel.loadingOpts">
  </app-loading>
  <div class="d-flex justify-content-between">
    <div class="modal-title" i18n>Add Team</div>
    <div
      class="text-link-button"
      [hidden]="hideCreateNewTeamButton"
      (click)="createNewTeamClicked()"
      i18n>
      Create New Team
    </div>
  </div>
  <app-form-group
    class="d-block pt-3"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formCancelled)="cancel()"
    (formSubmitted)="formSubmitted()">
  </app-form-group>
</div>
