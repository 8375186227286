import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {VenueDetailsViewModel} from '../venue-details-view-model';
import {FormInputItem, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {VenueFormObject} from '../../../../../models/resources/venue-form-object';
import {TeamFormObject} from '../../../../../models/resources/team-form-object';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime} from 'rxjs/operators';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {EditTeamModalComponent} from '../../../shared/edit-team-modal/edit-team-modal.component';
import {ToastService} from '../../../../../services/toast-service';
import {AddExistingTeamModalComponent} from '../../../shared/add-existing-team-modal/add-existing-team-modal.component';
import {HydratedTeam} from '../../../../../models/resources/hydrated-team';

@Component({
  selector: 'app-venue-teams',
  templateUrl: './venue-teams.component.html',
  styleUrls: ['./venue-teams.component.scss'],
})
export class VenueTeamsComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: VenueFormObject;
  private addTeamClicked = new EventEmitter();
  private editTeamClicked = new EventEmitter<TeamFormObject>();

  constructor(
    public viewModel: VenueDetailsViewModel,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupBindings() {
    this.setupFormStyling();

    this.viewModel.venue$
      .pipe(debounceTime(100))
      .subscribe(venue => {
        this.formObject = VenueFormObject.initWithVenue(venue);
        this.setupFormItems();
      }).addTo(this.subscriptions);

    this.addTeamClicked.subscribe(() => {
      this.addNewTeam();
    }).addTo(this.subscriptions);

    this.editTeamClicked.subscribe((t) => {
      this.editTeam(t);
    }).addTo(this.subscriptions);
  }

  setupFormBindings() {
  }

  setupViews() {
    this.setupFormOptions();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const teamsTitle = new FormInputItem();
    teamsTitle.itemType = FormItemType.Title;
    teamsTitle.label = $localize`Teams`;
    teamsTitle.titleButtonText = $localize`Add a Team`;
    teamsTitle.titleButtonClicked = this.addTeamClicked;
    teamsTitle.overrideFullWidth = true;
    items.push(teamsTitle);

    const teams = new FormInputItem();
    teams.inputName = 'teamFormObjects';
    teams.itemType = FormItemType.List;
    teams.bindingProperty = 'teamFormObjects';
    teams.listItemButtonText = $localize`Edit`;
    teams.listItemClicked = this.editTeamClicked;
    items.push(teams);

    this.formItems = items;
    this.setupFormBindings();
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: VenueFormObject) {
  }

  cancel() {
  }

  private addNewTeam() {
    const modalRef = this.modalService.open(
      AddExistingTeamModalComponent,
      ModalUtils.addTeamModalOptions(),
    );
    modalRef.result.then((existingTeam: HydratedTeam) => {
      if (existingTeam) {
        const tfo = TeamFormObject.initWithTeam(existingTeam);
        this.formObject.teamFormObjects.push(tfo);
        tfo.team.itemCreated = true;
        this.saveVenueTeamAssociation(tfo, modalRef);
      } else {
        this.createNewTeam();
      }
    }, reason => {});
  }

  private createNewTeam() {
    const modalRef = this.modalService.open(
      EditTeamModalComponent,
      ModalUtils.defaultModalOptions(),
    );
    const instance = (modalRef.componentInstance as EditTeamModalComponent);
    instance.initWithNewFormObject();
    // modalRef.result.then((teamFormObject: TeamFormObject) => {
    //   if (teamFormObject) {
    //     this.formObject.teamFormObjects.push(teamFormObject);
    //     this.saveVenueTeam(teamFormObject,modalRef);
    //   }
    // }, reason => {
    // });
    modalRef.componentInstance.returnResult.subscribe((teamFormObject: TeamFormObject) => {
      if (teamFormObject) {
        this.formObject.teamFormObjects.push(teamFormObject);
        this.saveVenueTeam(teamFormObject,modalRef);
      }
    }, reason => {
    });
  }

  private editTeam(teamFormObject: TeamFormObject) {
    const modalRef = this.modalService.open(
      EditTeamModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const instance = (modalRef.componentInstance as EditTeamModalComponent);
    instance.currentTeam = teamFormObject.team;
    instance.initWithFormObject(teamFormObject);
    // modalRef.result.then((updatedTeamFormObject: TeamFormObject) => {
    //   if (updatedTeamFormObject) {
    //     const index = this.formObject.teamFormObjects.indexOf(teamFormObject);
    //     this.formObject.teamFormObjects[index] = updatedTeamFormObject;
    //     if (updatedTeamFormObject.team.itemDeleted) {
    //       this.saveVenueTeamAssociation(updatedTeamFormObject);
    //       this.formObject.teamFormObjects.splice(index, 1);
    //     } else {
    //       this.saveVenueTeam(updatedTeamFormObject);
    //     }
    //   }
    // }, reason => {
    // });
    modalRef.componentInstance.returnResult.subscribe((updatedTeamFormObject: TeamFormObject) => {

      const index = this.formObject.teamFormObjects.indexOf(teamFormObject);
        this.formObject.teamFormObjects[index] = updatedTeamFormObject;
        if (updatedTeamFormObject.team.itemDeleted) {
          this.saveVenueTeamAssociation(updatedTeamFormObject, modalRef);
          // this.formObject.teamFormObjects.splice(index, 1);
        } else {
          this.saveVenueTeam(updatedTeamFormObject,modalRef);
        }
    }, reason => {
    });
  }

  saveVenueTeam(teamFormObject: TeamFormObject,ref) {
    this.setLoadingOpts(ref, true);
    this.viewModel.saveVenueTeam(teamFormObject).subscribe((updatedTeam: any) => {
      ref.componentInstance.concurrentUpdateTeam.next(null);
      this.toastService.publishSuccessMessage($localize`Venue Team Saved`, null);
      // const index = this.formObject.teamFormObjects.indexOf(teamFormObject);
      const index = this.formObject.teamFormObjects.findIndex(s => s.team.id === teamFormObject.team.id);
      this.formObject.teamFormObjects[index].team.id = updatedTeam ? updatedTeam?.teamId : this.formObject.teamFormObjects[index].team.id;
      this.formObject.teamFormObjects[index].itemCreated = false;
      this.formObject.teamFormObjects[index].itemChanged = false;
      this.setLoadingOpts(ref, false);
      this.modalService.dismissAll();
    }, error => {
      //for concurrent error
      if(error.code === 400){
        ref.componentInstance.concurrentUpdateTeam.next(teamFormObject);
        this.setLoadingOpts(ref, false);
      }
      //for another error-close model
      else{
        this.modalService.dismissAll();
      }
      this.toastService.publishErrorMessage(error.message, $localize`Venue Team Save Failed`);
    });
  }

  setLoadingOpts(ref, flag) {
    ref.componentInstance.isLoading = flag;
  }

  saveVenueTeamAssociation(teamFormObject: TeamFormObject, ref) {
    this.setLoadingOpts(ref, true);
    this.viewModel.saveVenueTeamAssociation(teamFormObject.team).subscribe((updatedTeamId) => {
      const toastMsg = teamFormObject.team?.itemDeleted ? $localize`League Team Deleted` : $localize`League Team Saved`;
      this.setLoadingOpts(ref, false);
      this.toastService.publishSuccessMessage(toastMsg, null);
      this.modalService.dismissAll();
      const index = this.formObject.teamFormObjects.indexOf(teamFormObject);
      if(teamFormObject.team?.itemDeleted) {
        this.formObject.teamFormObjects.splice(index, 1);
      }
      this.formObject.teamFormObjects[index].team.id = updatedTeamId.teamId;
      this.formObject.teamFormObjects[index].itemCreated = false;
      this.formObject.teamFormObjects[index].itemChanged = false;
    }, error => {
      this.setLoadingOpts(ref, false);
      this.toastService.publishErrorMessage(error.message, $localize`League Team Save Failed`);
    });
  }

}
