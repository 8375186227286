import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormInputItem, FormInputType, FormItemType } from '../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../models/shared/stylesheet/form-group-styling';
import { FormOptions } from '../../../../models/shared/stylesheet/form-options';
import { BaseModal } from '../../../../models/base/base-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../../models/account/dto/user';
import { DeserializeHelper } from '../../../../models/protocols/deserializable';
import { AdminUsersViewModel } from '../admin-users/admin-users-view-model';
import ConflictRecordUtils from '../../../../utils/conflict-record-utils';
@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
})
export class EditUserModalComponent extends BaseModal {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: User;
  public concurrentUpdateFlag: boolean = false;
  currentUser: any = null;
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];
  isLoading: boolean = false;
  @Input() concurrentUpdate$: EventEmitter<any> = new EventEmitter();
  @Output() modalFormData: EventEmitter<any> = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
    public viewModel: AdminUsersViewModel
  ) {
    super();
  }

  initWithUser(user: User) {
    this.currentUser = JSON.parse(JSON.stringify(user));
    setTimeout(() => {
      this.formObject = DeserializeHelper.deserializeToInstance(User, user);
    });
  }

  setupBindings() {
    this.concurrentUpdate$.subscribe(conflictObject => {
      // If concurrent issue occurs
      if (conflictObject) {
        this.viewModel.getAdmin(conflictObject.id).subscribe(refreshUser => {
          this.concurrentUpdateFlag = true;
          this.compareObjects(this.currentUser, conflictObject, refreshUser);
          this.setUserObjAfterConflict(conflictObject, refreshUser);
          this.formObject = DeserializeHelper.deserializeToInstance(User, refreshUser);
          this.setupFormItems();
        });
      } else {
        this.concurrentUpdateFlag = false;
        this.setupFormItems();
      }
    });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupFormItems() {
    const userChanges = {
      currentUserChanges: this.currentUserChanges,
      anotherUserChanges: this.anotherUserChanges
    };

    const items: FormInputItem[] = [];

    const firstName = new FormInputItem();
    firstName.inputName = 'firstName';
    firstName.inputType = FormInputType.Text;
    firstName.label = $localize`First Name`;
    firstName.placeholder = $localize`First Name`;
    firstName.bindingProperty = 'firstName';
    firstName.required = true;
    const firstNameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentUser, 'First name', 'firstName') : '';
    firstName.conflictType = firstNameData?.borderColor || firstNameData;
    firstName.tooltipText = firstNameData?.tooltipText;
    items.push(firstName);

    const lastName = new FormInputItem();
    lastName.inputName = 'lastName';
    lastName.inputType = FormInputType.Text;
    lastName.label = $localize`Last Name`;
    lastName.placeholder = $localize`Last Name`;
    lastName.bindingProperty = 'lastName';
    lastName.required = true;
    const lastNameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentUser, 'Last name', 'lastName') : '';
    lastName.conflictType = lastNameData?.borderColor || lastNameData;
    lastName.tooltipText = lastNameData?.tooltipText;
    items.push(lastName);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'active';
    active.overrideFullWidth = true;
    active.customClass = 'mt-0';
    const userStatusData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentUser, 'Status', 'active') : '';
    active.conflictType = userStatusData?.borderColor || userStatusData;
    active.tooltipText = userStatusData?.tooltipText;
    items.push(active);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save Changes`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    // this.activeModal.close(this.formObject);
    this.modalFormData.emit(this.formObject);
  }

  compareObjects(currentObj, conflictObj, refreshObj) {
    const currentConflictObj = ConflictRecordUtils.diff(currentObj, conflictObj, 'red');
    const currentRefreshObj = ConflictRecordUtils.diff(currentObj, refreshObj, 'orange');
    this.currentUserChanges = currentRefreshObj.currentUserChanges;
    this.currentUserChangesWithNestedProp = currentRefreshObj.currentUserChangesWithNestedProp;
    this.anotherUserChanges = currentRefreshObj.anotherUserChanges;
  }

  setUserObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      const val = ConflictRecordUtils.getObjProp(conflictObj, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }

  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }
}
