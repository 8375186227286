import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, EMPTY, of, Subject} from 'rxjs';
import {map, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {ToastService} from '../../../../services/toast-service';
import {ActivatedRoute, Router} from '@angular/router';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {AdvertisingDomainModel} from '../../../../domainModels/advertising-domain-model';
import {AdvertisementFormObject} from '../../../../models/resources/advertisement-form-object';
import {HydratedAdvertisement} from '../../../../models/resources/hydrated-advertisement';

@Injectable()
export class AdvertisementDetailsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  formErrorMessage = new BehaviorSubject<string>(null);

  loadingAdvertisementMessage = $localize`Loading Advertisement Details`;
  advertisement$: BehaviorSubject<HydratedAdvertisement> = new BehaviorSubject<HydratedAdvertisement>(null);
  addNew: boolean = false;
  partnerId$ = new BehaviorSubject<number>(null);
  fetchAdSubject = new BehaviorSubject<void>(null);
  fetchAd = this.fetchAdSubject
    .pipe(
      tap(() => this.loadingOpts.addRequest(this.loadingAdvertisementMessage)),
      switchMapTo(this.activatedRoute.params),
      switchMap(params => {
        this.addNew = !params.advertisementId;
        if (!!params.partnerId) {
          this.partnerId$.next(Number(params.partnerId));
          return this.addNew ? of(new HydratedAdvertisement())
            : this.domainModel.getAdvertisingPartnerAdvertisement(params.partnerId, params.advertisementId);
        } else {
          return EMPTY;
        }
      }),
      indicateOnNext(this.loadingOpts, this.loadingAdvertisementMessage),
    ).subscribe(this.advertisement$);

  updateFormItemStatesSubject$ = new Subject<void>();

  adUploads$ = this.activatedRoute.params.pipe(
    map(params => params?.advertisementId),
    switchMap(aId => {
      if (!aId) {
        return of([]);
      } else {
        return this.domainModel.getAdvertisementUploads(aId);
      }
    }),
  );

  priorityTypes$ = this.lookupDomainModel.advertisementPriorityTypes;
  concurrentUpdate$ = new BehaviorSubject<AdvertisementFormObject>(null);

  refreshAdvertisementSubject$ = new BehaviorSubject<any>(null);
  refreshHydratedAdvertisement$ = this.refreshAdvertisementSubject$.pipe(
    switchMapTo(this.activatedRoute.params),
    switchMap(params => {
      return this.domainModel.getAdvertisingPartnerAdvertisement(params.partnerId, params.advertisementId);
    })
  );
  constructor(
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private domainModel: AdvertisingDomainModel,
    private lookupDomainModel: LookupDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  clearFormErrors() {
    this.formErrorMessage.next(null);
  }

  saveAdvertisement(formObject: AdvertisementFormObject) {
    formObject.advertisement.advertisingPartnerId = this.partnerId$.getValue();
    this.domainModel.saveAdvertisement(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Advertisement`))
      .subscribe(() => {
        this.concurrentUpdate$.next(null);
        this.toastService.publishSuccessMessage($localize`Advertisement Saved`, null);
        this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
      }, error => {
        if (error.code === 400) {
          this.concurrentUpdate$.next(formObject);
        }
        this.toastService.publishError(error);
      });
  }
}
