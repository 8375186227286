import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { AdvertisingDomainModel } from 'src/app/domainModels/advertising-domain-model';
import { map, switchMap } from 'rxjs/operators';
import { LoadingOptions } from 'src/app/models/shared/loading-options';
import { indicateOnNext } from 'src/app/utils/observable.extensions';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class EditAdvertisingPartnerModalViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);

  partnerId$ = this.activatedRoute.params.pipe(
    map(params => params?.partnerId),
  );

  refreshPartner$ = new BehaviorSubject<void>(undefined);
  advertPartner$ = combineLatest([this.partnerId$, this.refreshPartner$]).pipe(
    switchMap(([id, _]) => {
      return this.domainModel.getAdvertisingPartner(id).pipe(indicateOnNext(this.loadingOpts, $localize`Loading Advertising Partner`));
    })
  );
  constructor(private domainModel: AdvertisingDomainModel,
    private activatedRoute: ActivatedRoute) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  getAdvertisingPartnerAfterConcurrent(id): Observable<any>{
    return this.domainModel.getAdvertisingPartner(id);
  }
}
