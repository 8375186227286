// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    mainServiceUrl: 'https://amazatic-dev.api.hometeamlive.com',
    cognitoClientSecret: '',
    freePlanId: 'HomeTeam-Free',
    freePlanPriceId: 'HomeTeam-Free-CAD-Monthly',
    googleAnalyticsTackingCode: 'G-EB6SFQDTDL',
  };
