import { Input, Output, EventEmitter } from '@angular/core';
import { FormInputItem, FormInputType, FormItemType } from '../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../models/shared/stylesheet/form-group-styling';
import { FormOptions } from '../../../../models/shared/stylesheet/form-options';
import { SeasonFormObject } from '../../../../models/resources/season-form-object';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModal } from '../../../../models/base/base-modal';
import { Component } from '@angular/core';
import { Season } from '../../../../models/resources/season';
import { LeagueDetailsViewModel } from '../../leagues/league-details/league-details-view-model';
import ConflictRecordUtils from '../../../../utils/conflict-record-utils';
import {DateUtils} from '../../../../utils/date-utils';

@Component({
  selector: 'app-edit-season-modal',
  templateUrl: './edit-season-modal.component.html',
  styleUrls: ['./edit-season-modal.component.scss'],
  providers: [LeagueDetailsViewModel]
})

export class EditSeasonModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: SeasonFormObject;
  isEditing: boolean = false;
  canRemoveSeasonOnEdit: boolean = true;

  public concurrentUpdateFlag: boolean = false;
  season: SeasonFormObject = null;
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];
  isLoading: boolean = false;
  @Input() concurrentUpdate$: EventEmitter<any> = new EventEmitter();
  @Output() modalFormData: EventEmitter<any> = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public viewModel: LeagueDetailsViewModel,
  ) {
    super();
  }

  setupBindings() {
    this.concurrentUpdate$.subscribe(conflictObject => {
      // If concurrent issue occurs
      if (conflictObject) {
        this.viewModel.getSeason(Number(conflictObject.leagueId), conflictObject.seasonId).subscribe(refreshSeason => {
          conflictObject.seasonStartDate = DateUtils.formatTimeDateInputString(conflictObject.seasonStartDate);
          conflictObject.seasonEndDate = DateUtils.formatTimeDateInputString(conflictObject.seasonEndDate);

          refreshSeason = SeasonFormObject.initWithSeason(refreshSeason);
          this.concurrentUpdateFlag = true;
          this.compareObjects(this.season, conflictObject, refreshSeason);
          this.setUserObjAfterConflict(conflictObject, refreshSeason);
          this.formObject = refreshSeason;
          this.setupFormItems();
        });
      } else {
        this.concurrentUpdateFlag = false;
      }
    });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  initWithNewFormObject() {
    this.formObject = SeasonFormObject.initWithSeason(new Season());
    this.season = JSON.parse(JSON.stringify(this.formObject));
    this.formObject.itemCreated = true;
  }

  initWithFormObject(seasonFormObject: SeasonFormObject) {
    this.isEditing = true;
    this.formObject = seasonFormObject.getCopy();
    this.formObject.itemChanged = true;
  }


  setupFormItems() {
    const userChanges = {
      currentUserChanges: this.currentUserChanges,
      anotherUserChanges: this.anotherUserChanges
    };

    const items: FormInputItem[] = [];

    const seasonName = new FormInputItem();
    seasonName.inputName = 'seasonName';
    seasonName.inputType = FormInputType.Text;
    seasonName.label = $localize`Season Name`;
    seasonName.placeholder = $localize`Season Name`;
    seasonName.bindingProperty = 'seasonName';
    seasonName.required = true;
    const seasonNameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.season, 'Season name', 'seasonName') : '';
    seasonName.conflictType = seasonNameData?.borderColor || seasonNameData;
    seasonName.tooltipText = seasonNameData?.tooltipText;
    items.push(seasonName);

    const seasonStartDate = new FormInputItem();
    seasonStartDate.itemType = FormItemType.Input;
    seasonStartDate.inputType = FormInputType.Date;
    seasonStartDate.inputName = 'seasonStartDate';
    seasonStartDate.label = $localize`Season Start Date`;
    seasonStartDate.placeholder = $localize`Choose Date`;
    seasonStartDate.bindingProperty = 'seasonStartDate';
    seasonStartDate.required = true;
    const startDateData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.season, 'Start date', 'seasonStartDate') : '';
    seasonStartDate.conflictType = startDateData?.borderColor || startDateData;
    seasonStartDate.tooltipText = startDateData?.tooltipText;
    items.push(seasonStartDate);

    const seasonEndDate = new FormInputItem();
    seasonEndDate.itemType = FormItemType.Input;
    seasonEndDate.inputType = FormInputType.Date;
    seasonEndDate.inputName = 'seasonEndDate';
    seasonEndDate.label = $localize`Season End Date`;
    seasonEndDate.placeholder = $localize`Choose Date`;
    seasonEndDate.bindingProperty = 'seasonEndDate';
    seasonEndDate.required = true;
    const endDateData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.season, 'End date', 'seasonEndDate') : '';
    seasonEndDate.conflictType = endDateData?.borderColor || endDateData;
    seasonEndDate.tooltipText = endDateData?.tooltipText;
    items.push(seasonEndDate);

    this.formItems = items;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.submitButtonText = $localize`Save Season`;
  }

  formSubmitted() {
    // this.activeModal.close(this.formObject);
    this.modalFormData.emit(this.formObject);
  }
  deleteSeasonClicked() {
    this.formObject.itemDeleted = true;
    this.modalFormData.emit(this.formObject);
    // this.activeModal.close(this.formObject);
  }
  cancel() {
    this.activeModal?.dismiss();
  }

  getModalTitle() {
    return this.isEditing ? $localize`Edit Season` : $localize`Add New Season`;
  }

  compareObjects(currentObj, conflictObj, refreshObj) {
    const currentConflictObj = ConflictRecordUtils.diff(currentObj, conflictObj, 'red');
    const currentRefreshObj = ConflictRecordUtils.diff(currentObj, refreshObj, 'orange');
    this.currentUserChanges = currentRefreshObj.currentUserChanges;
    this.currentUserChangesWithNestedProp = currentRefreshObj.currentUserChangesWithNestedProp;
    this.anotherUserChanges = currentRefreshObj.anotherUserChanges;
  }

  setUserObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      const val = ConflictRecordUtils.getObjProp(conflictObj, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }

  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }
}
