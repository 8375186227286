import {EventEmitter, Injectable, Output} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, Observable, combineLatest, merge, of} from 'rxjs';
import {HydratedProgram} from '../../../../models/program/hydrated-program';
import {filter, map, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {ToastService} from '../../../../services/toast-service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminProgramDomainModel} from '../../../../domainModels/admin-program-domain-model';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {ProgramFormObject} from '../../../../models/program/program-form-object';
import {Selectable} from '../../../../models/protocols/selectable';
import {DateUtils} from '../../../../utils/date-utils';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ProductionType} from '../../../../models/lookup/production-type';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {HydratedEvent} from '../../../../models/resources/hydrated-event';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';
import * as moment from 'moment-timezone';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class ProgramDetailsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  formErrorMessage = new BehaviorSubject<string>(null);

  loadingProgramMessage = $localize`Loading Program Details`;
  program$: BehaviorSubject<HydratedProgram> = new BehaviorSubject<HydratedProgram>(null);

  addNew: boolean = false;
  leagueId$ = new BehaviorSubject<number>(null);
  venueId$ = new BehaviorSubject<number>(null);
  eventId$ = new BehaviorSubject<number>(null);
  fetchProgramSubject = new BehaviorSubject<void>(null);
  fetchProgram = this.fetchProgramSubject
    .pipe(
      tap(() => this.loadingOpts.addRequest(this.loadingProgramMessage)),
      switchMapTo(this.activatedRoute.params),
      switchMap(params => {
        this.addNew = !params.programId;
        if (!!params.leagueId) {
          this.leagueId$.next(params.leagueId);
        } else if (!!params.venueId) {
          this.venueId$.next(params.venueId);
        } else if (!!params.eventId) {
          this.eventId$.next(params.eventId);
        }
        return this.addNew ? of(this.getDefaultHydratedProgram(false)) : this.domainModel.getHydratedProgram(params.programId);
      }),
      indicateOnNext(this.loadingOpts, this.loadingProgramMessage),
    ).subscribe(this.program$);
  productionTypes$ = this.lookupDomainModel.productionTypes;

  showStreamDetails$ = new BehaviorSubject<boolean>(false);
  fetchShowStreamDetails = this.program$.notNull().subscribe((p) => {
    this.showStreamDetails$.next(p.productionTypeId === ProductionType.PremiumId);
  }).addTo(this.subscriptions);

  hydratedLeague$ = new BehaviorSubject<HydratedLeague>(null);
  fetchLeague = this.leagueId$.pipe(
    filter(lId => !!lId),
    switchMap(lId => this.resourceDomainModel.getHydratedLeague(lId))).subscribe(this.hydratedLeague$);
  leagueHydratedVenues$ = this.hydratedLeague$.notNull().pipe(
    switchMap(league => this.resourceDomainModel.getHydratedVenuesByIds(league.associatedVenues.map(av => av.id)))
  );

  hydratedEvent$ = new BehaviorSubject<HydratedEvent>(null);
  fetchEvent = this.eventId$.pipe(
    filter(eId => !!eId),
    switchMap(eId => this.resourceDomainModel.getHydratedEvent(eId))).subscribe(this.hydratedEvent$);
  eventHydratedVenues$ = this.hydratedEvent$.notNull().pipe(
    switchMap(event => this.resourceDomainModel.getHydratedVenuesByIds(event.associatedVenues.map(av => av.id)))
  );

  hydratedVenue$ = new BehaviorSubject<HydratedVenue>(null);
  fetchVenue = this.venueId$.pipe(
    filter(vId => !!vId),
    switchMap(vId => this.resourceDomainModel.getHydratedVenue(vId))).subscribe(this.hydratedVenue$);

  filteredTeams$ = combineLatest([this.hydratedLeague$, this.hydratedVenue$, this.hydratedEvent$]).pipe(
    map(([league, venue, event]) => !!league ? league.associatedTeams :
      !!venue ? venue.associatedTeams :
        !!event ? event.associatedTeams :
          []),
    map(teams => teams.filter(t => t.active)?.sort((a, b) => a.name.localeCompare(b.name)))
  );

  venueStreams$ = merge(this.leagueHydratedVenues$, this.eventHydratedVenues$, this.hydratedVenue$.notNull().pipe(map(v => [v]))).pipe(
    map((venues) => this.getVenueStreamOptions(venues))
  );

  @Output() returnResult: EventEmitter<any> = new EventEmitter();
  constructor(
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private domainModel: AdminProgramDomainModel,
    private lookupDomainModel: LookupDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  getVenueStreamOptions(venues: HydratedVenue[]): Selectable[] {
    const venueStreamOptions: Selectable[] = [];
    venues.forEach(venue => {
      venue.streams?.forEach(stream => {
        venueStreamOptions.push({
          getSelectionTitle: (): string => {
            return `${venue.address.city ?? 'N/A'} - ${stream.name}`;
          }, getSelectionUniqueIdentifier: (): number => {
            return stream.id;
          }, getSelectionValue: (): number => {
            return stream.id;
          }
        });
      });
    });
    return venueStreamOptions;
  }

  init() {
    super.init();
  }

  clearFormErrors() {
    this.formErrorMessage.next(null);
  }

  getDefaultHydratedProgram(forLeague: boolean): HydratedProgram {
    const p = new HydratedProgram();
    p.productionTypeId = forLeague ? ProductionType.PremiumId : ProductionType.StandardId;
    return p;
  }

  getRefreshHydratedPProgram(programId): Observable<HydratedProgram> {
    return this.domainModel.getHydratedProgramById(programId);
  }

  getTimeOffset(): Selectable[] {
    const timeOffset: Selectable[] = [];
    const maxTimeOffset = 180; // 3 hours
    const timeOffsetIncrement = 5;
    let currentMinutes = timeOffsetIncrement;

    while (currentMinutes <= maxTimeOffset) {
      const option = Number(currentMinutes);
      timeOffset.push({
        getSelectionTitle: (): string => {
          return DateUtils.formatMinutesToHoursMinutesString(option);
        }, getSelectionUniqueIdentifier: (): number => {
          return option;
        }, getSelectionValue: (): number => {
          return option;
        }
      });
      currentMinutes += timeOffsetIncrement;
    }
    return timeOffset;
  }

  getDurationOptions(): Selectable[] {
    const durationsOptions: Selectable[] = [];
    const maxDurationMinutes = 720; // 12 hours
    const durationInterval = 15;
    let currentMinutes = durationInterval;

    while (currentMinutes <= maxDurationMinutes) {
      const option = Number(currentMinutes);
      durationsOptions.push({
        getSelectionTitle: (): string => {
          return DateUtils.formatMinutesToHoursMinutesString(option);
        }, getSelectionUniqueIdentifier: (): number => {
          return option;
        }, getSelectionValue: (): number => {
          return option;
        }
      });
      currentMinutes += durationInterval;
    }

    return durationsOptions;
  }

  getTimeZoneOptions(): Selectable[] {
    const timeZoneOptions: Selectable[] = [];
    const timeZones = moment.tz.zonesForCountry('CA').concat(moment.tz.zonesForCountry('US')).sort();
    timeZones.forEach(tz => {
      timeZoneOptions.push({
        getSelectionTitle: (): string => {
          return `${tz.replaceAll('_', ' ')} ${moment.tz(tz).format('Z z')}`;
        }, getSelectionUniqueIdentifier: (): string => {
          return tz;
        }, getSelectionValue: (): string => {
          return tz;
        }
      });
    });
    return timeZoneOptions;
  }

  saveProgram(formObject: ProgramFormObject) {
    formObject.leagueId = this.leagueId$.getValue();
    if (this.hydratedLeague$.getValue()) {
      formObject.program.subscriptionPlanId = this.hydratedLeague$.getValue().subscriptionPlanId;
    }
    formObject.venueId = this.venueId$.getValue();
    if (this.hydratedVenue$.getValue()) {
      formObject.program.subscriptionPlanId = this.hydratedVenue$.getValue().subscriptionPlanId;
    }
    formObject.eventId = this.eventId$.getValue();
    if (this.hydratedEvent$.getValue()) {
      formObject.program.subscriptionPlanId = this.hydratedEvent$.getValue().subscriptionPlanId;
    }
    if (formObject.freeToWatch) {
      formObject.program.subscriptionPlanId = environment.freePlanId;
    }
    this.domainModel.saveProgram(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Program`))
      .subscribe(() => {
        this.toastService.publishSuccessMessage($localize`Program Saved`, null);
        this.returnResult.emit(null);
        //stop redirect to program list
        //this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'programs'}).then();
      }, error => {
        if(error.code === 400){
          console.log(error);
          this.returnResult.emit(formObject);
        }
        this.toastService.publishError(error);
      });
  }

  completeProgram(formObject: ProgramFormObject) {
    this.domainModel.completeProgram(formObject.program)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Completing Program`))
      .subscribe(() => {
        this.toastService.publishSuccessMessage($localize`Program Completed`, null);
        this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'programs'}).then();
      }, error => {
        this.toastService.publishError(error);
      });
  }

  deleteLeagueProgram(formObject: ProgramFormObject) {
    this.domainModel.deleteLeagueProgram(formObject.program)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Deleting Program`))
      .subscribe(() => {
        this.toastService.publishSuccessMessage($localize`Program Deleted`, null);
        this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'programs'}).then();
      }, error => {
        this.toastService.publishError(error);
      });
  }

  deleteEventProgram(formObject: ProgramFormObject) {
    this.domainModel.deleteEventProgram(formObject.program)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Deleting Program`))
      .subscribe(() => {
        this.toastService.publishSuccessMessage($localize`Program Deleted`, null);
        this.router.navigate(['../..'], {relativeTo: this.activatedRoute, fragment: 'programs'}).then();
      }, error => {
        this.toastService.publishError(error);
      });
  }
}



