import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {CustomFile} from '../../../../models/shared/custom-file';
import {Subject} from 'rxjs';
import {UploadImageInterface} from './upload-image-interface';
import {ToastService} from '../../../../services/toast-service';
import {UploadItemInterface} from './upload-item/upload-item-interface';
import {MediaType} from '../../../../models/enum/dto/media-type.enum';
import {MediaUtils} from '../../../../utils/media-utils';
import {debounceTime} from 'rxjs/operators';
import {UploadType} from './upload-asset.component';
import {LoadingOptions} from '../../../../models/shared/loading-options';

@Injectable()
export class UploadAssetViewModel extends BaseViewModel
  implements UploadItemInterface {

  static MAX_FILE_SIZE = 10485760; // 10 MB
  static MAX_VIDEO_FILE_SIZE = 1024 * 1024 * 1024; // 1 GB

  public loadingOpts: LoadingOptions = LoadingOptions.defaultLight(true, false, false, 500);
  public id: number;
  public resetInputState: Subject<boolean> = new Subject<boolean>();
  public files: CustomFile[] = [];
  public accept: string;
  public acceptTypesDescription: string;
  public mimeTypeRegex: RegExp[];
  public parentHandler: UploadImageInterface;
  public maxAssets: number = -1;
  public changeDetect = new Subject<boolean>();

  private uploadDebouncer: Subject<boolean> = new Subject<boolean>();

  constructor(
    private toastService: ToastService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.uploadDebouncer.pipe(
      debounceTime(50))
      .subscribe(_ => {
        this.uploadFiles();
      });
  }

  initMaxAssets(ma: number) {
    this.maxAssets = ma;
  }

  initParentHandler(h: UploadImageInterface) {
    if (h) {
      this.parentHandler = h;
    }
  }

  initAcceptType(allowTypes: UploadType[]) {
    const acceptTypes: string[] = [];
    const description: string[] = [];
    const regex: RegExp[] = [];
    if (allowTypes.includes('video')) {
      acceptTypes.push('video/*');
      description.push('MP4');
      regex.push(/video\/*/);
    } else if (allowTypes.includes('image')) {
      acceptTypes.concat('image/*');
      description.push('JPG', 'PNG');
      regex.push(/image\/*/);
    } else if (allowTypes.includes('csv')) {
      acceptTypes.concat('text/csv');
      description.push('CSV');
      regex.push(/text\/csv/);
    }

    this.accept = acceptTypes.join('|');
    this.acceptTypesDescription = description.join(', ');
    this.mimeTypeRegex = regex;
  }

  fileBrowseHandler(target) {
    this.handleUploadedFiles(target.files);
  }

  handleUploadedFiles(uploadedFiles: FileList) {
    Array.from(uploadedFiles).forEach(file => {
      // ensure the content type is image, video or csv
      if (!this.properFileType(file)) {
        this.toastService.publishErrorMessage('Unsupported file type.', 'File Error');
        return;
      }

      const loadingMessage = $localize`Importing Media`;
      this.loadingOpts.addRequest(loadingMessage);
      this.parseFile(file, progress => {
        this.loadingOpts.progress = progress;
      },result => {
        this.loadingOpts.removeRequest(loadingMessage);
        const f = new CustomFile();
        f.name = file.name.toLowerCase();
        f.type = file.type;
        f.size = file.size;
        f.url = result;
        f.file = file;
        this.prepareFilesList(new Array(f));
      });
    });
  }

  parseFile(file: File, progressCallback: (progress) => void, callback: (result) => void) {

    if (this.isFileVideo(file)) {
      // Use chunked parser for videos
      this.parseFileInChunks(file, progressCallback, callback);
    } else {
      // Use regular file parser for images
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        callback(event.target.result);
      };
    }
  }

  private parseFileInChunks(file: File, progressCallback: (progress) => void, callback: (result) => void) {
    const fileSize = file.size;
    const chunkSize = 8 * 1024 * 1024; // 8 MB chunks
    let offset = 0;
    let chunkReaderBlock = null;

    const readEventHandler = evt => {
      if (evt.target.error == null) {
        offset += evt.target.result.length;
        const currentProgress = (offset / fileSize) * 100;
        progressCallback(currentProgress);
      } else {
        this.toastService.publishErrorMessage(evt?.target?.error, 'File Read error');
        return;
      }
      if (offset >= fileSize) {
        callback(evt.target.result); // callback for handling read chunk
        return;
      }

      // parse next chunk
      chunkReaderBlock(offset, chunkSize, file);
    };

    chunkReaderBlock = (_offset, length, _file) => {
      const r = new FileReader();
      const blob = _file.slice(_offset, length + _offset);
      r.onload = readEventHandler;
      r.readAsText(blob);
    };

    // start the read with the first block
    chunkReaderBlock(offset, chunkSize, file);
  }

  properFileType(f: File): boolean {
    const mimeType = f.type;
    if (this.mimeTypeRegex?.some(r => mimeType.match(r) != null)) {
      if (this.getMediaType(f) != null) {
        return true;
      }
    }
    return false;
  }

  getMediaType(f: File): MediaType {
    return MediaUtils.getMediaType(f.name);
  }

  prepareFilesList(files: CustomFile[]) {
    const indexesToUpload = [];
    for (const file of files) {
      if (this.isFileTooLarge(file)) {
        this.toastService.publishErrorMessage('File size is too large.', 'File Size Error');
      } else if (!file.success && !file.failed) {
        // if no success or failure, the file can be processed
        file.progress = 0;
        if (this.maxAssets <= 0) {
          this.files.push(file);
        } else {
          if (files.length >= this.maxAssets) {
            this.files.shift();
          }
          this.files.push(file);
        }
        indexesToUpload.push(this.files.indexOf(file));
      }
    }
    this.resetInputState.next(true);
    if (indexesToUpload.length > 0) {
      indexesToUpload.forEach(i => {
        this.files[i].progress = 100;
        this.files[i].failed = false;
        this.files[i].success = true;
      });
      this.uploadDebouncer.next(true);
    }
  }

  uploadFiles() {
    if (this.parentHandler) {
      // Show as successful because we will handle the upload logic elsewhere
      this.parentHandler.fileList(this.files, this.id);
      return;
    } else {
      console.error('Add parent handler for upload asset component.');
    }
  }

  imagePreview(index: number) {
    if (this.files[index].url && this.files[index].hasPreview) {
      return this.files[index].url;
    } else {
      switch (this.files[index].type) {
        case 'image/jpg':
          return '../../../../assets/img/icons/JPG.svg';
        case 'image/jpeg':
          return '../../../../assets/img/icons/JPG.svg';
        case 'image/png':
          return '../../../../assets/img/icons/PNG.svg';
        case 'application/pdf':
          return '../../../../assets/img/icons/PDF.svg';
        default:
          return '../../../../assets/img/icons/manage.svg';

      }
    }
  }

  getPlaceholderImagePadding(index: number) {
    if (this.files[index].url && this.files[index].hasPreview) {
      return '0';
    } else {
      return '0.625rem';
    }
  }


  isFileVideo(file: File): boolean {
    return file.type.match(/video\/*/) != null;
  }

  isFileTooLarge(f: CustomFile): boolean {
    if (f.isVideo()) {
      return (f.size > UploadAssetViewModel.MAX_VIDEO_FILE_SIZE);
    }
    return (f.size > UploadAssetViewModel.MAX_FILE_SIZE);
  }

  removeMe(f: CustomFile) {
    const i = this.files?.indexOf(f);
    if (i >= 0 && (this.files.length) > i) {
      this.files.splice(i, 1);
    }
    this.parentHandler.fileList(this.files, this.id);
  }

  clear() {
    this.files = [];
    this.parentHandler.fileList(this.files, this.id);
  }
}
