<app-detail-title
  [hrCustomClass]=" (viewModel.addNewPlan$|async) ? '' : 'd-none'"
  i18n>
  Plans
</app-detail-title>
<div class="position-relative min-height-20">
  <app-content-container [narrow]="true">
    <app-detail-back-button [backRoute]="'..'" class="d-block mt-4">
      <ng-container *ngIf="viewModel.addNewPlan$|async" i18n>Add a New Plan</ng-container>
      <ng-container *ngIf="!(viewModel.addNewPlan$|async)" i18n>{{(viewModel.plan$|async)?.externalName}}</ng-container>
    </app-detail-back-button>
    <app-tab-bar [class.hide-tab-header]="viewModel.addNewPlan$|async" [tabs]="tabs"></app-tab-bar>
  <app-loading
    [hidden]="!viewModel.loadingOpts?.isLoading"
    [options]="viewModel.loadingOpts">
  </app-loading>
    <div class="py-4 h-100 position-relative min-height-20">
      <div class="label-md mb-4" i18n>Info</div>
      <app-form-group
        *ngIf="formItems?.length > 0"
        [formItems]="formItems"
        [formObject]="formObject"
        [styling]="formStyling"
        [options]="formOptions">
      </app-form-group>
      <div class="table-container">
        <table class="styled-table">
          <thead>
          <tr>
            <th>Frequency</th>
            <th>Currency</th>
            <th>Price</th>
            <th *ngIf="isEditing === true">Status</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of pricingRows">
            <td>{{ row.frequencyLabel }}</td>
            <td>{{ row.currency }}</td>
            <td>
              <input type="number" placeholder="Enter price" [(ngModel)]="row.price">
            </td>
            <td *ngIf="isEditing === true">
              <div class="custom-switch-container">
                <label class="custom-switch">
                  <input [(ngModel)]="row.status"
                         type="checkbox"
                         class="form-control custom-switch-input"
                         name="priceOptionStatus"
                         value="{{row.status}}"
                         id="priceOptionStatus"
                         (change)="row.status ? openActivationModal(row) : openDeactivateModal(row)">
                  <span class="custom-slider"></span>
                </label>
                <div class="switch-label-container ml-2">
                  Active
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div style="display: flex">
        <button class="custom-button preferred-button mr-3"
                (click)="savePlan()">
          Save
        </button>
        <button class="custom-button neutral-button"
                (click)="cancel()">
          Cancel
        </button>
      </div>
    </div>
  </app-content-container>
</div>

