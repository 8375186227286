import {BaseModel} from '../base/base-model';
import {DatatableData} from '../protocols/datatable-data';

export class AdvertisingPartner extends BaseModel implements DatatableData {
  id: number;
  name: string = '';
  active: boolean = true;

  onDeserialize() {
    super.onDeserialize();
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}

