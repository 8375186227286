import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, NgZone, OnChanges, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {Cardable} from '../../../../models/protocols/cardable';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {SwiperComponent} from 'swiper/angular';
import SwiperCore, {Pagination, Navigation, Virtual, Autoplay} from 'swiper';
import 'swiper/css/pagination';
import {debounceTime, filter, map} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, merge} from 'rxjs';

SwiperCore.use([Pagination, Navigation, Virtual, Autoplay]);

@Component({
  selector: 'app-card-row',
  templateUrl: './card-row.component.html',
  styleUrls: ['./card-row.component.scss'],
})
export class CardRowComponent extends BaseComponent implements OnChanges {

  cardRowStyles = CardRowStyle;

  @Input() title: string;
  @Input() showTopDivider: boolean = false;
  @Input() buttonTitle: string;
  @Input() buttonClass: string = 'custom-button neutral-button';
  @Input() cardCtaText: string;
  @Input() fetchingMoreData: boolean;
  @Input() cards: Cardable[] = [];
  @Input() loadingOpts: LoadingOptions;
  @Input() cardStyle: CardRowStyle = CardRowStyle.textBelowCard;

  @Input() breakpoints = {
    0: {
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
    720: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
    960: {
      slidesPerGroup: 4,
      slidesPerView: 4,
    }
  };

  @Output() buttonClicked = new EventEmitter<void>();
  @Output() cardClicked = new EventEmitter<Cardable>();
  @Output() lastLoadedPageReached = new EventEmitter<void>();
  @ViewChild('swiper') swiper: SwiperComponent;
  fetchingMoreData$ = new BehaviorSubject<boolean>(null);
  snapIndex$ = new BehaviorSubject<number>(null);
  snapGridLength$ = new BehaviorSubject<number>(null);

  backButtonDisabled$ = this.snapIndex$.pipe(
    debounceTime(1),
    map(snapIndex => {
      return (snapIndex ?? 0) === 0;
    })
  );

  nextButtonDisabled$ = combineLatest([this.fetchingMoreData$, this.snapIndex$, this.snapGridLength$]).pipe(
    debounceTime(1),
    map(([fetchingMoreData, snapIndex, snapGridLength]) => {
      return !fetchingMoreData && (snapIndex + 1 >= snapGridLength);
    })
  );

  constructor(
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fetchingMoreData !== null) {
      this.fetchingMoreData$.next(this.fetchingMoreData);
    }
  }

  public updatedCards() {
    this.ngZone.runGuarded(() => {
      this.snapIndex$.next(this.swiper.swiperRef.snapIndex);
      this.snapGridLength$.next(this.swiper.swiperRef.snapGrid?.length);
      //   this.swiper?.initSwiper();
    });
  }

  setupViews() {
  }

  setupBindings() {
    merge(this.swiper.s_snapGridLengthChange, this.swiper.s_transitionEnd).subscribe(s => {
      this.ngZone.runGuarded(() => {
        this.snapIndex$.next(s[0].snapIndex);
        this.snapGridLength$.next(s[0].snapGrid?.length);
      });
    });

    combineLatest([this.snapIndex$, this.snapGridLength$])
      .pipe(
        filter(params => params?.every(p => p !== null)),
        debounceTime(100)
      )
      .subscribe(([snapIndex, snapGridLength]) => {
        if (snapIndex + 2 >= snapGridLength) {
          this.lastLoadedPageReached.next();
        }
      });
  }

  nextPageClicked() {
    this.swiper.swiperRef.slideNext();
  }

  getCardShortDescription(card: Cardable) {
    return '';
  }

  previousPagClicked() {
    this.swiper.swiperRef.slidePrev();
  }
}

export enum CardRowStyle {
  textBelowCard,
  textInsideCard,
}
