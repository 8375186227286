import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';

@Injectable()
export class FeaturedLeaguesRowViewModel extends BaseViewModel {

  leagues$ = this.resourceDomainModel.activeHydratedLeagues$;

  constructor(private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

}
