import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, of} from 'rxjs';
import {ContentQuery, ProgramQueryType} from '../../../../models/program/content-query';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class LeagueLandingViewModel extends BaseViewModel {

  leagues$ = this.resourceDomainModel.activeHydratedLeagues$;
  isSignedIn$ = this.accountDomainModel.sessionContainer$.pipe(map(s => !!s));
  leagueTabs$ = new BehaviorSubject<string[]>(null);
  fetchLeagueTabs = this.leagues$.pipe(map(leagues => {
    return [$localize`All`, ...(leagues?.map(l => l.abbreviation) ?? [])];
  })).subscribe(this.leagueTabs$).addTo(this.subscriptions);

  private _selectedTabIndex$ = new BehaviorSubject<number>(0);
  selectedLeagueId$ = combineLatest([this._selectedTabIndex$, this.leagues$])
    .pipe(map(([index, leagues]) => {
      if (index === 0) {
        return null;
      }
      return leagues[index - 1]?.id;
    }));

  selectedHydratedLeague$ = combineLatest([this.selectedLeagueId$, this.leagues$]).pipe(
    map(([leagueId, leagues]) => {
      if (!leagueId) {
        return null;
      }
      return leagues?.find(l => l.id === leagueId);
    }),
  );

  isSubscribedToLeague$ = combineLatest([this.selectedHydratedLeague$, this.accountDomainModel.subscriberSubscriptions$]).pipe(
    map(([league, subscriptions]) => {
      if (!league) {
        return true;
      }
      const leaguePlanId = league?.subscriptionPlanId;
      return leaguePlanId === environment.freePlanId || subscriptions?.some(s => s.planId === leaguePlanId);
    })
  );

  liveProgramsNoResults = new BehaviorSubject<boolean>(false);
  upcomingProgramsNoResults = new BehaviorSubject<boolean>(false);
  pastProgramsNoResults = new BehaviorSubject<boolean>(false);
  showsNoResults = new BehaviorSubject<boolean>(false);
  showNoResults$ = combineLatest([
    this.liveProgramsNoResults,
    this.upcomingProgramsNoResults,
    this.pastProgramsNoResults,
    this.showsNoResults
  ]).pipe(map(noResults => noResults.every(x => !!x)));

  contentQuery$ = this.selectedLeagueId$.pipe(map(leagueId => {
    return new ContentQuery(ProgramQueryType.Leagues, !!leagueId ? [leagueId] : null);
  }));

  programContentQuery$ = new BehaviorSubject<ContentQuery>(null);
  fetchProgramQuery = combineLatest([this.accountDomainModel.subscriberSubscriptions$, this.leagues$, this.selectedLeagueId$])
    .subscribe(([subscriberSubscriptions, leagues, selectedLeagueId]) => {
      const subscriptionIds = subscriberSubscriptions
        ?.filter(s => !s.isSubscriptionInactive())
        ?.map(s => s.planId) ?? [];
      const leagueIds = !!selectedLeagueId ? [selectedLeagueId] : leagues
        .filter(l => l.subscriptionPlanId === environment.freePlanId || subscriptionIds.includes(l.subscriptionPlanId))
        .map(l => l.id);
      this.programContentQuery$.next(new ContentQuery(ProgramQueryType.Leagues, leagueIds));
    }).addTo(this.subscriptions);

  programId$ = new BehaviorSubject<string>(null);
  isShow$ = new BehaviorSubject<boolean>(null);

  constructor(
    private programDomainModel: ProgramDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  selectedTabIndex(index: number) {
    this._selectedTabIndex$.next(index);
  }

}
