<app-detail-title i18n>
  Advertisements
</app-detail-title>
<app-content-container [narrow]="true">
  <app-detail-back-button [backRoute]="'..'" class="d-block mt-4">
    <ng-container *ngIf="viewModel?.addNew" i18n>
      Create Advertisement
    </ng-container>
    <ng-container *ngIf="!viewModel?.addNew" i18n>
      Edit Advertisement
    </ng-container>
  </app-detail-back-button>
  <div class="position-relative min-height-20">
    <app-loading
      [hidden]="!viewModel.loadingOpts?.isLoading"
      [options]="viewModel.loadingOpts">
    </app-loading>
    <div class="mt-4">
      <app-form-group
        *ngIf="formItems?.length > 0"
        [formItems]="formItems"
        [formObject]="formObject"
        [styling]="formStyling"
        [options]="formOptions"
        [hydrateInputObject]="hydrateInputObject"
        (formCancelled)="cancel()"
        (formSubmitted)="formSubmitted($event)">
        <div class="w-100">
          <div class="label-md mb-4" i18n>Media</div>
          <app-upload-asset
            *ngIf="!formObject.videoToUpload && !formObject.existingVideo"
            [allowTypes]="['video']"
            [maxAssets]="1"
            [parentHandler]="this">
          </app-upload-asset>
          <div *ngIf="formObject.videoToUpload || formObject.existingVideo">
            <div class="video-upload-container">
              <img src="/assets/icons/dark/outline/film.svg"
                   class="video-upload-icon"
                   alt="file-upload">
              <div class="flex-grow-1">
                <div *ngIf="formObject.existingVideo">
                  {{formObject.existingVideo.fileName}}
                </div>
                <div *ngIf="!formObject.existingVideo">
                  <div>{{formObject.videoToUpload.name}}</div>
                </div>
              </div>
              <div class="upload-status-container">
                <div class="color-mid-grey" i18n>Upload Status</div>
                <div class="upload-status"
                     [class.success]="formObject.existingVideo?.uploadStatusId === Types.UploadStatusType.Completed"
                     [class.in-progress]="formObject.existingVideo?.uploadStatusId === Types.UploadStatusType.InProgress">
                  <ng-container *ngIf="formObject.existingVideo">{{formObject.existingVideo.uploadStatus}}</ng-container>
                  <ng-container *ngIf="!formObject.existingVideo" i18n>Ready To Upload</ng-container>
                </div>
              </div>
            </div>
            <div class="text-link-button remove-upload-button mb-4" (click)="removeVideoClicked()" i18n>Remove Video</div>
          </div>
          <hr class="mt-0 mb-4">
          <div class="label-md mb-4" i18n>Targeting Conditions</div>
          <div class="paragraph-sm mb-5" i18n>Add conditions to modify where the ad will play, which teams it will play for, and when it will show up in app. </div>
        </div>
      </app-form-group>
    </div>
  </div>
</app-content-container>
