import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DefaultLayoutComponent} from './views/default/default-layout/default-layout.component';
import {AuthGuard} from './services/guards/auth.guard';
import {PermissionGuard} from './services/guards/permission.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    redirectTo: 'auth/sign-in/admin',
    pathMatch: 'full',
  },
  {
    path: 'league/:leagueAbbreviation',
    redirectTo: '/home/league/:leagueAbbreviation',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'HomeTeam'
    },
    children: [
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'account',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard, PermissionGuard],
      },
      {
        path: 'see-all',
        loadChildren: () => import('./views/past-programs/past-programs.module').then(m => m.PastProgramsModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'example',
        loadChildren: () => import('./views/example/example.module').then(m => m.ExampleModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'stylesheet',
        loadChildren: () => import('./views/stylesheet/stylesheet.module').then(m => m.StylesheetModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'auth',
        loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'team',
        loadChildren: () => import('./views/team/team.module').then(m => m.TeamModule),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
