import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {LeagueDetailsViewModel} from '../league-details-view-model';
import {DatatableOptions} from '../../../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../../../models/shared/datatable-filter';
import {BaseDatatableDataProvider} from '../../../../../models/base/base-datatable-data-provider';
import {ActivatedRoute, Router} from '@angular/router';
import {DatatableData} from '../../../../../models/protocols/datatable-data';
import {HydratedProgram} from '../../../../../models/program/hydrated-program';
import {DatatableColumn, DatatableColumnType} from '../../../../../models/shared/stylesheet/datatable-column';
import {Program} from '../../../../../models/program/program';
import {DateUtils} from '../../../../../utils/date-utils';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem, DatatableColumnFilterType
} from '../../../../../models/shared/stylesheet/datatable-column-filter';
import {Placements} from 'ngx-popper';

@Component({
  selector: 'app-league-programs',
  templateUrl: './league-programs.component.html',
  styleUrls: ['./league-programs.component.scss'],
})
export class LeagueProgramsComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public dataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();
  private performSilentUpdate = false;

  constructor(
    public viewModel: LeagueDetailsViewModel,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  setupViews() {
    this.setupDatatable();
  }

  setupBindings() {
    this.viewModel.leaguePrograms$.notNull().subscribe(p => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = p;
        dataProvider.silentUpdateData = this.performSilentUpdate;
        this.performSilentUpdate = true;
        this.updateTableData.next(dataProvider);
        this.updateColumnFilterValues(p);
      });
    });
  }

  programClicked(data: DatatableData) {
    const program = data as HydratedProgram;
    this.router.navigate([`./programs/${program.id}`], {relativeTo: this.activatedRoute}).then();
  }

  addNewProgramClicked() {
    this.router.navigate([`./programs/add`], {relativeTo: this.activatedRoute}).then();
  }

  programUploaderClicked() {
    this.router.navigate([`./program-upload`], {relativeTo: this.activatedRoute}).then();
  }

  public setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.datatableOptions.ctaButtonText = $localize`Add New Program`;
    this.datatableOptions.secondaryButtonText = $localize`Upload Programs`;
    this.setDatatableFilter();
  }

  getDatatableColumns() {
    const columns: DatatableColumn[] = [];

    const homeTeam = new DatatableColumn(
      'homeTeam',
      $localize`Home Team`,
      DatatableColumnType.Text,
      (p: Program): string => {
        return (!!p.homeTeamLocation ? `${p.homeTeamLocation} ${p.homeTeam}` : p.homeTeam);
      }
    );
    homeTeam.columnFilter = this.getDefaultColumnFilter();
    columns.push(homeTeam);

    const visitingTeam = new DatatableColumn(
      'visitingTeam',
      $localize`Away Team`,
      DatatableColumnType.Text,
      (p: Program): string => {
        return (!!p.visitingTeamLocation ? `${p.visitingTeamLocation} ${p.visitingTeam}` : p.visitingTeam);
      }
    );
    visitingTeam.columnFilter = this.getDefaultColumnFilter();
    columns.push(visitingTeam);

    const league = new DatatableColumn(
      'stream',
      $localize`Venue Stream`,
      DatatableColumnType.Text,
      (p: Program): string => {
        return p.venueStream;
      }
    );
    league.columnFilter = this.getDefaultColumnFilter();
    columns.push(league);

    const streamDate = new DatatableColumn(
      'streamDate',
      $localize`Stream Date`,
      DatatableColumnType.Text,
      (p: Program): string => {
        return  p.startDateUtc ?
          $localize`${DateUtils.formatDateWithWeekdayToReadableString(p.startDateUtc)} at
           ${DateUtils.formatTimeToReadableString(p.startDateUtc)}` :
          $localize`N/A`;
      }
    );
    streamDate.customSortingMethod = ((a: HydratedProgram, b: HydratedProgram) => {
      return DateUtils.dateIsBefore(b.startDateUtc, a.startDateUtc);
    });
    streamDate.columnFilter = this.getDateColumnFilter();
    streamDate.isDefaultSortColumn = true;
    columns.push(streamDate);

    const programType = new DatatableColumn(
      'programType',
      $localize`Production Type`,
      DatatableColumnType.Text,
      (p: Program): string => {
        return p.productionType;
      }
    );
    programType.columnFilter = this.getDefaultColumnFilter();
    columns.push(programType);

    const playCount = new DatatableColumn(
      'playCount',
      $localize`Play Count`,
      DatatableColumnType.Text,
      (p: Program): number => {
        return p.playCount;
      }
    );
    playCount.customSortingMethod = ((a, b) => {
      return b.playCount - a.playCount;
    });
    columns.push(playCount);


    const programComments = new DatatableColumn(
      'programComments',
      $localize`Feedback`,
      DatatableColumnType.Icon,
      (p: HydratedProgram): string => {
        return p.comments?.length > 0 ? '/assets/icons/dark/solid/notes-filled.svg' : '/assets/icons/dark/solid/notes.svg';
      }
    );
    programComments.className = 'custom-datatable-header text-right mr-2';
    programComments.headerClassName = 'custom-datatable-header text-right mr-2';
    programComments.iconClicked = ((p: HydratedProgram) => {
      this.viewModel.selectedFeedbackProgram = p;
    });
    columns.push(programComments);

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search programs`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  getDefaultColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }

  updateColumnFilterValues(programs: HydratedProgram[]) {
    this.updateHomeTeamsColumnFilterValues(programs);
    this.updateVisitingTeamsColumnFilterValues(programs);
    this.updateStreamColumnFilterValues(programs);
    this.updateProductionTypeColumnFilterValues(programs);
  }

  updateHomeTeamsColumnFilterValues(programs: HydratedProgram[]) {
    const homeTeamCol = this.datatableOptions.columns.find(c => c.id === 'homeTeam');
    if (!homeTeamCol) {
      return;
    }
    const teamNames = programs.map(o => o.homeTeam).unique(true);
    const items = [
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    teamNames.forEach(n => {
      items.unshift(new DatatableColumnFilterListItem(n));
    });
    homeTeamCol.columnFilter.selectionListItems.splice(0);
    homeTeamCol.columnFilter.selectionListItems.push(...items);
  }

  updateVisitingTeamsColumnFilterValues(programs: HydratedProgram[]) {
    const visitingTeamCol = this.datatableOptions.columns.find(c => c.id === 'visitingTeam');
    if (!visitingTeamCol) {
      return;
    }
    const teamNames = programs.map(o => o.visitingTeam).unique(true);
    const items = [
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    teamNames.forEach(n => {
      items.unshift(new DatatableColumnFilterListItem(n));
    });
    visitingTeamCol.columnFilter.selectionListItems.splice(0);
    visitingTeamCol.columnFilter.selectionListItems.push(...items);
  }

  updateStreamColumnFilterValues(programs: HydratedProgram[]) {
    const streamCol = this.datatableOptions.columns.find(c => c.id === 'stream');
    if (!streamCol) {
      return;
    }
    const leagueNames = programs.map(o => o.venueStream).unique(true);
    const items = [
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    leagueNames.forEach(n => {
      items.unshift(new DatatableColumnFilterListItem(n));
    });
    streamCol.columnFilter.selectionListItems.splice(0);
    streamCol.columnFilter.selectionListItems.push(...items);
  }

  updateProductionTypeColumnFilterValues(programs: HydratedProgram[]) {
    const productionTypeCol = this.datatableOptions.columns.find(c => c.id === 'programType');
    if (!productionTypeCol) {
      return;
    }
    const productionTypes = programs.map(o => o.productionType).unique(true);
    const items = [
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    productionTypes.forEach(n => {
      items.unshift(new DatatableColumnFilterListItem(n));
    });
    productionTypeCol.columnFilter.selectionListItems.splice(0);
    productionTypeCol.columnFilter.selectionListItems.push(...items);
  }

  getDateColumnFilter(): DatatableColumnFilter {
    const dateRangeFilter = new DatatableColumnFilter();
    dateRangeFilter.filterType = DatatableColumnFilterType.DateRangeFilter;
    dateRangeFilter.popperPlacement = Placements.BottomStart;
    dateRangeFilter.dateRangeFilterMethod = (p: HydratedProgram, dateRange) => {
      return DateUtils.dateRangeContains(dateRange.start, dateRange.end, p.startDateUtc);
    };
    return dateRangeFilter;
  }
}
