import {Image} from '../image/dto/image';
import {DeserializeHelper} from '../protocols/deserializable';
import {League} from './league';
import {HydratedTeam} from './hydrated-team';
import {HydratedVenue} from './hydrated-venue';
import {BannerAdvertisement} from './banner-advertisement';
import {Season} from './season';

export class HydratedLeague extends League {
  leagueLogos: Image[];
  associatedVenues: HydratedVenue[];
  associatedTeams: HydratedTeam[];
  advertisementBanners: BannerAdvertisement[];
  seasons: Season[];

  onDeserialize() {
    super.onDeserialize();
    this.associatedTeams = DeserializeHelper.deserializeArray(HydratedTeam, this.associatedTeams ?? []);
    this.associatedVenues = DeserializeHelper.deserializeArray(HydratedVenue, this.associatedVenues ?? []);
    this.seasons = DeserializeHelper.deserializeArray(Season, this.seasons ?? []);
    this.leagueLogos = DeserializeHelper.deserializeArray(Image, this.leagueLogos);
  }
}


