import {Component} from '@angular/core';
import {LeagueLandingViewModel} from './league-landing-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {ProgramRowType} from '../program-row/program-row.component';
import {NoContentType} from '../no-content/no-content.component';
import {Cardable} from '../../../../models/protocols/cardable';
import {Program} from '../../../../models/program/program';
import {Show} from '../../../../models/program/show';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-league-landing',
  templateUrl: './league-landing.component.html',
  styleUrls: ['./league-landing.component.scss'],
  providers: [LeagueLandingViewModel],
})
export class LeagueLandingComponent extends BaseComponent {

  noContentType = NoContentType;
  programRowType = ProgramRowType;

  constructor(
    public viewModel: LeagueLandingViewModel,
    private router: Router
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  tabSelected(index: number): void {
    this.viewModel.selectedTabIndex(index);
  }

  programCardClicked(card: Cardable) {
    if (card instanceof Program) {
      this.viewModel.isShow$.next(false);
      this.viewModel.programId$.next(card.id);
    } else if (card instanceof Show) {
      this.viewModel.isShow$.next(true);
      this.viewModel.programId$.next(card.id);
    }
    this.scrollToVideoPlayer();
  }

  seeAllPastGames = () => {
    this.viewModel.selectedLeagueId$.pipe(take(1)).subscribe(leagueId => {
      this.router.navigate([`/see-all`], {queryParams: leagueId ? {leagueId} : null}).then();
    });
  };
}
