import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {LoggingService} from '../services/logging-service';
import {Observable, throwError} from 'rxjs';
import {Endpoints} from './endpoints';
import {catchError} from 'rxjs/operators';
import {CustomError} from '../models/shared/custom-error';
import {ApiErrorLog} from '../models/shared/api-error-log';
import {HydratedEvent} from '../models/resources/hydrated-event';
import {Event} from '../models/resources/event';
import {TeamId} from '../models/resources/teamId';
import {VenueId} from '../models/resources/venue-id';
import {HttpParams} from '@angular/common/http';
import {BannerAdvertisement} from '../models/resources/banner-advertisement';

@Injectable({
  providedIn: 'root',
})
export class EventApi implements LoggableAPI {
  // Variables
  public serviceName = 'Event';

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }


  public getHydratedEvents(activeOnly: boolean): Observable<HydratedEvent[]> {
    let params = new HttpParams();
    if (activeOnly) {
      params = params.set('active', true);
    }
    return this.apiClient.recursiveGetArr(HydratedEvent, Endpoints.getHydratedEvents(), null, params).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedEvents', err));
        return throwError(err);
      })
    );
  }

  public getHydratedEvent(eventId: number): Observable<HydratedEvent> {
    return this.apiClient.getObj(HydratedEvent, Endpoints.getHydratedEvent(eventId)).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'getHydratedEvent', err));
        return throwError(err);
      })
    );
  }

  public deleteEventImage(eventId: number, imageId: string): Observable<string> {
    const url = Endpoints.deleteEventImage(eventId, imageId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteEventImage', err));
        return throwError(err);
      })
    );
  }

  public createEvent(req: Event): Observable<Event> {
    return this.apiClient.postObj<Event>(Event, Endpoints.createEvent(), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createEvent', err));
        return throwError(err);
      })
    );
  }

  public updateEvent(req: Event): Observable<Event> {
    return this.apiClient.putObj<Event>(Event, Endpoints.updateEvent(req.id), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateEvent', err));
        return throwError(err);
      })
    );
  }

  addEventVenueAssociation(eventId: number, venueId: number): Observable<VenueId> {
    const venueIdBody = new VenueId();
    venueIdBody.venueId = venueId;
    return this.apiClient.postObj<VenueId>(VenueId, Endpoints.createEventVenueAssociation(eventId), venueIdBody).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addEventVenueAssociation', err));
        return throwError(err);
      })
    );
  }

  deleteEventVenueAssociation(eventId: number, venueAssociationId: number): Observable<string> {
    const url = Endpoints.deleteEventVenueAssociation(eventId, venueAssociationId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteEventVenueAssociation', err));
        return throwError(err);
      })
    );
  }

  addEventTeamAssociation(eventId: number, teamId: number): Observable<TeamId> {
    const teamIdBody = new TeamId();
    teamIdBody.teamId = teamId;
    return this.apiClient.postObj<TeamId>(TeamId, Endpoints.createEventTeamAssociation(eventId), teamIdBody).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'addEventTeamAssociation', err));
        return throwError(err);
      })
    );
  }

  deleteEventTeamAssociation(eventId: number, teamId: number): Observable<string> {
    const url = Endpoints.deleteEventTeamAssociation(eventId, teamId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteEventTeamAssociation', err));
        return throwError(err);
      })
    );
  }

  public createEventBannerAdvertisement(eventId: number, req: BannerAdvertisement): Observable<BannerAdvertisement> {
    return this.apiClient.postObj(BannerAdvertisement, Endpoints.createEventBannerAdvertisement(eventId), req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'createEventBannerAdvertisement', err));
        return throwError(err);
      })
    );
  }

  public deleteEventBannerAdvertisement(eventId: number, bannerAdId: string): Observable<string> {
    const url = Endpoints.deleteEventBannerAdvertisement(eventId, bannerAdId);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'deleteEventBannerAdvertisement', err));
        return throwError(err);
      })
    );
  }
}
