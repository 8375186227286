import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import 'firebase/analytics';
import {CanDeactivateState} from './services/guards/can-deactivate.guard';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {LookupDomainModel} from './domainModels/lookup-domain-model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SessionService} from './services/session-service';
import {AuthModalComponent} from './views/shared/modals/auth-modal/auth-modal.component';
import {ModalUtils} from './utils/modal-utils';
import {AccountDomainModel} from './domainModels/account-domain-model';
import {GoogleAnalyticsService} from './services/google-analytics-service';
import {OpenAuthModalOptions} from './models/account/open-auth-modal-options';
import {UserAgreementModalComponent} from './views/admin/shared/user-agreement-modal/user-agreement-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private lookupDomainModel: LookupDomainModel,
    private modalService: NgbModal,
    private sessionService: SessionService,
    private accountDomainModel: AccountDomainModel,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {

    // Config for Abandon changes on back button
    window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false)
    ).subscribe();
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.googleAnalyticsService.trackPathChange(evt);
      this.modalService.dismissAll();
      window.scrollTo(0, 0);
    });

    this.sessionService.showUserAgreementModal$.pipe(debounceTime(100)).subscribe(() => {
      this.showUserAgreement();
    });

    this.sessionService.showAuthModal$.subscribe((options) => {
      this.openSubscriberAuthModal(options);
    });

    this.sessionService.showEditPlansModalForPlanId$.subscribe((planId) => {
      this.openEditPlansModalForPlanId(planId);
    });
  }

  openSubscriberAuthModal(options: OpenAuthModalOptions): void {
    if (this.modalService?.hasOpenModals()) {
      return;
    }
    const modalRef = this.modalService.open(AuthModalComponent, ModalUtils.authModalOptions(true));
    const compInstance = modalRef.componentInstance as AuthModalComponent;
    compInstance.initWithOptions(options);
  }

  openEditPlansModalForPlanId(planId: string): void {
    if (this.modalService?.hasOpenModals()) {
      return;
    }
    this.accountDomainModel.subscriberSubscriptions$.firstNotNull().subscribe(subscriberSubscriptions => {
      const modalRef = this.modalService.open(AuthModalComponent, ModalUtils.authModalOptions());
      const compInstance = modalRef.componentInstance as AuthModalComponent;
      compInstance.initForEditPlans(subscriberSubscriptions, planId);
    });
  }

  showUserAgreement() {
    const userAgreementModalRef = this.modalService.open(UserAgreementModalComponent, ModalUtils.defaultModalOptions());
    userAgreementModalRef.result.then(() => {
      this.accountDomainModel.userAgreedToEULA().subscribe(() => {
      }, error => {
      });
    }, () => {
      this.signOutUser();
    });
  }

  signOutUser() {
    this.accountDomainModel.signOut();
    this.router.navigate(['/home']).then(() => {
      window.location.reload();
    });
  }
}
