import {Season} from './season';
import {DeserializeHelper} from '../protocols/deserializable';
import {HydratedTeam} from './hydrated-team';
import {DateUtils} from '../../utils/date-utils';
import * as moment from 'moment-timezone';

export class SeasonFormObject {
  public season: Season;
  public seasonId: number;
  public leagueId: number;
  public seasonName: string;
  public seasonStartDate: string;
  public seasonEndDate: string;
  public timeStampVal: Date;
  itemChanged: boolean;
  itemCreated: boolean;
  public itemDeleted: boolean;

  static initWithSeason(season: Season): SeasonFormObject {
    const formObject = new SeasonFormObject();
    formObject.season = season;
    formObject.seasonId = season.id;
    formObject.leagueId = season.leagueId;
    formObject.seasonName = season.name;
    formObject.seasonStartDate = DateUtils.formatTimeDateInputString(season.startDate);
    formObject.seasonEndDate = DateUtils.formatTimeDateInputString(season.endDate);
    formObject.timeStampVal = season.timeStampVal;
    return formObject;
  }

  setSeason(s: Season) {
    return this.season = s;
  }

  getCopy(): SeasonFormObject {
    const seasonCopy = DeserializeHelper.deserializeToInstance(Season, this.season);
    const formObjectCopy = Object.assign(new SeasonFormObject(), this);
    formObjectCopy.setSeason(seasonCopy);
    return formObjectCopy;
  }

  getSeason(): Season {
    const newSeason = new Season();
    newSeason.name = this.seasonName;
    newSeason.id = this.seasonId;
    newSeason.leagueId = this.season.leagueId;
    newSeason.startDate = moment(this.seasonStartDate).toDate();
    newSeason.endDate = moment(this.seasonEndDate).toDate();
    newSeason.timeStampVal= this.season.timeStampVal;
    return newSeason;
  }
}
