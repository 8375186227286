import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import {AuthFlow, EditSubscriptionFlow, SubscriberSignUpFlow} from '../../../../models/account/enum/auth-flow.enum';
import {
  SubscriberSubscriptionRequest,
  SubscriptionPlan,
  SubscriptionPricingOption
} from '../../../../models/account/dto/subscription-plan';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {CacheService} from '../../../../services/cache-service';
import {ToastService} from '../../../../services/toast-service';
import {SessionService} from '../../../../services/session-service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthChallenge} from '../../../../models/account/dto/auth-challenge';
import {HydratedUser} from '../../../../models/account/dto/hydrated-user';
import {SignInRequest} from '../../../../models/account/requests/sign-in-request';
import {indicate, indicateOnNext} from '../../../../utils/observable.extensions';
import {SignUpRequest} from '../../../../models/account/requests/sign-up-request';
import {
  CreateSubscriberPaymentDetails,
  SubscriberPaymentDetails
} from '../../../../models/account/dto/subscriber-payment-details';
import {catchError, debounceTime, delay, map, switchMap, tap} from 'rxjs/operators';
import {ResetPasswordRequest} from '../../../../models/account/requests/reset-password-request';
import {ResetPasswordFormObject} from '../../../../models/account/requests/reset-password-form-object';
import {environment} from '../../../../../environments/environment';
import {Coupon, CouponApplyOnType} from '../../../../models/account/dto/coupon';
import {NumberUtils} from '../../../../utils/number-utils';
import {ConstraintType} from '../../../../models/account/dto/coupon-constraint';
import {SubscriberSubscription} from '../../../../models/account/dto/subscriber-subscription';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {DropDownItem} from '../../../../models/shared/stylesheet/drop-down-item';
import {Radiobutton} from '../../../../models/shared/stylesheet/radiobutton';
import {EmailVerification} from '../../../../models/account/requests/email-verification';

@Injectable()
export class AuthModalViewModel extends BaseViewModel {

  public sessionContainer$ = this.session.sessionContainer;
  public leagues: DropDownItem[] = [new DropDownItem('Select League', -1)];
  public selectedLeague: DropDownItem = this.leagues[0];

  // Loading Options
  public loadingOptions = LoadingOptions.defaultLight();
  public errorMessage = new BehaviorSubject<string>(null);
  public reLoginErrorMessage = new BehaviorSubject<string>(null);

  // sign up flow
  public authFlow: AuthFlow = AuthFlow.SignIn;
  public subSignUpFlow: SubscriberSignUpFlow = SubscriberSignUpFlow.EmailVerification;
  private _loginType: BehaviorSubject<LoginType> = new BehaviorSubject<LoginType>(null);
  public loginType$ = this._loginType.pipe(debounceTime(1));
  public savedEmail: string = null;
  public savedSessionId: string = null;
  public signInAfterSignUpRequest: SignInRequest;
  public completionRoute = '/home';
  public overrideSignInTitle: string;
  public overrideSignUpTitle: string;
  public filterSubscriptionPlanId: string = null;
  public countryTypes$ = this.lookupDomainModel.countryTypes;
  public hideResendVerificationButton = false;

  // edit subscription
  public editSubscriptionFlow: EditSubscriptionFlow = null;
  public preselectPlanId: string = null;
  public existingSubscriberSubscriptions: SubscriberSubscription[] = [];
  public paymentDetails$: BehaviorSubject<SubscriberPaymentDetails> = new BehaviorSubject<SubscriberPaymentDetails>(null);
  public isEditingDiscount = false;
  public subscriptionPlanToAddDiscount: SubscriberSubscription;
  public showVerifyEmailButton = true;

  private fetchPaymentDetails = this.domainModel.sessionContainer$.pipe(switchMap(s => {
    if (!!s) {
      return this.domainModel.getSubscriberPaymentDetails();
    } else {
      return of(null);
    }
  })).subscribe((p) => {
    this.paymentDetails$.next(p);
  }).addTo(this.subscriptions);

  // subscription plans
  public subscriptionPlans = new BehaviorSubject<SubscriptionPlan[]>([]);
  public selectedPlan$ = new BehaviorSubject<SubscriptionPlan>(null);
  public selectedPlanPricingOptions$ = new BehaviorSubject<SubscriptionPricingOption[]>([]);
  // discount codes
  public couponsToApply$ = new BehaviorSubject<Coupon[]>([]);
  public discountCodeInputToCheck$ = new BehaviorSubject<string>(null);
  public discountCodeErrorSubject$ = new Subject<string>();
  private fetchDiscountCodeForInput = this.discountCodeInputToCheck$.notNull().subscribe(d => this.lookupDiscountCodeInput(d));

  public selectedPlansTotalNoDiscounts$ = this.selectedPlanPricingOptions$
    .pipe(map(selectedPlanPricingOptions => this.getTotalForSelectedPricingOptionAndCoupons(selectedPlanPricingOptions, []) / 100));
  public selectedPlansTotalNoDiscountsText$ = combineLatest([this.selectedPlanPricingOptions$, this.selectedPlansTotalNoDiscounts$])
    .pipe(map(([selectedPricingOptions, total]) => {
      if (selectedPricingOptions?.every(p => p.periodUnit === selectedPricingOptions[0]?.periodUnit)) {
        return `${NumberUtils.formatNumberAsCurrency(total)} / ${selectedPricingOptions[0]?.periodUnit}`;
      } else {
        return `${NumberUtils.formatNumberAsCurrency(total)}`;
      }
    }));
  public selectedPlansTotal$ = combineLatest([this.selectedPlanPricingOptions$, this.couponsToApply$])
    .pipe(map(([selectedPlanPricingOptions, coupons]) => {
      return this.getTotalForSelectedPricingOptionAndCoupons(selectedPlanPricingOptions, coupons) / 100;
    }));
  public selectedPlansTotalText$ = combineLatest([this.selectedPlanPricingOptions$, this.selectedPlansTotal$])
    .pipe(map(([selectedPricingOptions, total]) => {
      if (selectedPricingOptions?.every(p => p.periodUnit === selectedPricingOptions[0]?.periodUnit)) {
        return `${NumberUtils.formatNumberAsCurrency(total)} / ${selectedPricingOptions[0]?.periodUnit}`;
      } else {
        return `${NumberUtils.formatNumberAsCurrency(total)}`;
      }
    }));
  public selectedPlansDescriptionText$ = this.selectedPlanPricingOptions$
    .pipe(
      map(selectedPlanPricingOptions => selectedPlanPricingOptions?.length === 1 ?
        $localize`Selected Plan: ` :
        $localize`Selected Plans: `));

  // invoice Summary
  public invoiceSummaryPlanText$ = this.selectedPlansTotalNoDiscounts$.pipe(map(total => NumberUtils.formatNumberAsCurrency(total)));
  public invoiceSummaryDiscountApplied$ = combineLatest([this.selectedPlansTotalNoDiscounts$, this.selectedPlansTotal$]).pipe(
    map(([subtotal, totalWithDiscounts]) => subtotal !== totalWithDiscounts),
  );
  public invoiceSummaryDiscount$ = combineLatest([this.selectedPlansTotalNoDiscounts$, this.selectedPlansTotal$]).pipe(
    map(([subtotal, totalWithDiscounts]) => totalWithDiscounts - subtotal),
  );
  public invoiceSummaryDiscountText$ = this.invoiceSummaryDiscount$.pipe(
    map(discount => `${NumberUtils.formatNumberAsCurrency(discount)}`),
  );
  public invoiceSummaryInvoiceTotalText$ = this.selectedPlansTotal$.pipe(
    map(total => {
      const totalString = NumberUtils.formatNumberAsCurrency(total);
      return total <= 0 ? totalString : `${totalString} + Tax`;
    })
  );

  constructor(
    public domainModel: AccountDomainModel,
    private cacheService: CacheService,
    private toastService: ToastService,
    private sessionService: SessionService,
    private activatedRoute: ActivatedRoute,
    private activeModal: NgbActiveModal,
    private router: Router,
    private lookupDomainModel: LookupDomainModel,
    public session: SessionService,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {
    this.domainModel.loginSuccessful.subscribe((u) => {
      this.handleLogin(u);
    }).addTo(this.subscriptions);

    this.domainModel.getAvailableSubscriptionPlans().pipe(indicate(this.loadingOptions, '')).subscribe(sp => {
      this.subscriptionPlans.next(sp);
    }).addTo(this.subscriptions);

    this.activatedRoute.queryParams.subscribe(params => {
      const paramLoginType = params.logintype as LoginType;
      if (paramLoginType) {
        this.setLoginType(paramLoginType);
      }
    }).addTo(this.subscriptions);
  }

  private handleLogin(user: HydratedUser) {
    if (user?.challengeName === AuthChallenge.NewPasswordChallenge) {
      this.savedEmail = user.email;
      this.savedSessionId = user.sessionId;
      this.clearCaches();
      this.authFlow = AuthFlow.ResetPassword;
    } else {
      this.navigateToCompletionRoute();
    }
  }

  navigateToCompletionRoute() {
    this.closeModal();
    if (!!this.sessionService.sessionContainer.getValue()?.userRole) {
      this.router.navigate(['admin/users']).then();
    } else {
      this.router.navigate(['/'], {skipLocationChange: true}).then(() => {
        this.router.navigate([this.completionRoute]).then();
      });
    }
  }

  signIn(signInRequest: SignInRequest) {
    this.clearErrorMessage();
    const loadingMessage = $localize`Signing In`;
    this.loginType$.firstNotNull().subscribe(loginType => {
      if (loginType === LoginType.Admin) {
        this.domainModel.adminSignIn(signInRequest)
          .pipe(indicate(this.loadingOptions, loadingMessage))
          .subscribe(user => {
            this.domainModel.loginSuccessful.next(user);
          }, error => {
            this.showErrorMessage(error);
          });
      } else {
        this.domainModel.subscriberSignIn(signInRequest)
          .pipe(indicate(this.loadingOptions, loadingMessage))
          .subscribe(user => {
            this.domainModel.loginSuccessful.next(user);
          }, error => {
            this.showErrorMessage(error);
            // create a modal to show the error message
          });
      }
    });
  }

  emailVerificationClicked(emailVerification: EmailVerification) {
    this.showVerifyEmailButton = this.subSignUpFlow !== SubscriberSignUpFlow.EmailVerification;
    this.domainModel.emailVerification(emailVerification).subscribe(() => {
    }, error => {
      switch (error.code) {
        case 403:
          this.subSignUpFlow = SubscriberSignUpFlow.EmailVerificationUnverified;
          this.showVerifyEmailButton = true;
          break;
        case 409:
          this.subSignUpFlow = SubscriberSignUpFlow.EmailVerificationLinkExisted;
          break;
      }
    });
  }

  resendVerificationClicked(emailVerification: EmailVerification) {
    if (this.subSignUpFlow === SubscriberSignUpFlow.EmailVerificationLinkExisted) {
      this.showVerifyEmailButton = true;
    } else {
      this.showVerifyEmailButton = false;
    }
    this.domainModel.resendEmailVerification(emailVerification).subscribe();
  }

  validateEmailVerificationToken(token: string): Observable<EmailVerification> {
    return this.domainModel.validateEmailVerificationToken(token);
  }

  showErrorMessage(error: any) {
    if (error?.code === 400) {
      if (error?.message === 'Session already exists for subscriber') {
        this.reLoginErrorMessage
          .next($localize`You may be logged in on another tab or browser.`);
        return;
      } else if (error?.message.includes('User is disabled')) {
        this.reLoginErrorMessage.next(null);
        this.errorMessage.next($localize`This account has been disabled.`);
        return;
      } else {
        this.reLoginErrorMessage.next(null);
        this.errorMessage.next($localize`The email or password you have entered is incorrect.`);
        return;
      }
    }
    this.reLoginErrorMessage.next(null);
    this.errorMessage.next(error?.message ?? $localize`An error has occurred. Please try again.`);
  }

  clearErrorMessage() {
    this.errorMessage.next(null);
  }

  logOutOtherSession() {
    this.domainModel.signOutSubscribers().pipe(delay(1000), indicateOnNext(this.loadingOptions, '')).subscribe(() => {
        this.signIn(this.domainModel.lastSignInRequest);
      }
    );
  }

  cancelClicked() {
    this.closeModal();
  }

  // Auth Flow
  backButtonVisibleForAuthFlow(): boolean {
    switch (this.authFlow) {
      case AuthFlow.SignUp:
        return this.backButtonVisibleForSignUpFlow();
      case AuthFlow.ForgotPassword:
      case AuthFlow.SignIn:
        return false;
      case AuthFlow.ResetPassword:
      case AuthFlow.SetNewPassword:
        return true;
    }
  }

  backButtonVisibleForSignUpFlow(): boolean {
    switch (this.subSignUpFlow) {
      case SubscriberSignUpFlow.CreateProfile:
      case SubscriberSignUpFlow.Completed:
        return false;
      case SubscriberSignUpFlow.PickPlan:
      case SubscriberSignUpFlow.ProfileDetails:
      case SubscriberSignUpFlow.PickPlanPackage:
      case SubscriberSignUpFlow.ChooseOrganizationToSupport:
      case SubscriberSignUpFlow.AddPaymentDetails:
      case SubscriberSignUpFlow.Review:
        return true;
    }
  }

  getAuthFlowTitle(): string {
    switch (this.authFlow) {
      case AuthFlow.SignUp:
        return this.signUpFlowTitle();
      case AuthFlow.SignIn:
        this.showVerifyEmailButton = true;
        this.hideResendVerificationButton = false;
        return this._loginType.value === LoginType.Admin ?
          $localize`Admin Sign In` :
          (this.overrideSignInTitle ?? $localize`Sign In to HomeTeam Live`);
      case AuthFlow.ForgotPassword:
        return $localize`Forgot Password`;
      case AuthFlow.ResetPassword:
        return $localize`Reset Password`;
      case AuthFlow.SetNewPassword:
        return $localize`Change Password`;
    }
  }

  signUpFlowTitle(): string {
    switch (this.subSignUpFlow) {
      case SubscriberSignUpFlow.EmailVerification:
      case SubscriberSignUpFlow.EmailVerificationUnverified:
      case SubscriberSignUpFlow.EmailVerificationLinkExisted:
      case SubscriberSignUpFlow.EmailVerificationLinkExpired:
        return $localize`Sign Up Email Verification`;
      case SubscriberSignUpFlow.CreateProfile:
        return this.overrideSignUpTitle ?? $localize`Create a HomeTeam Live Account`;
      case SubscriberSignUpFlow.PickPlan:
        return $localize`Choose A Plan`;
      case SubscriberSignUpFlow.ProfileDetails:
        return $localize`Profile details`;
      case SubscriberSignUpFlow.PickPlanPackage:
        return $localize`Choose Plan Package`;
      case SubscriberSignUpFlow.ChooseOrganizationToSupport:
        return $localize`Choose Organization To Support`;
      case SubscriberSignUpFlow.Review:
        return $localize`Review`;
      case SubscriberSignUpFlow.AddPaymentDetails:
        return $localize`Payment Details`;
      case SubscriberSignUpFlow.Completed:
        return '';
    }
  }

  clearCaches() {
    this.cacheService.clearSessionCache();
    this.cacheService.clearPersistentCache();
  }

  // Subscriber Auth
  createSubscriber(req: SignUpRequest, paymentDetails: CreateSubscriberPaymentDetails, subscriptionPlans: SubscriberSubscriptionRequest[]) {
    const signUpPlans = [new SubscriberSubscriptionRequest(environment.freePlanPriceId), ...subscriptionPlans];
    this.domainModel.createSubscriber(req).pipe(
      switchMap(createdUser => {
        if (!paymentDetails) {
          return of([createdUser, null] as [HydratedUser, string]);
        }
        return this.domainModel.createSubscriberPaymentDetails(paymentDetails, String(createdUser.id)).pipe(
          map(() => ([createdUser, null]) as [HydratedUser, string]),
          catchError(
            (e) => of(
              [null, $localize`There was an issue adding your payment information. Please log in and update your payment settings.`] as
                [HydratedUser, string]))
        );
      }),
      switchMap(([createdUser, message]) => {
        if (!createdUser) {
          return of([null, message]);
        } else if (!paymentDetails) {
          return of([createdUser, message] as [HydratedUser, string]);
        }
        return this.domainModel.createSubscriberSubscriptions(signUpPlans, String(createdUser.id)).pipe(
          map(() => ([createdUser, null]) as [HydratedUser, string]),
          catchError(
            (e) => of(
              [null, $localize`There was an issue adding your subscription plan. Please log in and update your subscription settings.`] as
                [HydratedUser, string]))
        );
      }),
      indicateOnNext(this.loadingOptions, $localize`Creating Account`),
    ).subscribe(([_, message]) => {
      this.handleSignUpCompletion(message, req);
    }, error => {
      this.toastService.publishError(error, $localize`Account Creation Failed`);
    });
  }

  handleSignUpCompletion(message: string, req: SignUpRequest) {
    if (!!message) {
      this.toastService.publishWarningMessage(message, $localize`Account Created`);
    }
    fbq('track', 'CompleteRegistration', {currency: 'CAD'});
    this.subSignUpFlow = SubscriberSignUpFlow.Completed;
    const signInReq = new SignInRequest();
    signInReq.password = req.password;
    signInReq.email = req.email;
    this.signInAfterSignUpRequest = signInReq;
  }

  signInAfterSignUp() {
    if (!!this.signInAfterSignUpRequest) {
      this.signIn(this.signInAfterSignUpRequest);
    } else {
      this.authFlow = AuthFlow.SignIn;
    }
  }

  // Forgot/Reset Password
  sendForgotPasswordCode(email: string) {
    this.clearErrorMessage();
    const loadingMessage = $localize`Sending Code`;
    let isAdmin = false;
    if (this._loginType.getValue() === LoginType.Admin) {
      isAdmin = true;
    }
    this.domainModel.sendPasswordResetCode(email, isAdmin).pipe(indicate(this.loadingOptions, loadingMessage)).subscribe(_ => {
      this.toastService.publishSuccessMessage($localize`Check your email`, $localize`Code Sent to ` + email);
      this.authFlow = AuthFlow.SetNewPassword;
    }, error => {
      this.showErrorMessage(error);
      this.toastService.publishSuccessMessage($localize`Check your email`, $localize`Code Sent to ` + email);
    });
  }

  resetForgottenPassword(email: string, req: ResetPasswordRequest) {
    this.clearErrorMessage();
    const loadingMessage = $localize`Resetting Password`;
    let isAdmin = false;
    if (this._loginType.getValue() === LoginType.Admin) {
      isAdmin = true;
    }
    this.domainModel.resetForgottenPassword(email, isAdmin, req).pipe(indicate(this.loadingOptions, loadingMessage)).subscribe(_ => {
      this.toastService.publishSuccessMessage($localize`You may login with your new password`, $localize`Password Changed`);
      this.authFlow = AuthFlow.SignIn;
    }, error => {
      this.showErrorMessage(error);
      this.toastService.publishErrorMessage(error.errorMessage, $localize`Reset Password Failed`);
    });
  }

  respondToNewPassChallenge(req: ResetPasswordFormObject) {
    this.clearErrorMessage();
    const loadingMessage = $localize`Setting your new password`;
    req.sessionId = this.savedSessionId;
    this.domainModel.respondToNewPasswordChallenge(req, this.savedEmail).pipe(indicate(this.loadingOptions, loadingMessage))
      .subscribe(_ => {
        this.toastService.publishSuccessMessage($localize`You may login with your new password`, $localize`Password Set`);
        this.authFlow = AuthFlow.SignIn;
      }, error => {
        this.showErrorMessage(error);
        this.toastService.publishErrorMessage(error.errorMessage, $localize`Set Password Failed`);
      });
  }

  setLoginType(loginType: LoginType) {
    this._loginType.next(loginType);
  }

  addPaymentDetailsForExistingUser(planRadiobutton: Radiobutton[], paymentDetails: CreateSubscriberPaymentDetails): Observable<any> {
    const userId = this.sessionService.getUserId() as string;
    return this.domainModel.createSubscriberPaymentDetails(paymentDetails, userId)
      .pipe(indicate(this.loadingOptions, $localize`Adding Payment Details`))
      .pipe(tap(() => {
        this.updateSubscriptions(planRadiobutton);
      }));
  }

  updateSubscriptions(planRadiobutton: Radiobutton[]) {
    const coupons = this.couponsToApply$.getValue();
    const invoiceCoupons = this.getInvoiceCoupons(coupons);
    const subscriberId = this.sessionService.getUser()?.id as string;
    let plansToCreate = planRadiobutton.filter(p => p.selected).map(p => {
      const planCoupons = [...invoiceCoupons, ...this.getAppliedCouponsForPlan(p.id as string, coupons)];
      return new SubscriberSubscriptionRequest(p.id as string, planCoupons.map(c => c.id));
    });

    // add free plan as well
    plansToCreate.push(new SubscriberSubscriptionRequest(environment.freePlanPriceId));

    let subscriptionsToDiscount: SubscriberSubscriptionRequest[] = [];
    plansToCreate?.forEach(createPlan => {
      const existingSubscription = this.existingSubscriberSubscriptions.find(s => s.planPriceId === createPlan.planPriceId);
      if (!!existingSubscription) {
        createPlan.subscriptionId = existingSubscription.id;
        subscriptionsToDiscount.push(createPlan);
      }
    });

    plansToCreate = plansToCreate.filter(p => subscriptionsToDiscount.findIndex(s => s.planPriceId === p.planPriceId) < 0);
    subscriptionsToDiscount = subscriptionsToDiscount.filter(s => s.coupons?.length > 0);
    forkJoin([
      this.domainModel.createSubscriberSubscriptions(plansToCreate, subscriberId),
      this.domainModel.addCouponsToSubscription(subscriptionsToDiscount, subscriberId)
    ]).pipe(indicate(this.loadingOptions, 'Saving Subscription')).subscribe(() => {
      this.toastService.publishSuccessMessage($localize`Updated Subscription`, null);
      this.closeModal();
    }, error => {
      this.toastService.publishError(error, $localize`Update Subscription Failed`);
      this.closeModal();
    });
  }

  addDiscount() {
    const coupon = this.couponsToApply$.getValue()[0];
    const subscriberId = this.sessionService.getUser()?.id as string;
    if (coupon) {
      this.domainModel.addCouponToSubscription(coupon, this.subscriptionPlanToAddDiscount.id, subscriberId)
        .pipe(indicate(this.loadingOptions, 'Saving Subscription')).subscribe(() => {
        this.toastService.publishSuccessMessage($localize`Updated Subscription`, null);
        this.closeModal();
      }, error => {
        this.closeModal();
      });
    }
  }

  getPrimaryButtonText(): string {
    switch (this.authFlow) {
      case AuthFlow.SignIn:
        return $localize`Sign In`;
      case AuthFlow.SignUp:
        return this.getSignUpPrimaryButtonText();
      case AuthFlow.ForgotPassword:
        return $localize`Send Reset Code`;
      case AuthFlow.ResetPassword:
      case AuthFlow.SetNewPassword:
        return $localize`Confirm New Password`;
      default:
        return null;
    }
  }

  isPrimaryButtonDisabled(): boolean {
    if (this.authFlow === AuthFlow.SignUp &&
      this.subSignUpFlow === SubscriberSignUpFlow.PickPlan) {
      if (this.selectedPlan$.getValue() === null) {
        return true;
      }
    }
    if (this.authFlow === AuthFlow.SignUp &&
      this.subSignUpFlow === SubscriberSignUpFlow.PickPlanPackage) {
      if (this.selectedPlanPricingOptions$.getValue().length === 0) {
        return true;
      }
    }
    if (this.authFlow === AuthFlow.SignUp &&
      this.subSignUpFlow === SubscriberSignUpFlow.ChooseOrganizationToSupport) {
      if (this.selectedLeague.value === -1) {
        return true;
      }
    }
    return false;
  }

  getSignUpPrimaryButtonText(): string {
    switch (this.subSignUpFlow) {
      case SubscriberSignUpFlow.EmailVerification:
        return $localize`Verify Email`;
      case SubscriberSignUpFlow.EmailVerificationUnverified:
      case SubscriberSignUpFlow.EmailVerificationLinkExpired:
      case SubscriberSignUpFlow.EmailVerificationLinkExisted:
        return $localize`Resend Verification Link`;
      case SubscriberSignUpFlow.Review:
        return this.editSubscriptionFlow !== null ? $localize`Save` : $localize`Next`;
      case SubscriberSignUpFlow.AddPaymentDetails:
        return $localize`Complete`;
      case SubscriberSignUpFlow.CreateProfile:
        return this.filterSubscriptionPlanId === environment.freePlanId ? $localize`Okay` : $localize`Next`;
      default:
        return $localize`Next`;
    }
  }

  getSecondaryButtonText(): string {
    switch (this.authFlow) {
      case AuthFlow.SignUp:
        return this.getSignUpSecondaryButtonText();
      default:
        return $localize`Cancel`;
    }
  }

  getSignUpSecondaryButtonText(): string {
    if (this.editSubscriptionFlow !== null) {
      return $localize`Cancel`;
    }
    if (this.subSignUpFlow === SubscriberSignUpFlow.EmailVerificationUnverified) {
      return $localize`Close`;
    }

    switch (this.subSignUpFlow) {
      case SubscriberSignUpFlow.EmailVerification:
      case SubscriberSignUpFlow.EmailVerificationLinkExisted:
      case SubscriberSignUpFlow.EmailVerificationLinkExpired:
      case SubscriberSignUpFlow.CreateProfile:
      case SubscriberSignUpFlow.PickPlan:
      case SubscriberSignUpFlow.ProfileDetails:
      case SubscriberSignUpFlow.PickPlanPackage:
      case SubscriberSignUpFlow.ChooseOrganizationToSupport:
      case SubscriberSignUpFlow.Review:
      case SubscriberSignUpFlow.AddPaymentDetails:
        return $localize`Cancel`;
      default:
        return null;
    }
  }

  getSecondaryButtonClass(): string {
    switch (this.authFlow) {
      case AuthFlow.SignUp:
        return this.getSignUpSecondaryButtonClass();
      default:
        return '';
    }
  }

  getSignUpSecondaryButtonClass(): string {
    if (this.editSubscriptionFlow !== null) {
      return '';
    }

    switch (this.subSignUpFlow) {
      case SubscriberSignUpFlow.Review:
      case SubscriberSignUpFlow.PickPlan:
      case SubscriberSignUpFlow.ProfileDetails:
      case SubscriberSignUpFlow.PickPlanPackage:
      case SubscriberSignUpFlow.ChooseOrganizationToSupport:
      case SubscriberSignUpFlow.AddPaymentDetails:
        return 'mr-auto';
      default:
        return '';
    }
  }

  getTertiaryButtonText(): string {
    switch (this.authFlow) {
      case AuthFlow.SignUp:
        return this.getSignUpTertiaryButtonText();
      case AuthFlow.SignIn:
        this.subSignUpFlow = SubscriberSignUpFlow.EmailVerification;
        return this._loginType.value === LoginType.Admin ? null : $localize`Don’t have an account?`;
      case AuthFlow.ForgotPassword:
        return $localize`Already Have a Reset Code?`;
      default:
        return null;
    }
  }

  getSignUpTertiaryButtonText(): string {
    switch (this.subSignUpFlow) {
      case SubscriberSignUpFlow.CreateProfile:
      case SubscriberSignUpFlow.EmailVerification:
      case SubscriberSignUpFlow.EmailVerificationLinkExisted:
        return $localize`Already have an account?`;
      default:
        return null;
    }
  }

  lookupDiscountCodeInput(codeInput: string) {
    const codes = codeInput.replaceAll(/\s/g, '').split(',');
    forkJoin(codes.map(c => this.domainModel.getCouponFromCode(c)))
      .pipe(
        indicateOnNext(this.loadingOptions, 'Verifying Discount Code'),
        switchMap(coupons => {
          const couponNotFoundIndex = coupons.findIndex(c => !c);
          const couponNotValidIndex = coupons.findIndex(c => !!c && !this.isCouponValidForSelectedPricingOptions(c));
          const couponExpired = coupons.find(c => c?.status === 'expired');
          if (couponNotFoundIndex >= 0) {
            return throwError($localize`Couldn't find a discount that matches \`${codes[couponNotFoundIndex]}\`.`);
          } else if (couponNotValidIndex >= 0) {
            return throwError($localize`The discount code \`${codes[couponNotValidIndex]}\` does not apply to any of the selected plans.`);
          } else if (couponExpired) {
            return throwError($localize`The Coupon entered is no longer valid.`);
          } else {
            return of(coupons);
          }
        }),
      )
      .subscribe((verifiedCoupons) => {
        const currentCoupons = this.couponsToApply$.getValue();
        const updatedUniqueCoupons = [...currentCoupons, ...verifiedCoupons].filter(
          (coupon, i, couponArray) => couponArray.findIndex(c => c.id === coupon.id) === i
        );
        this.couponsToApply$.next(updatedUniqueCoupons);
      }, error => {
        if (typeof error === 'string') {
          this.discountCodeErrorSubject$.next(error);
        } else {
          this.toastService.publishError(error);
        }
      }).addTo(this.subscriptions);
  }

  removeAppliedDiscount(couponToRemove: Coupon) {
    const updatedCoupons = this.couponsToApply$.getValue().filter(c => c.id !== couponToRemove.id);
    this.couponsToApply$.next(updatedCoupons);
  }

  getTotalForSelectedPricingOptionAndCoupons(selectedPricingOptions: SubscriptionPricingOption[], coupons: Coupon[]): number {
    let total = selectedPricingOptions.map(p => this.getTotalForPricingOption(p, coupons))
      .reduce((sum, current) => sum + current, 0);
    const applicableCoupons = this.getInvoiceCoupons(coupons);
    applicableCoupons.forEach(c => {
      return total = c.getDiscountOnPrice(total);
    });
    return total;
  }

  getTotalTextForPlanLineItem(pricingOption: SubscriptionPricingOption, applyDiscount: boolean): string {
    let couponPeriodDescription = '';
    const itemTotal = this.getTotalForPricingOption(pricingOption, applyDiscount ? this.couponsToApply$.getValue() : []) / 100;

    if (applyDiscount) {
      const applicableCoupons = this.getAppliedCouponsForPlan(pricingOption.id, this.couponsToApply$.getValue());
      applicableCoupons?.forEach(c => {
        if (couponPeriodDescription?.length === 0) {
          couponPeriodDescription = c.getCouponPeriodDescription();
        }
      });
    }
    return `${NumberUtils.formatNumberAsCurrency(itemTotal)} / ${pricingOption.periodUnit} ${couponPeriodDescription}`;
  }

  getTotalForPricingOption(planPricingOption: SubscriptionPricingOption, coupons: Coupon[]): number {
    const applicableCoupons = this.getAppliedCouponsForPlan(planPricingOption.id, coupons);
    let price = planPricingOption.price;
    applicableCoupons.forEach(c => {
      return price = c.getDiscountOnPrice(price);
    });
    return price;
  }

  getAppliedCouponsForPlan(planId: string, coupons: Coupon[]): Coupon[] {
    return coupons.filter(coupon => {
      return !!coupon.couponConstraints?.find(c => c.constraint === ConstraintType.Specific && c.planIds.includes(planId))
        && coupon.applyOn === CouponApplyOnType.EachSpecifiedItem;
    });
  }

  getInvoiceCoupons(coupons: Coupon[]): Coupon[] {
    return coupons.filter(coupon => {
      return coupon.applyOn === CouponApplyOnType.InvoiceAmount;
    });
  }

  isCouponValidForSelectedPricingOptions(coupon: Coupon): boolean {
    if (coupon.applyOn === CouponApplyOnType.InvoiceAmount) {
      return true;
    }
    return this.selectedPlanPricingOptions$.getValue().some(p => {
      return coupon.couponConstraints?.some(c => c.constraint === ConstraintType.Specific && c.planIds.includes(p.id));
    });
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  closeModal() {
    this.activeModal.close(true);
  }
}

export enum LoginType {
  Subscriber = 'subscriber',
  Admin = 'admin'
}
