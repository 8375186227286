import { Component, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { FormInputItem, FormInputType, FormItemType } from '../../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../../models/shared/stylesheet/form-group-styling';
import { FormOptions } from '../../../../../models/shared/stylesheet/form-options';
import { EventFormObject } from '../../../../../models/resources/event-form-object';
import { ToastService } from '../../../../../services/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionPlan } from '../../../../../models/account/dto/subscription-plan';
import { first, take } from 'rxjs/operators';
import { UploadImageModalComponent } from '../../../../shared/components/upload-image-modal/upload-image-modal.component';
import { ModalUtils } from '../../../../../utils/modal-utils';
import { ConfirmationModalComponent } from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { EventDetailsViewModel } from '../event-details-view-model';
import { AlphanumericValidatorDirective } from 'src/app/views/shared/components/form-group/validators/alphanumeric-validator.directive';
import ConflictRecordUtils from '../../../../../utils/conflict-record-utils';
@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss'],
})
export class EditEventComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: EventFormObject;
  public updatedFormObject = new EventEmitter<void>();
  public hydrateInputObject = new EventEmitter<void>();
  public concurrentUpdateFlag: boolean = false;
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];
  currentEvent: any = null;
  exceptFieldsArr = [
    'timeStampVal',
    'updatedDate',
    'createdDate',
    '_isScalar',
    '_subscribe',
    'project',
    'predicate',
    'presignedUrl',
    'active'
  ];

  constructor(
    public viewModel: EventDetailsViewModel,
    private toastService: ToastService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupBindings() {
    // default get request for event
    this.viewModel.event$.notNull().subscribe(event => {
      setTimeout(() => {
        this.currentEvent = JSON.parse(JSON.stringify(event));
        this.formObject = EventFormObject.initWithEvent(event);
        this.viewModel.updateFormItemStatesSubject$.next();
      });
    }, error => {
      this.toastService.publishError(error);
    }).addTo(this.subscriptions);

    this.viewModel.concurrentUpdate.subscribe(conflictObject => {
      // If concurrent issue occurs
      if (conflictObject) {
        this.viewModel.refreshHydratedEvent$.notNull().subscribe(refreshEvent => {
          setTimeout(() => {
            this.concurrentUpdateFlag = true;
            // Compare event objects to check concurrent issue
            this.compareObjects(this.currentEvent, conflictObject, refreshEvent);

            this.setEventObjAfterConflict(conflictObject, refreshEvent);
            this.formObject = EventFormObject.initWithEvent(refreshEvent);
            this.viewModel.updateFormItemStatesSubject$.next();
            this.setupFormItems();
          });
        });
      } else {
        this.concurrentUpdateFlag = false;
        this.setupFormItems();
      }
    });
  }

  compareObjects(currentObj, conflictObj, refreshObj) {
    // const result1 = this.diff(currentObj, conflictObj.event, 'red');
    // const result2 = this.diff(currentObj, refreshObj, 'orange');

    const currentConflictObj = ConflictRecordUtils.diff(currentObj, conflictObj.event, 'red');
    const currentRefreshObj = ConflictRecordUtils.diff(currentObj, refreshObj, 'orange');
    this.currentUserChanges = currentRefreshObj.currentUserChanges;
    this.currentUserChangesWithNestedProp = currentRefreshObj.currentUserChangesWithNestedProp;
    this.anotherUserChanges = currentRefreshObj.anotherUserChanges;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.submitButtonText = $localize`Save Event`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: EventFormObject) {
    this.viewModel.saveEvent(result);
  }

  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }

  // getObjProp(obj, propPath) {
  //   const [head, ...rest] = propPath.split('.');

  //   return !rest.length
  //     ? obj[head]
  //     : this.getObjProp(obj[head], rest.join('.'));
  // }

  setEventObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      const val = ConflictRecordUtils.getObjProp(conflictObj.event, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }

  // assignBorderColor(property): string {
  //   if (this.currentUserChanges.includes(property) && this.anotherUserChanges.includes(property)) {
  //     return 'red';
  //   } else if (this.anotherUserChanges.includes(property)) {
  //     return 'orange';
  //   } else if (this.currentUserChanges.includes(property)) {
  //     return 'blue';
  //   } else {
  //     return 'none';
  //   }
  // }

  // assignConflictIssueProperty(label, property, subProperty = null) {
  //   let tooltipText: any = '';
  //   const borderColor: string = this.assignBorderColor(property);

  //   if (borderColor === 'blue') {
  //     tooltipText = 'The ' + label + ' has not changed';
  //   } else if (this.currentUserChanges.includes(property) || this.anotherUserChanges.includes(property)) {
  //     const text = subProperty ? this.currentEvent[subProperty][property] : this.currentEvent[property];
  //     if (property === 'active') {
  //       tooltipText = 'Previous status: ' + (true ? 'Active' : 'Inactive');
  //     } else {
  //       tooltipText = 'Previous selected ' + label + ': ' + text;
  //     }
  //   }
  //   return { borderColor, tooltipText };
  // }

  setupFormItems() {
    const userChanges = {
      currentUserChanges: this.currentUserChanges,
      anotherUserChanges: this.anotherUserChanges
    };
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const infoTitle = new FormInputItem();
    infoTitle.itemType = FormItemType.Title;
    infoTitle.label = $localize`Info`;
    items.push(infoTitle);

    const eventName = new FormInputItem();
    eventName.inputName = 'eventName';
    eventName.inputType = FormInputType.Text;
    eventName.label = $localize`Event Name`;
    eventName.placeholder = $localize`Event Name`;
    eventName.bindingProperty = 'event.name';
    eventName.required = true;
    const nameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentEvent, 'name', 'name') : '';
    eventName.conflictType = nameData?.borderColor || nameData;
    eventName.tooltipText = nameData?.tooltipText;
    items.push(eventName);

    const eventAbbreviation = new FormInputItem();
    eventAbbreviation.inputName = 'eventAbbreviation';
    eventAbbreviation.inputType = FormInputType.Text;
    eventAbbreviation.label = $localize`Event Abbreviation`;
    eventAbbreviation.placeholder = $localize`Event Abbreviation`;
    eventAbbreviation.bindingProperty = 'event.abbreviation';
    eventAbbreviation.required = true;
    eventAbbreviation.customValidator = new AlphanumericValidatorDirective();
    const abbreviationData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentEvent, 'Abbreviation', 'abbreviation') : '';
    eventAbbreviation.conflictType = abbreviationData?.borderColor || abbreviationData;
    eventAbbreviation.tooltipText = abbreviationData?.tooltipText;
    items.push(eventAbbreviation);

    const subscriptionPlan = new FormInputItem();
    subscriptionPlan.itemType = FormItemType.Dropdown;
    subscriptionPlan.inputName = 'subscriptionPlanId';
    subscriptionPlan.label = $localize`Subscription Plan`;
    subscriptionPlan.placeholder = $localize`Choose a Subscription Plan`;
    subscriptionPlan.bindingProperty = 'event.subscriptionPlanId';
    subscriptionPlan.dropdownIsObject = true;
    subscriptionPlan.required = true;
    subscriptionPlan.dropdownOptions = [];
    const subscriptionData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentEvent, 'Plan', 'subscriptionPlanId') : '';
    subscriptionPlan.conflictType = subscriptionData?.borderColor || subscriptionData;
    subscriptionPlan.tooltipText = subscriptionData?.tooltipText;
    items.push(subscriptionPlan);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'event.active';
    active.customClass = 'mb-4 mt-0';
    active.valueChanged.subscribe(v => {
      if (!v[0]) {
        this.openDeactivateEventModal();
      }
    }).addTo(this.subscriptions);
    const eventStatusData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentEvent, 'Status', 'active') : '';
    active.conflictType = eventStatusData?.borderColor || eventStatusData;
    active.tooltipText = eventStatusData?.tooltipText || eventStatusData;
    items.push(active);

    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    items.push(FormInputItem.generateDivider());

    this.formItems = items;
    this.setupFormBindings();
  }

  setupFormBindings() {
    this.viewModel.updateFormItemStatesSubject$.subscribe(() => {
      setTimeout(() => {
        this.updateFormItemStates();
      });
    }).addTo(this.subscriptions);

    this.viewModel.subscriptionPlans$.notNull().subscribe(s => {
      setTimeout(() => {
        this.setSubscriptionPlanOptions(s);
      });
    }).addTo(this.subscriptions);
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute }).then();
  }

  setSubscriptionPlanOptions(subscriptionPlans: SubscriptionPlan[]) {
    setTimeout(() => {
      const subscriptionPlanInput = this.formItems.find(f => f.inputName === 'subscriptionPlanId');
      subscriptionPlanInput.dropdownOptions = subscriptionPlans;
    });
  }

  updateFormItemStates() {
    if (this.formItems.length === 0) {
      return;
    }
  }

  showEditPhotoModal() {
    this.viewModel.getLogo(this.formObject).pipe(take(1)).subscribe((logo) => {
      const modalRef = this.modalService.open(UploadImageModalComponent, ModalUtils.defaultMedium());
      const compInstance = modalRef.componentInstance as UploadImageModalComponent;
      compInstance.initWith(
        $localize`Add Event Logo`,
        $localize`Crop Event Logo`,
        logo
      );
      modalRef.result.then((result) => {
        if (result) {
          this.formObject.imageToUpload = result;
        } else {
          this.removeLogo();
        }
      }, () => {
      });
    }).addTo(this.subscriptions);
  }

  removeLogo() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  openDeactivateEventModal(): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Deactivate Event`;
    // eslint-disable-next-line max-len
    opts.bodyText = $localize`Deactivating a event will limit actions that admins can take with that event. Are you sure you want to deactivate this event?\n\nThis action can be reversed from the Events tab. `;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Deactivate Event`;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((deactivate) => {
      if (!deactivate) {
        const activeInput = this.formItems.find(i => i.inputName === 'active');
        activeInput?.setInputFormControlValue(true);
      }
    });
  }

  // get(obj, path) {
  //   return path.split('.').reduce((r, e) => {
  //     if (!r) { return r; }
  //     else { return r[e] || undefined; }
  //   }, obj);
  // }

  // diff(a, b, arr, prev = '') {
  //   return Object.keys(a).reduce((r, e) => {
  //     let value: any = '';
  //     const path = prev + (prev ? '.' + e : e);
  //     if (typeof a[e] == 'boolean') {
  //       value = a[e]?.toString() === b[e]?.toString();
  //     } else {
  //       value = a[e] === this.get(b, path);
  //     }
  //     // const value = a[e] === this.get(b, path);
  //     r[e] = typeof a[e] === 'object' ? this.diff(a[e], b, arr, path) : value;

  //     if (!r[e]) {
  //       if (path === 'active' || !this.exceptFieldsArr.includes(e)) {
  //         if (arr === 'red') {
  //           this.currentUserChanges.push(e);
  //           this.currentUserChangesWithNestedProp.push(path);
  //         }
  //         if (arr === 'orange') { this.anotherUserChanges.push(e); }
  //       }
  //     }
  //     return r;
  //   }, {});
  // }

}
