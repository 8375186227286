import {DatatableAction} from './datatable-action';
import {DatatableColumnFilter} from './datatable-column-filter';

export class DatatableColumn {
  public id: string;
  public title: string;
  public type: DatatableColumnType;
  public disableSorting: boolean = false;
  public isDefaultSortColumn: boolean = false;
  public className: string;
  public headerClassName: string = 'custom-datatable-header';
  public widthPercent: number;
  public actions: DatatableAction[];
  public tooltipIconSrc: string;
  public columnFilter: DatatableColumnFilter;
  public getCellValue: (val: any) => any;
  public getCellSubtextValue: (val: any) => any;
  public getNestedCellValue: (val: any) => any;
  public getCsvExportCellValue: (val: any) => any;
  public getTooltipValue: (val: any) => any;
  public getTooltipListValue: (val: any) => string[];
  public getTooltipListTitle: (val: any) => any;
  public customSortingMethod: (a: any, b: any) => number;

  constructor(id, title: string,
              type: DatatableColumnType,
              getCellValue: (val: any) => any,
              getNestedCellValue: ((val: any) => any) = () => '') {
    this.id = id;
    this.title = title;
    this.type = type;
    this.getCellValue = getCellValue;
    this.getNestedCellValue = getNestedCellValue;
    this.getCsvExportCellValue = getCellValue;
  }

  public getCustomCellClassName: (val: any) => string = (val => '');
  public iconClicked: (val: any) => any = (() => {});

  getWidth(): string {
    if (this.widthPercent && this.widthPercent > 0) {
      return `${this.widthPercent}%`;
    } else {
      return '';
    }
  }
}

export enum DatatableColumnType {
  Text,
  Checkbox,
  Actions,
  Label,
  Icon,
}
