
export enum AuthFlow {
  SignUp,
  SignIn,
  ForgotPassword,
  ResetPassword,
  SetNewPassword,
}

export enum SubscriberSignUpFlow {
  EmailVerification,
  EmailVerificationLinkExisted,
  EmailVerificationLinkExpired,
  EmailVerificationUnverified,
  CreateProfile,
  PickPlan,
  PickPlanPackage,
  Review,
  AddPaymentDetails,
  Completed,
  ChooseOrganizationToSupport,
  ProfileDetails,
}

export enum EditSubscriptionFlow {
  EditPlans,
  AddDiscount,
}
