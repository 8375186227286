import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {VenueStream} from '../../../../models/resources/venue-stream';
import {DeserializeHelper} from '../../../../models/protocols/deserializable';
import {SportType} from '../../../../models/lookup/sport-type';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {VenueDetailsViewModel} from '../venue-details/venue-details-view-model';
import {PixellotVenues} from '../../../../models/resources/pixellot-venues';
import {BehaviorSubject} from 'rxjs';
import {ToastService} from '../../../../services/toast-service';

@Component({
  selector: 'app-edit-venue-stream-modal',
  templateUrl: './edit-venue-stream-modal.component.html',
  styleUrls: ['./edit-venue-stream-modal.component.scss'],
  providers: [VenueDetailsViewModel],
})
export class EditVenueStreamModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: VenueStream;
  venueStream: VenueStream = null;
  venueStreamForPixellotCamera = new VenueStream();
  isEditing: boolean = false;
  pixellotVenues = new BehaviorSubject<PixellotVenues[]>(null);
  @Output() returnResult: EventEmitter<any> = new EventEmitter();
  @Input() concurrentUpdateStream: EventEmitter<any> = new EventEmitter();
  public concurrentUpdateFlag: boolean = false;
  venueId: number = null;
  sportTypes: SportType[] = [];
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];
  isLoading: boolean = false;
  exceptFieldsArr = [
    'timeStampVal',
    'updatedDate',
    'createdDate',
    '_isScalar',
    '_subscribe',
    'project',
    'predicate',
    'presignedUrl',
    'active',
    'cameraId'
  ];

  constructor(
    private viewModel: VenueDetailsViewModel,
    private activeModal: NgbActiveModal,
    private lookupDomainModel: LookupDomainModel,
    private toastService: ToastService,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.concurrentUpdateStream.subscribe(conflictObject => {

      // If concurrent issue occurs
      if (conflictObject) {
        this.viewModel.getActiveVenueStream(this.venueId, conflictObject.id).subscribe(refreshStream => {
          this.setSportsOptions(this.sportTypes);
          this.concurrentUpdateFlag = true;
          this.compareObjects(this.venueStream, conflictObject, refreshStream);
          this.setVenueObjAfterConflict(conflictObject, refreshStream);
          this.formObject = DeserializeHelper.deserializeToInstance(VenueStream, refreshStream);
          this.formObject.itemChanged = true;
          this.formObject.itemCreated = false;
          this.setupFormItems();
        });
      } else {
        this.concurrentUpdateFlag = false;
        this.setupFormItems();
      }
    });

    this.lookupDomainModel.sportTypes.notNull().subscribe(s => {
      setTimeout(() => {
        this.setSportsOptions(s);
      });
    }).addTo(this.subscriptions);
    this.viewModel.getPixellotVenues().notNull().subscribe((pv) => {
      setTimeout(() => {
        this.pixellotVenues.next(pv);
      });
    }).addTo(this.subscriptions);
  }

  setupViews() {
    if (this.venueStream) {
      this.formObject = DeserializeHelper.deserializeToInstance(VenueStream, this.venueStream);
      this.formObject.itemChanged = true;
      this.formObject.itemCreated = false;
    } else {
      this.formObject = new VenueStream();
      this.formObject.itemCreated = true;
    }
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const name = new FormInputItem();
    name.inputName = 'name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Stream Name`;
    name.bindingProperty = 'name';
    name.required = true;
    name.overrideFullWidth = true;
    const nameData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('name', 'name') : '';
    name.conflictType = nameData?.borderColor || nameData;
    name.tooltipText = nameData?.tooltipText;
    items.push(name);

    // const cameraName = new FormInputItem();
    // cameraName.inputName = 'cameraName';
    // cameraName.inputType = FormInputType.Text;
    // cameraName.label = $localize`Camera Name`;
    // cameraName.placeholder = $localize`Camera Name`;
    // cameraName.bindingProperty = 'CameraName';
    // cameraName.overrideFullWidth = true;
    // cameraName.valueChanged.subscribe((value) => {
    //   cameraId.setInputFormControlValue('');
    // });
    // cameraName.required = true;
    // items.push(cameraName);

    const cameraIdCheckbox = new FormInputItem();
    cameraIdCheckbox.itemType = FormItemType.CheckboxItem;
    cameraIdCheckbox.inputName = 'cameraIdCheckbox';
    cameraIdCheckbox.label = $localize`Camera Id Required`;
    cameraIdCheckbox.bindingProperty = 'cameraIdCheckbox';
    cameraIdCheckbox.valueChanged.subscribe(checked => {
      const cameraIdItem = items.find(item => item.inputName === 'hiddenCameraId');
      if (checked[0]) {
        getCameraIdButton.buttonDisabled = false;
        getCameraIdButton.customClass = 'custom-button preferred-button ml-auto';
        cameraIdItem.setRequired(true);
      } else {
        getCameraIdButton.buttonDisabled = true;
        getCameraIdButton.customClass = 'custom-button preferred-button ml-auto disabled';
        cameraIdItem.setRequired(false);
      }
    });
    cameraIdCheckbox.required = false;
    items.push(cameraIdCheckbox);

    const getCameraIdButton = new FormInputItem();
    getCameraIdButton.itemType = FormItemType.Button;
    getCameraIdButton.bindingProperty = 'getCameraIdButton';
    getCameraIdButton.inputName = 'getCameraIdButton';
    getCameraIdButton.required = false;
    getCameraIdButton.inputHasButton = true;
    getCameraIdButton.titleButtonText = this.isEditing ? $localize`Update Camera Id` : $localize`Get Camera Id`;
    getCameraIdButton.customClass = 'custom-button preferred-button ml-auto disabled';
    getCameraIdButton.titleButtonClicked.subscribe(() => {
      items.find(item => item.inputName === 'hiddenCameraId').setFormEnabledStatus(false);
      const cameraNameItem = items.find(item => item.inputName === 'cameraName');
      const typedCameraName = cameraNameItem?.getValue();
      const pixellotCameraId = this.findPixellotIdByName(typedCameraName);

      this.venueStreamForPixellotCamera.cameraId = pixellotCameraId;

      if (!pixellotCameraId) {
        this.toastService.publishErrorMessage($localize`Camera Id not found, please check camera name.`, null);
        cameraId.setInputFormControlValue('');
      } else {
        cameraId.setInputFormControlValue(pixellotCameraId);
      }
    });
    items.push(getCameraIdButton);

    const cameraId = new FormInputItem();
    cameraId.inputName = 'cameraId';
    cameraId.inputType = FormInputType.Text;
    cameraId.label = $localize`Camera Id`;
    cameraId.placeholder = $localize`Camera Id`;
    cameraId.bindingProperty = 'cameraId';
    cameraId.setFormEnabledStatus(false);
    cameraId.overrideFullWidth = true;
    const cameraIdData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('camera ID', 'cameraId') : '';
    cameraId.conflictType = cameraIdData?.borderColor || cameraIdData;
    cameraId.tooltipText = cameraIdData?.tooltipText;
    items.push(cameraId);

    const hiddenCameraId = new FormInputItem();
    hiddenCameraId.inputName = 'hiddenCameraId';
    hiddenCameraId.inputType = FormInputType.Text;
    hiddenCameraId.label = $localize`Hidden Camera Id`;
    hiddenCameraId.itemType = FormItemType.Hidden;
    hiddenCameraId.placeholder = $localize`Hidden Camera Id`;
    hiddenCameraId.bindingProperty = 'cameraId';
    hiddenCameraId.setFormEnabledStatus(true);
    items.push(hiddenCameraId);

    const sportType = new FormInputItem();
    sportType.itemType = FormItemType.Dropdown;
    sportType.inputName = 'sportId';
    sportType.label = $localize`Sport`;
    sportType.placeholder = $localize`Choose a Sport`;
    sportType.bindingProperty = 'sportId';
    sportType.dropdownIsObject = true;
    sportType.required = true;
    sportType.overrideFullWidth = true;
    sportType.dropdownOptions = this.concurrentUpdateFlag ? this.sportTypes : [];
    const sportTypeData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('sport', 'sportId') : '';
    sportType.conflictType = sportTypeData?.borderColor || sportTypeData;
    sportType.tooltipText = sportTypeData?.tooltipText;
    items.push(sportType);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'active';
    active.overrideFullWidth = true;
    active.overrideFullWidth = true;
    const streamStatusData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('Status', 'active') : '';
    active.conflictType = streamStatusData?.borderColor || streamStatusData;
    active.tooltipText = streamStatusData?.tooltipText;
    items.push(active);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: VenueStream) {
    // this.activeModal.close(result);
    this.returnResult.emit(result);
  }

  getModalTitle(): string {
    return this.isEditing ? $localize`Edit Stream` : $localize`Add New Stream`;
  }

  deleteStreamClicked() {
    this.formObject.itemDeleted = true;
    this.activeModal.close(this.formObject);
  }

  setSportsOptions(sports: SportType[]) {
    setTimeout(() => {
      const sportInput = this.formItems.find(f => f.inputName === 'sportId');
      this.sportTypes = sports;
      sportInput.dropdownOptions = sports;
    });
  }

  findPixellotIdByName(name: string): string {
    return this.pixellotVenues.getValue().find((pv) => {
      return pv.name === name;
    })?.id.toString();
  }
  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }

  assignBorderColor(property): string {
    if (this.currentUserChanges.includes(property) && this.anotherUserChanges.includes(property)) {
      return 'red';
    } else if (this.anotherUserChanges.includes(property)) {
      return 'orange';
    } else if (this.currentUserChanges.includes(property)) {
      return 'blue';
    } else {
      return 'none';
    }
  }

  getSportTitle(id) {
    return this.sportTypes.find(s => s.id === id).pixellotEnumValue;
  }

  assignConflictIssueProperty(label, property, subProperty = null) {
    let tooltipText: any = '';
    const borderColor: string = this.assignBorderColor(property);

    if (borderColor === 'blue') {
      tooltipText = 'The ' + label + ' has not changed';
    } else if (this.currentUserChanges.includes(property) || this.anotherUserChanges.includes(property)) {
      let text = subProperty ? this.venueStream[subProperty][property] : this.venueStream[property];
      text = property === 'sportId' ? this.getSportTitle(text) : text;
      if (property === 'active') {
        tooltipText = 'Previous status: ' + (true ? 'Active' : 'Inactive');
      } else {
        tooltipText = 'Previous selected ' + label + ': ' + text;
      }
    }
    return { borderColor, tooltipText };
  }

  getObjProp(obj, propPath) {
    const [head, ...rest] = propPath.split('.');

    return !rest.length
      ? obj[head]
      : this.getObjProp(obj[head], rest.join('.'));
  }

  setVenueObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      const val = this.getObjProp(conflictObj, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }

  compareObjects(currentObj, conflictObj, refreshObj) {
    const result1 = this.diff(currentObj, conflictObj, 'red');
    const result2 = this.diff(currentObj, refreshObj, 'orange');
  }

  get(obj, path) {
    return path.split('.').reduce((r, e) => {
      if (!r) { return r; }
      else { return r[e] || undefined; }
    }, obj);
  }

  diff(a, b, arr, prev = '') {
    return Object.keys(a).reduce((r, e) => {
      let value: any = '';
      const path = prev + (prev ? '.' + e : e);
      if (typeof a[e] == 'boolean') {
        value = a[e]?.toString() === b[e]?.toString();
      } else {
        value = a[e] === this.get(b, path);
      }
      // const value = a[e] === this.get(b, path);
      r[e] = typeof a[e] === 'object' ? this.diff(a[e], b, arr, path) : value;

      if (!r[e]) {
        if (path === 'active' || !this.exceptFieldsArr.includes(e)) {
          if (arr === 'red') {
            this.currentUserChanges.push(e);
            this.currentUserChangesWithNestedProp.push(path);
          }
          if (arr === 'orange') { this.anotherUserChanges.push(e); }
        }
      }
      return r;
    }, {});
  }

}
