import {Component, EventEmitter} from '@angular/core';
import {AdminTeamsViewModel} from './admin-teams-view-model';
import {BaseComponent} from '../../../models/base/base-component';
import {DatatableOptions} from '../../../models/shared/stylesheet/datatable-options';
import {DatatableFilter} from '../../../models/shared/datatable-filter';
import {BaseDatatableDataProvider} from '../../../models/base/base-datatable-data-provider';
import {ActivatedRoute, Router} from '@angular/router';
import {DatatableData} from '../../../models/protocols/datatable-data';
import {DatatableColumn, DatatableColumnType} from '../../../models/shared/stylesheet/datatable-column';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../models/shared/stylesheet/datatable-column-filter';
import {HydratedTeam} from '../../../models/resources/hydrated-team';
import {EditTeamModalComponent} from '../shared/edit-team-modal/edit-team-modal.component';
import {ModalUtils} from '../../../utils/modal-utils';
import {TeamFormObject} from '../../../models/resources/team-form-object';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from 'src/app/services/toast-service';

@Component({
  selector: 'app-admin-teams',
  templateUrl: './admin-teams.component.html',
  styleUrls: ['./admin-teams.component.scss'],
  providers: [AdminTeamsViewModel],
})
export class AdminTeamsComponent extends BaseComponent {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public userDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetTable = new EventEmitter();
  public updateTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();

  constructor(
    public viewModel: AdminTeamsViewModel,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {
    super();
  }

  setupBindings() {
    this.viewModel.teams$.notNull().subscribe(s => {
      setTimeout(() => {
        const dataProvider = new BaseDatatableDataProvider();
        dataProvider.data = s;
        this.updateTableData.next(dataProvider);
      });
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupDatatable();
  }

  rowClicked(data: DatatableData) {
    const team = data as HydratedTeam;
    this.editTeam(TeamFormObject.initWithTeam(team));
  }

  setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.setDatatableFilter();
  }

  getDatatableColumns(): DatatableColumn[] {
    const columns: DatatableColumn[] = [];
    const teamName = new DatatableColumn(
      'teamName',
      $localize`Name`,
      DatatableColumnType.Text,
      (hydratedTeam: HydratedTeam): string => {
        return hydratedTeam.name;
      }
    );
    teamName.isDefaultSortColumn = true;
    columns.push(teamName);

    const teamLocation = new DatatableColumn(
      'teamLocation',
      $localize`Location`,
      DatatableColumnType.Text,
      (hydratedTeam: HydratedTeam): string => {
        return hydratedTeam.location;
      }
    );
    columns.push(teamLocation);

    const hasLogo = new DatatableColumn(
      'hasLogo',
      $localize`Logo`,
      DatatableColumnType.Text,
      (hydratedTeam: HydratedTeam): string => {
        return (hydratedTeam.logos?.length > 0) ? $localize`Yes` : $localize`No`;
      }
    );
    columns.push(hasLogo);

    const hasBanner = new DatatableColumn(
      'hasBanner',
      $localize`Banner Image`,
      DatatableColumnType.Text,
      (hydratedTeam: HydratedTeam): string => {
        return (hydratedTeam.bannerImages?.length > 0) ? $localize`Yes` : $localize`No`;
      }
    );
    columns.push(hasBanner);

    const activeTeam = new DatatableColumn(
      'activeTeam',
      $localize`Status`,
      DatatableColumnType.Text,
      (t: HydratedTeam): string => {
        return t.active ? $localize`Active` : $localize`Inactive`;
      }
    );
    activeTeam.className = 'custom-datatable-header text-right mr-2';
    activeTeam.headerClassName = 'custom-datatable-header text-right mr-2';
    activeTeam.columnFilter = this.getStatusColumnFilter();
    columns.push(activeTeam);

    return columns;
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  addNewTeamClicked() {
    this.createNewTeam();
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }


  private createNewTeam() {
    const modalRef = this.modalService.open(
      EditTeamModalComponent,
      ModalUtils.defaultModalOptions(),
    );
    const instance = (modalRef.componentInstance as EditTeamModalComponent);
    instance.initWithNewFormObject();
    // modalRef.result.then((teamFormObject: TeamFormObject) => {
    //   if (teamFormObject) {
    //     this.viewModel.saveTeam(teamFormObject);
    //   }
    // }, reason => {
    // });
    modalRef.componentInstance.returnResult.subscribe((teamFormObject: TeamFormObject) => {
      if (teamFormObject) {
        this.saveTeam(teamFormObject,modalRef);
      }
    }, reason => {
    });
  }

  private editTeam(teamFormObject: TeamFormObject) {
    const modalRef = this.modalService.open(
      EditTeamModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const instance = (modalRef.componentInstance as EditTeamModalComponent);
    instance.currentTeam = teamFormObject.team;
    instance.initWithFormObject(teamFormObject);
    instance.canRemoveTeamOnEdit = false;
    // modalRef.result.then((updatedTeamFormObject: TeamFormObject) => {
    //   if (updatedTeamFormObject) {
    //     this.viewModel.saveTeam(updatedTeamFormObject);
    //   }
    // }, reason => {
    // });
    modalRef.componentInstance.returnResult.subscribe((updatedTeamFormObject: TeamFormObject) => {
      if (updatedTeamFormObject) {
        this.saveTeam(updatedTeamFormObject,modalRef);
      }
    }, reason => { });
  }

  setLoadingOpts(ref, flag) {
    ref.componentInstance.isLoading = flag;
  }

  saveTeam(teamFormObject: TeamFormObject,ref) {
    this.setLoadingOpts(ref, true);
    this.viewModel.saveTeam(teamFormObject).subscribe((updatedTeam) => {
      this.setLoadingOpts(ref, false);
      ref.componentInstance.concurrentUpdateTeam.next(null);
      this.toastService.publishSuccessMessage($localize`Team Saved`, null);
      this.modalService.dismissAll();
    }, error => {
      this.setLoadingOpts(ref, false);
      //for concurrent error
      if(error.code === 400){
        ref.componentInstance.concurrentUpdateTeam.next(teamFormObject);
      }
      this.toastService.publishErrorMessage(error.message, $localize`Team Save Failed`);
    });
  }
}
