<app-detail-title>
  <div class="d-flex justify-content-between">
    <ng-container i18n>Sports</ng-container>
    <button class="custom-button preferred-button" (click)="viewModel.addNewSport()" i18n>Add a New Sport
    </button>
  </div>
</app-detail-title>
<div class="mt-3 position-relative">
    <app-loading
      [hidden]="!viewModel.loadingOpts?.isLoading"
      [options]="viewModel.loadingOpts">
    </app-loading>
  <app-data-table
    [tableData]="userDataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetUsersTable"
    [updateTableData]="updateSportsTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="sportClicked($event)"
    [tableOptions]="datatableOptions">
  </app-data-table>
</div>
