<div class="modal-body">
  <app-loading
    [hidden]="!(isLoading)">
  </app-loading>
  <div class="modal-title">
    {{getModalTitle()}}
  </div>
  <app-form-group
    class="d-block pt-3"
    *ngIf="formItems?.length > 0"
    [formItems]="formItems"
    [formObject]="formObject"
    [styling]="formStyling"
    [options]="formOptions"
    (formCancelled)="cancel()"
    (secondaryButtonPressed)="deleteSeasonClicked()"
    (formSubmitted)="formSubmitted()">
  </app-form-group>
  <div >
    <button
      (click)="deleteSeasonClicked()"
      *ngIf="isEditing && canRemoveSeasonOnEdit"
      class="custom-button destructive-button mr-auto"
      i18n>
      Delete Season
    </button>
  </div>
</div>
