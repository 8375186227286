import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Session} from './session';
import {User} from './user';

export class HydratedUser extends User implements Deserializable {
  public session: Session;
  public sessionId: string;
  public challengeParameters: Map<string, string>;
  public challengeName: string;

  public onDeserialize() {
    super.onDeserialize();
    this.challengeParameters = DeserializeHelper.deserializeGenericMap(this.challengeParameters);
    this.session = DeserializeHelper.deserializeToInstance(Session, this.session);
  }
}

