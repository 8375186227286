import {Component} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageApi} from '../../../../api/image-api';
import {AddRunDatesViewModel} from './add-run-dates-view-model';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {AdvertisementRunDate} from '../../../../models/resources/advertisement-run-date';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-add-league-modal',
  templateUrl: './add-run-dates-modal.component.html',
  styleUrls: ['./add-run-dates-modal.component.scss'],
  providers: [AddRunDatesViewModel]
})
export class AddRunDatesModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject = new AdvertisementRunDate();

  constructor(
    public viewModel: AddRunDatesViewModel,
    public modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private imageApi: ImageApi,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.viewModel.formErrorMessage.pipe(debounceTime(100)).subscribe(error => {
      const banner = this.formItems.find(f => f.alertBannerId === 'banner');
      if (banner) {
        banner.alertBannerMessage = error;
      }
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const startDate = new FormInputItem();
    startDate.itemType = FormItemType.Input;
    startDate.inputType = FormInputType.Date;
    startDate.inputName = 'startDate';
    startDate.label = $localize`Start Date`;
    startDate.placeholder = $localize`Enter Start Date`;
    startDate.bindingProperty = 'startDateUtc';
    startDate.required = true;
    items.push(startDate);

    const endDate = new FormInputItem();
    endDate.itemType = FormItemType.Input;
    endDate.inputType = FormInputType.Date;
    endDate.inputName = 'endDate';
    endDate.label = $localize`End Date`;
    endDate.placeholder = $localize`Enter End Date`;
    endDate.bindingProperty = 'endDateUtc';
    endDate.required = true;
    items.push(endDate);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    // primary buttons
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Add`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    if (this.viewModel.isFormValid(this.formObject)) {
      this.activeModal.close(this.formObject);
    }
  }
}
