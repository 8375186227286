import {Component} from '@angular/core';
import {ProgramDetailsViewModel} from '../program-details-view-model';
import {BaseComponent} from '../../../../../models/base/base-component';
import {Tabbable} from '../../../../../models/protocols/tabbable';
import {ToastService} from '../../../../../services/toast-service';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-program-details-stream',
  templateUrl: './program-details-stream.component.html',
  styleUrls: ['./program-details-stream.component.scss'],
})
export class ProgramDetailsStreamComponent extends BaseComponent implements Tabbable {

  hideIngestServerKey = true;

  constructor(
    public viewModel: ProgramDetailsViewModel,
    private clipboard: Clipboard,
    private toastService: ToastService,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  copyIngestKeyToClipboard() {
    this.clipboard.copy(this.viewModel.program$.value?.amazonIngestStreamKey ?? '');
    this.toastService.publishSuccessMessage($localize`Ingest server key copied to clipboard`, null);
  }

  copyIngestUrlToClipboard() {
    this.clipboard.copy(this.viewModel.program$.value?.amazonIngestStreamUrl ?? '');
    this.toastService.publishSuccessMessage($localize`Ingest server copied to clipboard`, null);
  }

  copyPixellotStreamUrlToClipboard() {
    this.clipboard.copy(this.viewModel.program$.value?.pixellotHDStreamUrl ?? '');
    this.toastService.publishSuccessMessage($localize`Pixellot Stream Url copied to clipboard`, null);
  }

  pixellotPanoStreamUrl() {
    this.clipboard.copy(this.viewModel.program$.value?.pixellotPANOStreamUrl ?? '');
    this.toastService.publishSuccessMessage($localize`Pixellot Pano Stream Url copied to clipboard`, null);
  }
}
