import {Component} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddVenueViewModel} from './add-venue-view-model';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import AddressUtils from '../../../../utils/address-utils';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';

@Component({
  selector: 'app-add-venue-modal',
  templateUrl: './add-venue-modal.component.html',
  styleUrls: ['./add-venue-modal.component.scss'],
  providers: [AddVenueViewModel]
})
export class AddVenueModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject = {venue: null};

  constructor(
    public viewModel: AddVenueViewModel,
    public modalService: NgbModal,
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.viewModel.venues$.notNull().subscribe(v => {
      setTimeout(() => {
        this.updateVenueSearchValues(v);
      });
    });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const venueSearch = new FormInputItem();
    venueSearch.itemType = FormItemType.Input;
    venueSearch.inputType = FormInputType.Search;
    venueSearch.inputName = 'venueSearch';
    venueSearch.label = 'Search For Venue';
    venueSearch.placeholder = 'Search';
    venueSearch.bindingProperty = 'venue';
    venueSearch.required = true;
    venueSearch.inlineLabel = true;
    venueSearch.customError = new Map<string, string>([
      ['searchFor', 'Venue Not Found'],
    ]);
    venueSearch.searchable = [];
    items.push(venueSearch);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Add`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    this.activeModal.close(this.formObject.venue);
  }

  private updateVenueSearchValues(venues: HydratedVenue[]) {
    const venueSearchFormItem = this.formItems.find(f => f.inputName === 'venueSearch');
    venueSearchFormItem.searchable.splice(0);
    venueSearchFormItem.searchable.push(...venues.map(v => {
      return {
        title: v.name,
        subtitle: AddressUtils.singleLineFormat(v.address),
        lookupKey: v.name,
        value: v,
      };
    }));
  }
}
