<app-detail-title i18n>
  Programs
</app-detail-title>
<app-content-container [narrow]="true">
  <div class="row align-items-center justify-content-between">
    <div class="col-6">
      <app-detail-back-button class="d-block mt-4" i18n>Program Details</app-detail-back-button>
    </div>
      <div class="d-flex justify-content-start">
        <div *ngIf="isInProgress()" class="pr-0">
          <button class="custom-button preferred-button mr-3 "
               (click)="completeProgramWarningButton()">Complete Program
          </button>
        </div>
      </div>
  </div>
  <div class="position-relative min-height-20">
    <app-loading
      [hidden]="!viewModel.loadingOpts?.isLoading"
      [options]="viewModel.loadingOpts">
    </app-loading>
    <div class="mt-4">
      <hr>
      <div class="label-md mb-4" i18n>Descriptions</div>
      <app-form-group
        *ngIf="formItems?.length > 0"
        [formItems]="formItems"
        [formObject]="formObject"
        [styling]="formStyling"
        [options]="formOptions"
        [hydrateInputObject]="hydrateInputObject"
        [showTertiaryButton]="this.showTertiaryButtonCondition()"
        (formCancelled)="cancel()"
        (tertiaryButtonPressed)="handleTertiaryButtonCondition()"
        (formSubmitted)="formSubmitted($event)"
      >
        <div class="w-100">
          <div *ngIf="viewModel.showStreamDetails$|async">
            <div class="label-md mb-4" i18n>Stream Info</div>
            <app-program-details-stream></app-program-details-stream>
            <hr>
          </div>
          <div class="label-md mb-4" i18n>Image</div>
          <app-upload-asset
            *ngIf="!formObject.imageToUpload && !formObject.existingImageId"
            [allowTypes]="['image']"
            [maxAssets]="1"
            [parentHandler]="this">
          </app-upload-asset>
          <div class="program-image-container" *ngIf="formObject.imageToUpload || formObject.existingImageId">
            <div class="program-image">
              <img *ngIf="formObject.existingImageId" [src]="(formObject.program.imgSrc$|async)" alt="">
              <img *ngIf="!formObject.existingImageId" [src]="formObject.imageToUpload" alt="">
            </div>
            <div class="text-link-button my-4" (click)="removeImageClicked()" i18n>Remove Image</div>
          </div>
        </div>
      </app-form-group>
    </div>
  </div>
</app-content-container>
