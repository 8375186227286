import {Component} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageApi} from '../../../../api/image-api';
import {AddExistingTeamViewModel} from './add-existing-team-view-model.service';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {HydratedTeam} from '../../../../models/resources/hydrated-team';

@Component({
  selector: 'app-add-existing-team-modal',
  templateUrl: './add-existing-team-modal.component.html',
  styleUrls: ['./add-existing-team-modal.component.scss'],
  providers: [AddExistingTeamViewModel]
})
export class AddExistingTeamModalComponent extends BaseModal {

  hideCreateNewTeamButton = false;
  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject = {team: null};

  constructor(
    public viewModel: AddExistingTeamViewModel,
    public modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private imageApi: ImageApi,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.viewModel.activeHydratedTeams$.notNull().subscribe(t => {
      setTimeout(() => {
        this.updateTeamSearchValues(t);
      });
    });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const teamSearch = new FormInputItem();
    teamSearch.itemType = FormItemType.Input;
    teamSearch.inputType = FormInputType.Search;
    teamSearch.inputName = 'teamSearch';
    teamSearch.label = 'Search For Team';
    teamSearch.placeholder = 'Search';
    teamSearch.bindingProperty = 'team';
    teamSearch.required = true;
    teamSearch.inlineLabel = true;
    teamSearch.customError = new Map<string, string>([
      ['searchFor', 'Team Not Found'],
    ]);
    teamSearch.searchable = [];
    items.push(teamSearch);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Add`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    this.activeModal.close(this.formObject.team);
  }

  private updateTeamSearchValues(teams: HydratedTeam[]) {
    const teamSearchFormItem = this.formItems.find(f => f.inputName === 'teamSearch');
    teamSearchFormItem.searchable.splice(0);
    teamSearchFormItem.searchable.push(...teams.map(t => {
      return {
        title: t.name,
        subtitle: t.location,
        lookupKey: t.location + ' ' + t.name,
        value: t,
      };
    }));
  }

  createNewTeamClicked() {
    this.activeModal.close(null);
  }
}
