/* eslint-disable object-shorthand */
import {Injectable} from '@angular/core';
import {NavigationEnd} from '@angular/router';
import {environment} from '../../environments/environment';
import {Program} from '../models/program/program';
import {Show} from '../models/program/show';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {
  }

  public trackEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    gtag('event', eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
      debug_mode: !environment.production
    });
  }

  public trackVideoEvent(program: Program|Show, action: 'play' | 'pause') {
    const videoVenue = (program as Program)?.venue;
    const videoEvent: string = null; //(program as Program)?.event; // TODO add event name here
    // gtag('config', environment.googleAnalyticsTackingCode, {
    //   custom_map: {
    //     dimension1: 'video_action',
    //     dimension2: 'video_league',
    //     dimension3: 'video_venue',
    //     dimension4: 'video_event'
    //   }
    // });
    gtag('event', 'video', {
      video_url: program?.playbackStreamUrl,
      video_title: program?.getGoogleAnalyticsEventTitle(),
      video_action: action,
      debug_mode: !environment.production,
      video_league: program.league ?? undefined,
      video_venue: videoVenue ?? undefined,
      video_event: videoEvent ?? undefined,
    });
  }

  public trackPathChange(event: NavigationEnd) {
    gtag('config', environment.googleAnalyticsTackingCode, {page_path: event.urlAfterRedirects, debug_mode: true});
  }
}
