import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BaseComponent} from 'src/app/models/base/base-component';
import {ProgramRowViewModel} from './program-row-view-model';
import {CardRowComponent, CardRowStyle} from '../../../shared/components/card-row/card-row.component';
import {Program} from '../../../../models/program/program';
import {Cardable} from '../../../../models/protocols/cardable';
import {Router} from '@angular/router';
import {ContentQuery} from '../../../../models/program/content-query';
import {Show} from '../../../../models/program/show';


@Component({
  selector: 'app-program-row',
  templateUrl: './program-row.component.html',
  styleUrls: ['./program-row.component.scss'],
  providers: [ProgramRowViewModel],
})
export class ProgramRowComponent extends BaseComponent implements OnChanges {

  @ViewChild('cardRowComponent') cardRowComponent: CardRowComponent;
  @Input() programRowType: ProgramRowType;
  @Input() programContentQuery: ContentQuery;
  @Input() navigateOnClick: boolean = true;
  @Input() seeAllGamesClicked: (() => void) = this.seeAllPastGames;
  @Output() noResults = new EventEmitter<boolean>();
  @Output() handleCardClicked = new EventEmitter<Cardable>();
  cardRowStyles = CardRowStyle;
  favouritesTabIndex = 1;

  constructor(
    public viewModel: ProgramRowViewModel,
    public router: Router,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.programRowType) {
      this.viewModel.programRowType$.next(this.programRowType);
    }
    if (!!changes.programContentQuery) {
      this.viewModel.programContentQuery$.next(this.programContentQuery);
    }
  }

  setupViews() {
  }

  setupBindings() {
    this.viewModel.cards$.subscribe(c => {
      this.noResults.next(!!c && c?.length === 0);
      this.cardRowComponent.updatedCards();
    }).addTo(this.subscriptions);
  }

  seeAllPastGames() {
    this.router.navigate([`/see-all`]).then();
  }

  cardClicked(card: Cardable) {
    this.handleCardClicked.next(card);

    if (!this.navigateOnClick) {
      return;
    }

    if (card instanceof Program) {
      const program = card as Program;
      if (!!program?.venueId && !!program?.venueStreamId) {
        this.router.navigate([`/home/venues/stream/${program.venueStreamId}/${program.id}`]).then();
      } else {
        this.router.navigate([`/home/${program.id}`]).then();
      }
    }

    if (card instanceof Show) {
      this.router.navigate([`/home/show/${card.id}`]).then();
    }
  }

  updatePreferencesClicked() {
    this.router.navigate(['/account'], {fragment: 'preferences'});
  }

  loadMorePrograms() {
    this.viewModel.loadNextPage$.next();
  }
}

export enum ProgramRowType {
  Live,
  Upcoming,
  Past,
  Shows
}
