/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { SessionService } from '../services/session-service';
import { SignInPortal, SignInRequest } from '../models/account/requests/sign-in-request';
import { AccountAPI } from '../api/account-api';
import { catchError, concatMap, debounceTime, delay, filter, map, shareReplay, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { HydratedUser } from '../models/account/dto/hydrated-user';
import { CacheService } from '../services/cache-service';
import { DefaultCacheKey } from '../models/enum/shared/default-cache-key.enum';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { Session } from '../models/account/dto/session';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { ResetPasswordRequest } from '../models/account/requests/reset-password-request';
import { ResetPasswordResponse } from '../models/account/requests/reset-password-response';
import { UserApi } from '../api/user-api';
import { ImageApi } from '../api/image-api';
import { SignUpRequest } from '../models/account/requests/sign-up-request';
import { User } from '../models/account/dto/user';
import { ChangePasswordRequest } from '../models/account/requests/change-password-request';
import { Image } from '../models/image/dto/image';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ImageSize } from '../models/enum/dto/image-size.enum';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { MediaType } from '../models/enum/dto/media-type.enum';
import { Favourites } from '../models/resources/favourites';
import { CreateAdminRequest } from '../models/account/requests/create-admin-request';
import { ResetPasswordFormObject } from '../models/account/requests/reset-password-form-object';
import { AuthChallenge } from '../models/account/dto/auth-challenge';
import { UsersDomainModel } from './users-domain-model';
import { SubscriberSubscriptionRequest, SubscriptionCouponRequest, SubscriptionPlan } from '../models/account/dto/subscription-plan';
import { SubscriberSubscription, SubscriptionStatus } from '../models/account/dto/subscriber-subscription';
import { CreateSubscriberPaymentDetails, SubscriberPaymentDetails } from '../models/account/dto/subscriber-payment-details';
import { ToastrService } from 'ngx-toastr';
import { Coupon } from '../models/account/dto/coupon';
import { SubscriberInvoice } from '../models/account/dto/subscriber-invoice';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../services/toast-service';
import Timeout = NodeJS.Timeout;
import {AuthFlow} from '../models/account/enum/auth-flow.enum';
import {OpenAuthModalOptions} from '../models/account/open-auth-modal-options';
import {EmailVerification} from '../models/account/requests/email-verification';
import {CustomError} from '../models/shared/custom-error';

@Injectable({
  providedIn: 'root'
})
export class AccountDomainModel extends BaseDomainModel {

  public lastSignInRequest: SignInRequest;

  public loginSuccessful = new Subject<HydratedUser>();
  public refreshSessionResult: BehaviorSubject<Session> = new BehaviorSubject<Session>(null);
  public sessionContainer$ = this.session.sessionContainer;
  public authHeartbeatInterval: Timeout;


  refreshSubscriberSubscriptions$ = new BehaviorSubject<void>(null);
  public subscriberSubscriptions$: Observable<SubscriberSubscription[]> = this.refreshSubscriberSubscriptions$.pipe(
    debounceTime(200),
    switchMapTo(this.sessionContainer$),
    switchMap((s) => {
      if (!s) {
        return of(null);
      }
      return this.getSubscriberSubscriptions();
    }),
    shareReplay(1),
  );

  constructor(
    public session: SessionService,
    private accountApi: AccountAPI,
    private userApi: UserApi,
    private imageApi: ImageApi,
    private cacheService: CacheService,
    private toastr: ToastrService,
    private usersDomainModel: UsersDomainModel,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private sessionService: SessionService,
  ) {
    super();
    this.init();
  }

  public init() {
    this.setupBindings();
  }

  private setupBindings() {
    // Bind to destroy session
    this.session.destroySession.notNull().subscribe((_) => {
      this.session.refreshingSession.next(false);
      this.refreshSessionResult.next(null);
    }).addTo(this.subscriptions);

    this.session.sessionContainer.subscribe(s => {
      if (!!s && !s.user?.roleId && !s.user?.vip) {
        this.intervalFunction();
      } else {
        clearInterval(this.authHeartbeatInterval);
      }
      if (!!s?.user?.roleId) {
        this.toastr.toastrConfig.toastClass = 'custom-toast ngx-toastr admin-toast';
      } else {
        this.toastr.toastrConfig.toastClass = 'custom-toast ngx-toastr';
      }
    }).addTo(this.subscriptions);
  }

  public isAuthenticated(forceRefresh: boolean = false): Observable<Session> {
    return combineLatest([
      this.session.sessionContainer,
      this.session.refreshingSession
    ]).pipe(
      filter(([, s]) => !s),
      concatMap(([container, refreshingSession]) => {
        if (!container) {
          container = this.session.getCachedSession();
        }
        if (container?.validSession() && !refreshingSession && !forceRefresh) {
          this.session.isUserSubscriber();
          return of(container.user.session);
        } else {
          const req = this.session.getRefreshSessionReq(container);
          if (!!req) {
            this.session.refreshingSession.next(true);
            const isSubscriber = this.session.isUserSubscriber();
            const email = this.session.getUser()?.email;
            return this.accountApi.refreshSession(email, req, isSubscriber).pipe(
              tap(refreshResponse => {
                const user = this.session.getUser();
                const refreshToken = user.session.refreshToken;
                user.session = refreshResponse.session;
                if (!user.session.refreshToken) {
                  user.session.refreshToken = refreshToken;
                }
                const userRole = this.session.getRole();
                this.session.setUser(user, userRole, true, container.rememberSession);
              }),
              delay(1000),
              switchMap(u => {
                this.session.refreshingSession.next(false);
                return of(u.session);
              }),
              catchError(e => {
                this.session.destroySession.next(true);
                this.session.refreshingSession.next(false);
                return of(null).pipe(tap(_ => this.signOut()));
              })
            );
          } else {
            // No refreshSession req in cache
            return of(null);
          }
        }
      }),
      take(1)
    );
  }

  emailVerification(emailVerification: EmailVerification): Observable<EmailVerification> {
    return this.accountApi.emailVerification(emailVerification);
  }

  resendEmailVerification(emailVerification: EmailVerification): Observable<EmailVerification> {
    return this.accountApi.reverifyEmail(emailVerification);
  }

  validateEmailVerificationToken(token: string) {
    return this.accountApi.validateEmailVerificationToken(token);
  }

  // Admin Auth Methods

  public adminSignIn(req: SignInRequest): Observable<HydratedUser> {
    const signIn$ = this.accountApi.signIn(req, 'admin');
    const userAndRole$: Observable<any> = signIn$.pipe(
      switchMap(user => !user.session || user.challengeName === AuthChallenge.NewPasswordChallenge ?
        of([user, null]) :
        this.userApi.getRoleForSignIn(user.roleId, user.session.accessToken).pipe(map(role => [user, role]))
      ));
    return userAndRole$.pipe(
      map(([user, role]) => {
        // Clear existing session from cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        // Set the new user in the session
        this.session.setUser(user, role, true, req.rememberMe);
        return user;
      })
    );
  }

  public signOut() {
    const user = this.session.getUser();
    this.accountApi.signOut(user?.email).subscribe(() => {
    });
    // Don't wait for sign out response, just kill the session
    this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
    this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
    this.session.destroySession.next(true);
  }

  signOutSubscribers(): Observable<any> {
    return this.accountApi.subscriberSignOut(this.sessionContainer$.value?.user.email ?? this.lastSignInRequest.email).pipe(tap(() => {
      this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
      this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
      this.session.destroySession.next(true);
    }
    ));
  }

  intervalSignOutSubscribers() {
    this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
    this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
    this.session.destroySession.next(true);
    this.toastService.publishErrorMessage($localize`You have been logged out. A login has been detected on another device.`, null);
    this.router.navigate(['']).then();
  }

  public sendPasswordResetCode(email: string, isAdmin: boolean): Observable<string> {
    return this.accountApi.getPasswordResetCode(email, isAdmin);
  }

  public resetForgottenPassword(email: string, isAdmin: boolean, req: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    return this.accountApi.resetForgottenPassword(email, isAdmin, req);
  }

  getUser(): HydratedUser {
    return this.session.getUser();
  }

  userAgreedToEULA(): Observable<any> {
    return this.session.sessionContainer.pipe(
      take(1),
      switchMap(s => {
        const updatedUser = s.user;
        updatedUser.eulaConfirmation = true;
        return this.userApi.updateSubscriber(updatedUser).pipe(tap(() => {
          const remember = this.session.sessionContainer.getValue()?.rememberSession;
          const user = this.session.getUser();
          const userRole = this.session.getRole();
          user.eulaConfirmation = true;
          this.session.setUser(user, userRole, true, remember);
        }));
      })
    );
  }

  updateSubscriberFavourites(favourites: Favourites): Observable<User> {
    return this.session.sessionContainer.pipe(
      take(1),
      switchMap(s => {
        const user = s.user;
        user.favouriteLeagues = favourites.favouriteLeagues.favouriteLeagues;
        user.favouriteTeams = favourites.favouriteTeams.favouriteTeams;
        this.session.setUser(user, s.userRole, false, s.rememberSession);
        return this.userApi.updateSubscriber(user);
      }
      ));
  }

  updateSessionUser(updatedUser: HydratedUser) {
    this.session.sessionContainer.pipe(take(1)).subscribe(s => {
      this.session.setUser(updatedUser, s.userRole, false, s.rememberSession);
    });
  }

  updateSubscriber(user: User, uploadLogo: string, deleteLogoId: string): Observable<any> {
    const obs: Observable<any>[] = [];
    obs.push(this.userApi.updateSubscriber(user));

    if (uploadLogo) {
      const uploadLogoReq = new GenerateUploadUrlRequest();
      uploadLogoReq.mediaType = MediaType.PNG;
      uploadLogoReq.fileName = new Date().getTime() + '.png';
      const uploadObs = this.imageApi.createSubscriberImage(user.id as string, uploadLogoReq).pipe(switchMap(uploadLogoAsset => {
        const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
        return this.imageApi.putImageUploadUrl(uploadUrl, uploadLogo, uploadLogoReq.fileName)
          .pipe(tap(a => this.imageApi.imageUploadSuccessful('subscriber', user.id.toString(), uploadLogo)));
      }));
      obs.push(uploadObs);
    }

    if (deleteLogoId) {
      const deleteLogoObs = this.userApi.deleteSubscriberImage(user.id as string, deleteLogoId).pipe(tap(() => {
        this.imageApi.clearCachedImages('subscriber', user.id.toString());
      }));
      obs.push(deleteLogoObs);
    }

    return forkJoin(obs);
  }

  changePassword(changePasswordRequest: ChangePasswordRequest, type: SignInPortal = 'subscriber'): Observable<ChangePasswordRequest> {
    return this.session.sessionContainer.pipe(
      take(1),
      switchMap((s) => this.userApi.changeUserPassword(s.user.email, changePasswordRequest, type))
    );
  }

  getUserImage(): Observable<Image> {
    return this.session.sessionContainer.pipe(
      take(1),
      switchMap(s => {
        if (s.user?.roleId) {
          // admins do not have images
          return of(null);
        } else {
          return this.userApi.getSubscriberImages(s.user.id as string).pipe(map(i => i.find((x) => !!x)));
        }
      }),
    );
  }

  getUserImageSrC(): Observable<string | SafeResourceUrl> {
    return this.session.sessionContainer.pipe(switchMap(s => {
      if (!s || !!s?.user?.roleId) {
        // admins do not have images
        return of(null);
      } else {
        return this.imageApi.getSubscriberImage(s.user.id as string, ImageSize.Original);
      }
    })
    );
  }

  createAdmin(req: CreateAdminRequest): Observable<User> {
    return this.userApi.createAdmin(req).pipe(map(u => {
      const oldValues = this.usersDomainModel.admins$.getValue();
      oldValues.push(u);
      this.usersDomainModel.admins$.next(oldValues);
      return u;
    }));
  }

  getAdmin(userId: number): Observable<any> {
    return this.userApi.getAdmin(userId);
  }

  updateAdmin(req: User): Observable<User> {
    return this.userApi.updateAdmin(req).pipe(map(updatedAdmin => {
      const adminArray = this.usersDomainModel.admins$.getValue();
      const index = adminArray.findIndex(u => u.id === updatedAdmin.id);
      adminArray[index] = updatedAdmin;
      this.usersDomainModel.admins$.next(adminArray);
      return updatedAdmin;
    }));
  }

  respondToNewPasswordChallenge(req: ResetPasswordFormObject, email: string): Observable<HydratedUser> {
    return this.userApi.respondToNewPasswordChallenge(req, email);
  }

  // Subscriber Auth Methods

  public createSubscriber(req: SignUpRequest): Observable<HydratedUser> {
    return this.userApi.createSubscriber(req);
  }

  public subscriberSignIn(req: SignInRequest): Observable<HydratedUser> {
    this.lastSignInRequest = req;
    return this.accountApi.signIn(req, 'subscriber').pipe(tap(user => {
      this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
      this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
      // Set the new user in the session
      this.session.setUser(user, null, true, req.rememberMe);
      return user;
    }));
  }

  openAuthModal() {
    const options = new OpenAuthModalOptions(AuthFlow.SignIn, this.router.url);
    this.sessionService.showAuthModal$.next(options);
  }

  intervalFunction() {
    let failedHeartbeats = 0;
    this.authHeartbeatInterval = setInterval(() => {

      if (failedHeartbeats < 0) {
        failedHeartbeats++;
        return;
      }
      this.accountApi.getSubscriberSession(
        this.sessionContainer$.value?.user.email,
        this.sessionContainer$.value?.user.session.refreshToken
      ).pipe(
        map((session) => {
          if (session?.[0]) {
            failedHeartbeats = 0;
          }
          else {
            failedHeartbeats = 0;
            // Implement silent login
            this.subscriberSignIn(this.lastSignInRequest).subscribe(user => {
            }, error => {
              this.toastr.error(error);
            });
          }
        }),
        catchError((error: CustomError) => {
          if (error.code === 403) {
            failedHeartbeats = 0;
            this.toastr.error('Another session was detected');
            this.intervalSignOutSubscribers();
          }
          else if (error.code === 503) {
            failedHeartbeats = -3;
          }
          // all other errors
          else {
            if (failedHeartbeats >= 3) {
              this.intervalSignOutSubscribers();
            } else {
              failedHeartbeats += 1;
            }
          }
          return of(null);
        })
      ).subscribe();
    }, 120000); // 2 minutes
  }

  public createSubscriberSubscriptions(reqs: SubscriberSubscriptionRequest[], subscriberId: string): Observable<any> {
    if (!reqs || reqs?.length === 0) {
      return of([]);
    }
    return forkJoin(...reqs.map(r => this.userApi.createSubscriberSubscription(r, subscriberId)))
      .pipe(tap(() => this.refreshSubscriberSubscriptions$.next()));
  }

  public createSubscriberPaymentDetails(req: CreateSubscriberPaymentDetails, subscriberId: string): Observable<SubscriberPaymentDetails> {
    return this.userApi.createSubscriberPaymentDetails(req, subscriberId);
  }

  public addCouponsToSubscription(reqs: SubscriberSubscriptionRequest[], subscriberId: string): Observable<any> {
    if (!reqs || reqs?.length === 0) {
      return of([]);
    }
    return forkJoin(...reqs.flatMap(r => r.coupons.map(c => this.userApi.addCouponToSubscription(c, r.subscriptionId, subscriberId))))
      .pipe(tap(() => this.refreshSubscriberSubscriptions$.next()));
  }

  public addCouponToSubscription(coupon: Coupon, subscriptionId: string, subscriberId: string): Observable<SubscriberSubscription> {
    return this.userApi.addCouponToSubscription(new SubscriptionCouponRequest(coupon.id), subscriptionId, subscriberId);
  }

  public deleteSubscriberPaymentDetails(req: HydratedUser, subscriberId: string, paymentDetailsId: string): Observable<any> {
    return this.userApi.deleteSubscriberPaymentDetails(req, subscriberId, paymentDetailsId).pipe(tap(() =>
      this.refreshSubscriberSubscriptions$.next())
    );
  }

  public pauseSubscriberSubscription(req: SubscriberSubscriptionRequest, subscriberId: string, subscriptionId: string): Observable<any> {
    return this.userApi.pauseSubscriberSubscription(req, subscriberId, subscriptionId);
  }

  public resumeSubscriberSubscription(req: SubscriberSubscriptionRequest, subscriberId: string, subscriptionId: string): Observable<any> {
    return this.userApi.resumeSubscriberSubscription(req, subscriberId, subscriptionId);
  }

  public getSubscriberPaymentDetails(): Observable<SubscriberPaymentDetails> {
    return this.sessionContainer$.pipe(
      take(1),
      switchMap(s => {
        if (!s) {
          return of(null);
        }
        return this.userApi.getSubscriberPaymentDetails(String(s?.user?.id)).pipe(map(p => p?.find(x => !!x)));
      }),
    );
  }

  public getSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.userApi.getSubscriptionPlans();
  }

  public getAvailableSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    const allPlans = this.userApi.getSubscriptionPlans();
    const currentSubscriptions = this.getSubscriberSubscriptions();

    return currentSubscriptions.pipe(
      switchMap(subscriptions => {
        return allPlans.pipe(map(plans => {
          return plans.filter(p => !subscriptions?.some(s => s.planId === p.id));
        }));
      })
    );
  }


  private getVIPSubscriptions(): Observable<SubscriberSubscription[]> {
    return this.getSubscriptionPlans().pipe(
      map(subscriptionPlans => subscriptionPlans.map(subscriptionPlan => {
        const vipSubscription = new SubscriberSubscription();
        vipSubscription.plan = subscriptionPlan.name;
        vipSubscription.planId = subscriptionPlan.id;
        vipSubscription.planPriceId = subscriptionPlan.pricingOptions[0].id;
        vipSubscription.planPrice = subscriptionPlan.pricingOptions[0].price;
        vipSubscription.status = SubscriptionStatus.Active;
        vipSubscription.billingPeriod = parseInt(subscriptionPlan.pricingOptions[0].period, 10);
        vipSubscription.billingPeriodUnit = subscriptionPlan.pricingOptions[0].periodUnit;
        return vipSubscription;
      }))
    );
  }

  public getSubscriberSubscriptions(): Observable<SubscriberSubscription[]> {
    return this.sessionContainer$.pipe(
      take(1),
      switchMap(s => {
        if (!s) {
          return of(null);
        }
        if (this.sessionContainer$.getValue().user.vip) {
          return this.getVIPSubscriptions();
        } else {
          return this.userApi.getSubscriberSubscriptions(String(s.user?.id));
        }
      }),
    );
  }

  public getCouponFromCode(code: string): Observable<Coupon> {
    return this.userApi.getCouponFromCode(code).pipe(catchError(err => {
      return of(null);
    }));
  }

  public getSubscriberInvoices(): Observable<SubscriberInvoice[]> {
    return this.sessionContainer$.pipe(
      take(1),
      switchMap(s => {
        if (!s) {
          return of(null);
        }
        return this.userApi.getSubscriberInvoices(String(s.user?.id));
      }),
    );
  }

  public cancelSubscriberSubscription(req: SubscriberSubscriptionRequest): Observable<any> {
    return this.userApi.cancelSubscription(req, String(this.session.getUser().id), req.subscriptionId).pipe(tap(() =>
      this.refreshSubscriberSubscriptions$.next())
    );
  }
}
