import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {EventDetailsViewModel} from '../event-details-view-model';
import {FormInputItem, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {EventFormObject} from '../../../../../models/resources/event-form-object';
import {ToastService} from '../../../../../services/toast-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {BannerAdvertisementFormObject} from '../../../../../models/resources/banner-ad-form-object';
import {
  EditBannerAdvertisementModalComponent
} from '../../../shared/edit-banner-ad-modal/edit-banner-advertisement-modal.component';

@Component({
  selector: 'app-event-banner-ads',
  templateUrl: './event-banner-ads.component.html',
  styleUrls: ['./event-banner-ads.component.scss'],
})
export class EventBannerAdsComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: EventFormObject;
  public updatedFormObject = new EventEmitter<void>();
  private createBannerAdClicked = new EventEmitter();
  private editBannerAdClicked = new EventEmitter<BannerAdvertisementFormObject>();

  constructor(
    public viewModel: EventDetailsViewModel,
    private toastService: ToastService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupBindings() {
    this.viewModel.event$.notNull().subscribe(event => {
      setTimeout(() => {
        this.formObject = EventFormObject.initWithEvent(event);
        this.viewModel.updateFormItemStatesSubject$.next();
      });
    }, error => {
      this.toastService.publishError(error);
    }).addTo(this.subscriptions);

    this.createBannerAdClicked.subscribe(() => {
      this.createNewBannerAd();
    }).addTo(this.subscriptions);

    this.editBannerAdClicked.subscribe((t) => {
      this.editEventBannerAd(t);
    }).addTo(this.subscriptions);
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: EventFormObject) {
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const teamsTitle = new FormInputItem();
    teamsTitle.itemType = FormItemType.Title;
    teamsTitle.label = $localize`Banner Advertisements`;
    teamsTitle.titleButtonText = $localize`Create a Banner Ad`;
    teamsTitle.titleButtonClicked = this.createBannerAdClicked;
    teamsTitle.overrideFullWidth = true;
    items.push(teamsTitle);

    const teams = new FormInputItem();
    teams.inputName = 'bannerAdFormObjects';
    teams.itemType = FormItemType.List;
    teams.bindingProperty = 'bannerAdFormObjects';
    teams.listItemButtonText = $localize`Edit`;
    teams.listItemClicked = this.editBannerAdClicked;
    items.push(teams);

    this.formItems = items;
  }

  cancel() {
  }

  private createNewBannerAd() {
    const modalRef = this.modalService.open(
      EditBannerAdvertisementModalComponent,
      ModalUtils.defaultModalOptions(),
    );
    const instance = (modalRef.componentInstance as EditBannerAdvertisementModalComponent);
    instance.initWithNewFormObject();
    modalRef.result.then((bannerAdFormObject: BannerAdvertisementFormObject) => {
      if (bannerAdFormObject) {
        this.formObject.bannerAdFormObjects.push(bannerAdFormObject);
        this.saveEventBannerAd(bannerAdFormObject);
      }
    }, reason => {});
  }

  private editEventBannerAd(bannerAdFormObject: BannerAdvertisementFormObject) {
    const modalRef = this.modalService.open(
      EditBannerAdvertisementModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const instance = (modalRef.componentInstance as EditBannerAdvertisementModalComponent);
    instance.initWithFormObject(bannerAdFormObject);
    modalRef.result.then((updatedBannerAdFormObject: BannerAdvertisementFormObject) => {
      if (updatedBannerAdFormObject) {
        const index = this.formObject.bannerAdFormObjects.indexOf(bannerAdFormObject);
        this.formObject.bannerAdFormObjects[index] = updatedBannerAdFormObject;
        this.saveEventBannerAd(updatedBannerAdFormObject);
      }
    }, reason => {});
  }

  saveEventBannerAd(bannerAdFormObject: BannerAdvertisementFormObject) {
    this.viewModel.saveEventBannerAdvertisement(bannerAdFormObject).subscribe((bannerAd) => {
      this.toastService.publishSuccessMessage($localize`Event Banner Ad Saved`, null);
      const index = this.formObject.bannerAdFormObjects.indexOf(bannerAdFormObject);
      this.formObject.bannerAdFormObjects[index].bannerAd.id = bannerAd.id;
      this.formObject.bannerAdFormObjects[index].itemCreated = false;
      this.formObject.bannerAdFormObjects[index].itemChanged = false;
    }, error => {
      this.toastService.publishErrorMessage(error, $localize`Event Banner Ad Save Failed`);
    });
  }
}
