import { BaseViewModel } from '../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupDomainModel } from '../../../domainModels/lookup-domain-model';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { indicateOnNext } from '../../../utils/observable.extensions';
import { ModalUtils } from '../../../utils/modal-utils';
import { AddNewSportModalComponent } from './add-new-sport-modal/add-new-sport-modal.component';
import { CreateSportTypeRequest } from '../../../models/lookup/requests/create-sport-type-request';
import { SportType } from '../../../models/lookup/sport-type';
import { ToastService } from '../../../services/toast-service';
import { Selectable } from '../../../models/protocols/selectable';

@Injectable({
  providedIn: 'root',
})
export class SportsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false, true, 200);
  sportTypes$ = this.domainModel.sportTypes.pipe(indicateOnNext(this.loadingOpts, $localize`Loading Sports`));
  pixellotProductionTypes$ = this.domainModel.pixellotProductionTypes
    .pipe(indicateOnNext(this.loadingOpts, $localize`Loading Pixellot Production Types`));

  concurrentUpdateUser$ = new BehaviorSubject<any>(null);
  constructor(
    private modalService: NgbModal,
    private domainModel: LookupDomainModel,
    private toastService: ToastService) {
    super();
  }

  init() {
    super.init();
  }

  addNewSport() {
    const modalRef = this.modalService.open(
      AddNewSportModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    // modalRef.result.then((sport: CreateSportTypeRequest) => {
    //   if (sport) {
    //     this.domainModel.createSportType(sport).subscribe(s => {
    //       this.toastService.publishSuccessMessage(null, $localize`Sport Created`);
    //       this.domainModel.refreshSportTypes();
    //     }, error => {
    //       this.toastService.publishErrorMessage(error, $localize`Creating Sport Failed`);
    //     });
    //   }
    // });

    modalRef.componentInstance.modalFormData.subscribe((sport: CreateSportTypeRequest) => {
      this.setLoadingOpts(modalRef, true);
      if (sport) {
        this.domainModel.createSportType(sport).subscribe(s => {
          this.setLoadingOpts(modalRef, false);
          this.toastService.publishSuccessMessage(null, $localize`Sport Created`);
          this.domainModel.refreshSportTypes();
          modalRef.close();
        }, error => {
          this.setLoadingOpts(modalRef, false);
          this.toastService.publishErrorMessage(error, $localize`Creating Sport Failed`);
        });
      }
    });
  }

  editSport(sport: SportType) {
    const sportTypeId = sport.id;
    const modalRef = this.modalService.open(
      AddNewSportModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    modalRef.componentInstance.sport = sport;
    modalRef.componentInstance.isEditing = true;
    // modalRef.result.then((updatedSport: CreateSportTypeRequest) => {
    //   if (updatedSport) {
    //     this.domainModel.updateSportType(updatedSport, String(sportTypeId)).subscribe(s => {
    //       this.toastService.publishSuccessMessage(null, $localize`Sport Updated`);
    //       this.domainModel.refreshSportTypes();
    //     }, error => {
    //       this.toastService.publishErrorMessage(error.message, $localize`Updating Sport Failed`);
    //     });
    //   }
    // });
    modalRef.componentInstance.modalFormData.subscribe((updatedSport: CreateSportTypeRequest) => {
      this.setLoadingOpts(modalRef, true);
      if (updatedSport) {
        this.domainModel.updateSportType(updatedSport, String(sportTypeId)).subscribe(s => {
          this.setLoadingOpts(modalRef, false);
          this.concurrentUpdateUser$.next(null);
          this.toastService.publishSuccessMessage(null, $localize`Sport Updated`);
          this.domainModel.refreshSportTypes();
          modalRef.close();
        }, error => {
          this.setLoadingOpts(modalRef, false);
          if (error.code === 400) {
            this.concurrentUpdateUser$.next(updatedSport);
          }
          this.toastService.publishErrorMessage(error.message, $localize`Updating Sport Failed`);
        });
      } else {
        this.concurrentUpdateUser$.next(null);
        modalRef.componentInstance.sport = null;
      }
    });
  }

  getPixellotProductionTypeOptions(): Selectable[] {
    const pixellotProductionTypeOptions: Selectable[] = [];
    this.pixellotProductionTypes$.subscribe(productionTypes => {
      for (const item of productionTypes) {
        const selectable = {
          getSelectionTitle: (): string => {
            return item;
          },
          getSelectionUniqueIdentifier: (): string => {
            return item;
          },
          getSelectionValue: (): string => {
            return item;
          }
        };
        pixellotProductionTypeOptions.push(selectable);
      }
    });
    return pixellotProductionTypeOptions;
  }

  getSportType(userId: number): Observable<any> {
    return this.domainModel.getSportType(userId);
  }

  setLoadingOpts(ref, flag) {
    ref.componentInstance.isLoading = flag;
  }
}
