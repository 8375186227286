import {AuthFlow, EditSubscriptionFlow, SubscriberSignUpFlow} from '../models/account/enum/auth-flow.enum';
import { DatatableColumnFilterType } from '../models/shared/stylesheet/datatable-column-filter';
import {UploadStatusType} from '../models/image/dto/video-upload';
import {ProductionType} from '../models/lookup/production-type';

export class TypeUtils {
  // static ExampleTypeEnum: typeof ExampleTypeEnum = ExampleTypeEnum;
  static DatatableColumnFilterType: typeof DatatableColumnFilterType = DatatableColumnFilterType;
  static AuthFlow: typeof AuthFlow = AuthFlow;
  static SubAuthFlow: typeof SubscriberSignUpFlow = SubscriberSignUpFlow;
  static UploadStatusType: typeof UploadStatusType = UploadStatusType;
  static ProductionType: typeof ProductionType = ProductionType;
}
