import {BaseModel} from '../base/base-model';

export class Advertisement extends BaseModel {
  id: string;
  name: string = '';
  active: boolean = true;
  advertisingPartnerId: number = null;
  advertisingPartner: string = null;
  advertisementPriorityId: number = null;
  advertisementPriority: string = null;
  amazonPlaybackStreamUrl: string = null;
}

