import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {catchError, map, shareReplay, switchMap, switchMapTo} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, combineLatest, Observable, of, Subject, throwError} from 'rxjs';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {indicate, indicateOnNext} from '../../../../utils/observable.extensions';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {ImageApi} from '../../../../api/image-api';
import {LeagueFormObject} from '../../../../models/resources/league-form-object';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ImageSize} from '../../../../models/enum/dto/image-size.enum';
import {HydratedProgram} from '../../../../models/program/hydrated-program';
import {AdminProgramDomainModel} from '../../../../domainModels/admin-program-domain-model';
import {HydratedShow} from '../../../../models/program/hydrated-show';
import {TeamFormObject} from '../../../../models/resources/team-form-object';
import {Team} from '../../../../models/resources/team';
import {VenueId} from '../../../../models/resources/venue-id';
import {TeamId} from '../../../../models/resources/teamId';
import {Venue} from '../../../../models/resources/venue';
import {BannerAdvertisementFormObject} from '../../../../models/resources/banner-ad-form-object';
import {BannerAdvertisement} from '../../../../models/resources/banner-advertisement';
import {Season} from '../../../../models/resources/season';
import {SeasonFormObject} from '../../../../models/resources/season-form-object';
import {SeasonId} from '../../../../models/resources/seasonId';
import * as moment from 'moment-timezone';

@Injectable()
export class LeagueDetailsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  programLoadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  leagueId$ = this.activatedRoute.params.pipe(map(p => p.leagueId));
  addNewLeague$ = this.activatedRoute.data.pipe(map(d => d.addLeague as boolean));
  refreshLeagueSubject$ = new BehaviorSubject<void>(null);
  league$ = combineLatest([this.leagueId$, this.addNewLeague$, this.refreshLeagueSubject$]).pipe(
    switchMap(([t, addNew, ]) => {
      if (!!addNew) {
        return of(new HydratedLeague());
      } else {
        return this.domainModel.getHydratedLeague(t).pipe(indicate(this.loadingOpts, $localize`Loading League Details`));
      }
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  subscriptionPlans$ = this.accountDomainModel.getSubscriptionPlans().pipe(
    indicateOnNext(this.loadingOpts, $localize`Loading Subscription Plans`),
    shareReplay({bufferSize: 1, refCount: true})
  );

  refreshLeagueProgramsAndShows$ = new BehaviorSubject<void>(null);

  leaguePrograms$: Observable<HydratedProgram[]> = this.refreshLeagueProgramsAndShows$.pipe(
    switchMapTo(this.leagueId$),
    switchMap(leagueId => this.adminProgramDomainModel.getLeagueHydratedPrograms(leagueId)
      .pipe(indicate(this.programLoadingOpts, $localize`Loading Programs`))),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Programs Failed`)),
  );

  leagueShows$: Observable<HydratedShow[]> = this.refreshLeagueProgramsAndShows$.pipe(
    switchMapTo(this.leagueId$),
    switchMap(leagueId => this.adminProgramDomainModel.getLeagueHydratedShows(leagueId)
      .pipe(indicate(this.programLoadingOpts, $localize`Loading Shows`))),
    catchError(e => this.toastService.publishAndThrowError(e, $localize`Load Shows Failed`)),
  );

  updateFormItemStatesSubject$ = new Subject<void>();
  selectedFeedbackProgram: HydratedProgram;
  selectedFeedbackShow: HydratedShow;
  concurrentUpdate = new BehaviorSubject<LeagueFormObject>(null);

  constructor(private domainModel: ResourceDomainModel,
              private lookupDomainModel: LookupDomainModel,
              private adminProgramDomainModel: AdminProgramDomainModel,
              private accountDomainModel: AccountDomainModel,
              private toastService: ToastService,
              private imageApi: ImageApi,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  saveLeague(formObject: LeagueFormObject) {
    this.domainModel.saveLeague(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving League`))
      .subscribe(() => {
        if (!!formObject.league.id) {
          this.toastService.publishSuccessMessage($localize`League Updated`, null);
          this.refreshLeagueSubject$.next();
          this.concurrentUpdate.next(null);
        } else {
          this.toastService.publishSuccessMessage($localize`League Created`, null);
          this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
        }
      }, error => {
        if (error.code === 400) {
          this.concurrentUpdate.next(formObject);
        }
        this.toastService.publishError(error);
      });
  }

  getLogo(leagueFormObject: LeagueFormObject): Observable<string | SafeResourceUrl> {
    if (leagueFormObject.imageToUpload) {
      return of(leagueFormObject.imageToUpload);
    } else if (leagueFormObject.existingImageId) {
      return this.imageApi.getHydratedLeagueImage(leagueFormObject.league, ImageSize.Original, true);
    } else {
      return of(null);
    }
  }

  getLeagueOnConcurrent(leagueId: number){
    return this.domainModel.getLeagueOnConcurrent(leagueId);
  }

  saveLeagueTeam(team: TeamFormObject): Observable<Team> {
    return this.league$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveLeagueTeam(team, l.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving League Team`));
  }

  saveLeagueTeamAssociation(team: Team): Observable<TeamId> {
    return this.league$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveLeagueTeamAssociation(team, l.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving League Team`));
  }

  saveLeagueVenueAssociation(venue: Venue): Observable<VenueId> {
    return this.league$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveLeagueVenueAssociation(venue, l.id)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Venue Stream`));
  }

  saveLeagueBannerAdvertisement(bannerAdFormObject: BannerAdvertisementFormObject): Observable<BannerAdvertisement> {
    return this.league$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveLeagueBannerAdvertisement(l.id, bannerAdFormObject)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving League Banner Advertisement`));
  }

  saveLeagueSeason(formObject: SeasonFormObject): Observable<Season> {
    if (moment(formObject.seasonStartDate).year() < moment().year()) {
      return throwError('The season start date cannot be in the past.');
    }
    if (!/^[a-zA-Z0-9\s]+$/.test(formObject.seasonName)) {
      return throwError('The season name cannot contain special characters.');
    }
    if (formObject.seasonName.length > 128) {
      return throwError('The season name cannot be longer than 128 characters.');
    }
    if (formObject.seasonStartDate && formObject.seasonEndDate) {
      if (moment(formObject.seasonEndDate).toDate() < moment(formObject.seasonStartDate).toDate()) {
        return throwError('The season end date cannot be before the season start date.');
      }
    }
    return this.domainModel.saveLeagueSeason(formObject)
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Season`));
  }

  deleteLeagueSeason(formObject: SeasonFormObject, leagueId: number): Observable<any> {
    return this.domainModel.deleteLeagueSeason(formObject, leagueId);
  }

  getSeason(leagueId: number, seasonId: number): Observable<any> {
    return this.domainModel.getSeason(leagueId, seasonId);
  }
}
