import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {UsersDomainModel} from '../../../../domainModels/users-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {User} from '../../../../models/account/dto/user';
import {CreateAdminRequest} from '../../../../models/account/requests/create-admin-request';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false, true, 400);
  admins$ = this.domainModel.admins$.notNull().pipe(indicateOnNext(this.loadingOpts, $localize`Loading Admins`));
  concurrentUpdateUser = new BehaviorSubject<any>(null);

  constructor(private domainModel: UsersDomainModel,
              private accountDomainModel: AccountDomainModel,
              private toastService: ToastService) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
    this.domainModel.getAdmins();
  }

  setupBindings() {
  }

  updateAdmin(req: User, ref) {
    this.setLoadingOpts(ref, true);
    this.accountDomainModel.updateAdmin(req).subscribe(() => {
      this.concurrentUpdateUser.next(null);
      this.setLoadingOpts(ref, false);
      ref.close();
      this.toastService.publishSuccessMessage($localize`User Updated Successfully`, null);
    }, error => {
      this.setLoadingOpts(ref, false);
      if (error.code === 400) {
        this.concurrentUpdateUser.next(req);
      }
      this.toastService.publishError(error);
    });
  }

  createAdmin(req: CreateAdminRequest, ref) {
    this.setLoadingOpts(ref, true);
    this.accountDomainModel.createAdmin(req).subscribe(u => {
      this.setLoadingOpts(ref, false);
      ref.close();
      this.toastService.publishSuccessMessage($localize`An email has been sent to ${req.email}`, $localize`Admin Account Created`);
    }, error => {
      this.setLoadingOpts(ref, false);
      this.toastService.publishError(error);
    });
  }

  getAdmin(userId: number): Observable<any> {
    return this.accountDomainModel.getAdmin(userId);
  }

  setLoadingOpts(ref, flag) {
    ref.componentInstance.isLoading = flag;
  }
}
