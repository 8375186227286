import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {LeagueDetailsViewModel} from '../league-details-view-model';
import {FormInputItem, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {LeagueFormObject} from '../../../../../models/resources/league-form-object';
import {TeamFormObject} from '../../../../../models/resources/team-form-object';
import {ToastService} from '../../../../../services/toast-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {EditTeamModalComponent} from '../../../shared/edit-team-modal/edit-team-modal.component';
import {AddExistingTeamModalComponent} from '../../../shared/add-existing-team-modal/add-existing-team-modal.component';
import {HydratedTeam} from '../../../../../models/resources/hydrated-team';

@Component({
  selector: 'app-league-teams',
  templateUrl: './league-teams.component.html',
  styleUrls: ['./league-teams.component.scss'],
})
export class LeagueTeamsComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: LeagueFormObject;
  public updatedFormObject = new EventEmitter<void>();
  private addTeamClicked = new EventEmitter();
  private editTeamClicked = new EventEmitter<TeamFormObject>();

  constructor(
    public viewModel: LeagueDetailsViewModel,
    private toastService: ToastService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupBindings() {
    this.viewModel.league$.notNull().subscribe(league => {
      setTimeout(() => {
        this.formObject = LeagueFormObject.initWithLeague(league);
        this.viewModel.updateFormItemStatesSubject$.next();
      });
    }, error => {
      this.toastService.publishError(error);
    }).addTo(this.subscriptions);

    this.addTeamClicked.subscribe(() => {
      this.addNewTeam();
    }).addTo(this.subscriptions);

    this.editTeamClicked.subscribe((t) => {
      this.editTeam(t);
    }).addTo(this.subscriptions);
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: LeagueFormObject) {
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const teamsTitle = new FormInputItem();
    teamsTitle.itemType = FormItemType.Title;
    teamsTitle.label = $localize`Teams`;
    teamsTitle.titleButtonText = $localize`Add a Team`;
    teamsTitle.titleButtonClicked = this.addTeamClicked;
    teamsTitle.overrideFullWidth = true;
    items.push(teamsTitle);

    const teams = new FormInputItem();
    teams.inputName = 'teamFormObjects';
    teams.itemType = FormItemType.List;
    teams.bindingProperty = 'teamFormObjects';
    teams.listItemButtonText = $localize`Edit`;
    teams.listItemClicked = this.editTeamClicked;
    items.push(teams);

    this.formItems = items;
  }

  cancel() {
  }

  private createNewTeam() {
    const modalRef = this.modalService.open(
      EditTeamModalComponent,
      ModalUtils.defaultModalOptions(),
    );
    const instance = (modalRef.componentInstance as EditTeamModalComponent);
    instance.initWithNewFormObject();
    // modalRef.result.then((teamFormObject: TeamFormObject) => {
    //   if (teamFormObject) {
    //     this.formObject.teamFormObjects.push(teamFormObject);
    //     this.saveLeagueTeam(teamFormObject);
    //   }
    // }, reason => {});
    modalRef.componentInstance.returnResult.subscribe((updatedTeamFormObject: TeamFormObject) => {
      if (updatedTeamFormObject) {
        this.formObject.teamFormObjects.push(updatedTeamFormObject);
        this.saveLeagueTeam(updatedTeamFormObject,modalRef);
      }
    }, reason => {});
  }

  private addNewTeam() {
    const modalRef = this.modalService.open(
      AddExistingTeamModalComponent,
      ModalUtils.addTeamModalOptions(),
    );
    modalRef.result.then((existingTeam: HydratedTeam) => {
      if (existingTeam) {
        const tfo = TeamFormObject.initWithTeam(existingTeam);
        this.formObject.teamFormObjects.push(tfo);
        tfo.team.itemCreated = true;
        this.saveLeagueTeamAssociation(tfo,null);
      } else {
        this.createNewTeam();
      }
    }, reason => {});
  }

  private editTeam(teamFormObject: TeamFormObject) {
    const modalRef = this.modalService.open(
      EditTeamModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const instance = (modalRef.componentInstance as EditTeamModalComponent);
    instance.currentTeam = teamFormObject.team;
    instance.initWithFormObject(teamFormObject);
    // modalRef.result.then((updatedTeamFormObject: TeamFormObject) => {
    //   if (updatedTeamFormObject) {
    //     const index = this.formObject.teamFormObjects.indexOf(teamFormObject);
    //     this.formObject.teamFormObjects[index] = updatedTeamFormObject;
    //     if (updatedTeamFormObject.team.itemDeleted) {
    //       this.saveLeagueTeamAssociation(updatedTeamFormObject);
    //       this.formObject.teamFormObjects.splice(index, 1);
    //     } else {
    //       this.saveLeagueTeam(updatedTeamFormObject,modalRef);
    //     }
    //   }
    // }, reason => {});
    modalRef.componentInstance.returnResult.subscribe((updatedTeamFormObject: TeamFormObject) => {
        if (updatedTeamFormObject) {
          const index = this.formObject.teamFormObjects.indexOf(teamFormObject);
          this.formObject.teamFormObjects[index] = updatedTeamFormObject;
          if (updatedTeamFormObject.team.itemDeleted) {
            this.saveLeagueTeamAssociation(updatedTeamFormObject,index);
          } else {
            this.saveLeagueTeam(updatedTeamFormObject,modalRef);
          }
        }
    }, reason => {});
  }

  saveLeagueTeam(teamFormObject: TeamFormObject,ref) {
    this.viewModel.saveLeagueTeam(teamFormObject).subscribe((updatedTeam) => {
      ref.componentInstance.concurrentUpdateTeam.next(null);
      this.toastService.publishSuccessMessage($localize`League Team Saved`, null);
      this.viewModel.refreshLeagueSubject$.next();
      this.modalService.dismissAll();
    }, error => {
      //for concurrent error
      if(error.code === 400){
        ref.componentInstance.concurrentUpdateTeam.next(teamFormObject);
        this.setLoadingOpts(ref, false);
      }
      //for another error-close model
      else{
        this.modalService.dismissAll();
      }
      this.toastService.publishErrorMessage(error.message, $localize`League Team Save Failed`);
    });
  }

  setLoadingOpts(ref, flag) {
    ref.componentInstance.isLoading = flag;
  }

  saveLeagueTeamAssociation(teamFormObject: TeamFormObject,index: number) {
    this.viewModel.saveLeagueTeamAssociation(teamFormObject.team).subscribe((updatedTeamId) => {
      this.toastService.publishSuccessMessage($localize`League Team Saved`, null);
      if(index){
        this.formObject.teamFormObjects.splice(index, 1);
      }
      this.viewModel.refreshLeagueSubject$.next();
      this.modalService.dismissAll();
    }, error => {
      this.toastService.publishErrorMessage(error.message, $localize`League Team Save Failed`);
    });
  }
}
