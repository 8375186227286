import {Component} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageApi} from '../../../../api/image-api';
import {AddEventViewModel} from './add-event-view-model';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import AddressUtils from '../../../../utils/address-utils';
import {HydratedEvent} from '../../../../models/resources/hydrated-event';

@Component({
  selector: 'app-add-event-modal',
  templateUrl: './add-event-modal.component.html',
  styleUrls: ['./add-event-modal.component.scss'],
  providers: [AddEventViewModel]
})
export class AddEventModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject = {event: null};

  constructor(
    public viewModel: AddEventViewModel,
    public modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private imageApi: ImageApi,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.viewModel.events$.notNull().subscribe(v => {
      setTimeout(() => {
        this.updateEventSearchValues(v);
      });
    });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const eventSearch = new FormInputItem();
    eventSearch.itemType = FormItemType.Input;
    eventSearch.inputType = FormInputType.Search;
    eventSearch.inputName = 'eventSearch';
    eventSearch.label = 'Search For Event';
    eventSearch.placeholder = 'Search';
    eventSearch.bindingProperty = 'event';
    eventSearch.required = true;
    eventSearch.inlineLabel = true;
    eventSearch.customError = new Map<string, string>([
      ['searchFor', 'Event Not Found'],
    ]);
    eventSearch.searchable = [];
    items.push(eventSearch);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Add`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    this.activeModal.close(this.formObject.event);
  }

  private updateEventSearchValues(events: HydratedEvent[]) {
    const eventSearchFormItem = this.formItems.find(f => f.inputName === 'eventSearch');
    eventSearchFormItem.searchable.splice(0);
    eventSearchFormItem.searchable.push(...events.map(l => {
      return {
        title: l.name,
        subtitle: l.abbreviation,
        lookupKey: l.name,
        value: l,
      };
    }));
  }
}
