import {CustomFile} from '../shared/custom-file';
import {VideoUpload} from '../image/dto/video-upload';
import {HydratedAdvertisement} from './hydrated-advertisement';

export class AdvertisementFormObject {
  public advertisement: HydratedAdvertisement;

  videoToUpload: CustomFile;
  existingVideo: VideoUpload;
  deleteVideoId: string;

  static initWith(ad: HydratedAdvertisement, uploads: any[] | VideoUpload[]): AdvertisementFormObject {
    const formObject = new AdvertisementFormObject();
    formObject.existingVideo = uploads?.find(u => !!u);
    formObject.advertisement = ad;
    return formObject;
  }
}
