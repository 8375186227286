import {HydratedEvent} from './hydrated-event';
import {SafeResourceUrl} from '@angular/platform-browser';
import {TeamFormObject} from './team-form-object';
import {BannerAdvertisementFormObject} from './banner-ad-form-object';

export class EventFormObject {
  public event: HydratedEvent;
  public imageToUpload: string | SafeResourceUrl;
  public existingImageId: string;
  public deleteImageId: string;
  public teamFormObjects: TeamFormObject[];
  public bannerAdFormObjects: BannerAdvertisementFormObject[];

  static initWithEvent(event: HydratedEvent): EventFormObject {
    const formObject = new EventFormObject();
    formObject.event = event;
    if (event.eventLogos?.length > 0) {
      formObject.existingImageId = event.eventLogos[0]?.id;
    }
    formObject.teamFormObjects = event.associatedTeams
      ?.map(t => TeamFormObject.initWithTeam(t)) ?? [];
    formObject.bannerAdFormObjects = event.advertisementBanners
      ?.map(b => BannerAdvertisementFormObject.initWithBannerAdvertisement(b)) ?? [];
    return formObject;
  }
}
