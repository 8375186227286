import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormInputItem, FormInputType, FormItemType } from '../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../models/shared/stylesheet/form-group-styling';
import { FormOptions } from '../../../../models/shared/stylesheet/form-options';
import { BaseModal } from '../../../../models/base/base-modal';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HydratedTeam } from '../../../../models/resources/hydrated-team';
import { TeamFormObject } from '../../../../models/resources/team-form-object';
import { catchError, first, take } from 'rxjs/operators';
import { UploadImageModalComponent } from '../../../shared/components/upload-image-modal/upload-image-modal.component';
import { ModalUtils } from '../../../../utils/modal-utils';
import { Observable, of } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ImageSize } from '../../../../models/enum/dto/image-size.enum';
import { ImageApi } from '../../../../api/image-api';
import { CustomFile } from '../../../../models/shared/custom-file';
import { CardImageFormObject } from '../../../../models/resources/card-image-form-object';
import { FormGroupComponent } from '../../../shared/components/form-group/form-group.component';
import { VenueDetailsViewModel } from '../../venues/venue-details/venue-details-view-model';
import ConflictRecordUtils from 'src/app/utils/conflict-record-utils';
import { DeserializeHelper } from 'src/app/models/protocols/deserializable';

@Component({
  selector: 'app-edit-team-modal',
  templateUrl: './edit-team-modal.component.html',
  styleUrls: ['./edit-team-modal.component.scss'],
  providers: [VenueDetailsViewModel]
})
export class EditTeamModalComponent extends BaseModal {

  @ViewChild('teamFormGroup') teamFormGroup: FormGroupComponent;
  public readonly bannerImageInputId = 1;
  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: TeamFormObject;
  currentTeam: HydratedTeam;
  isEditing: boolean = false;
  canRemoveTeamOnEdit: boolean = true;

  @Output() returnResult: EventEmitter<any> = new EventEmitter();
  @Input() concurrentUpdateTeam: EventEmitter<any> = new EventEmitter();
  public concurrentUpdateFlag: boolean = false;
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];
  isLoading: boolean = false;
  exceptFieldsArr = [
    'timeStampVal',
    'updatedDate',
    'createdDate',
    '_isScalar',
    '_subscribe',
    'project',
    'predicate',
    'presignedUrl',
    'active'
  ];

  constructor(
    private activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private imageApi: ImageApi, public viewModel: VenueDetailsViewModel,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.concurrentUpdateTeam.subscribe(conflictObject => {
      // If concurrent issue occurs
      if (conflictObject) {
        this.viewModel.getTeamById(conflictObject.team.id).subscribe(refreshTeam => {
          this.concurrentUpdateFlag = true;
          this.compareObjects(this.currentTeam, conflictObject.team, refreshTeam);
          this.setVenueObjAfterConflict(conflictObject, refreshTeam);
          this.formObject.team = DeserializeHelper.deserializeToInstance(HydratedTeam, refreshTeam);
          this.formObject.itemChanged = true;
          this.formObject.itemCreated = false;
          this.setupFormItems();
        });
      } else {
        this.concurrentUpdateFlag = false;
        this.setupFormItems();
      }
    });
  }

  initWithNewFormObject() {
    this.formObject = TeamFormObject.initWithTeam(new HydratedTeam());
    this.formObject.itemCreated = true;
  }

  initWithFormObject(teamFormObject: TeamFormObject) {
    this.isEditing = true;
    this.formObject = teamFormObject.getCopy();
    this.formObject.itemChanged = true;
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormItems();
    this.setupFormStyling();
  }

  setupFormItems() {
    const userChanges = {
      currentUserChanges: this.currentUserChanges,
      anotherUserChanges: this.anotherUserChanges
    };
    const items: FormInputItem[] = [];

    const name = new FormInputItem();
    name.inputName = 'name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Team Name`;
    name.bindingProperty = 'team.name';
    name.required = true;
    // const nameData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('name', 'name') : '';
    const nameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentTeam, 'name', 'name') : '';
    name.conflictType = nameData?.borderColor || nameData;
    name.tooltipText = nameData?.tooltipText;
    items.push(name);

    const location = new FormInputItem();
    location.inputName = 'location';
    location.inputType = FormInputType.Text;
    location.label = $localize`Location`;
    location.placeholder = $localize`Team Location`;
    location.bindingProperty = 'team.location';
    location.required = false;
    // const locationData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('location', 'location') : '';
    const locationData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentTeam, 'location', 'location') : '';
    location.conflictType = locationData?.borderColor || locationData;
    location.tooltipText = locationData?.tooltipText;
    items.push(location);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'team.active';
    active.customClass = 'mb-4 mt-0';
    active.overrideFullWidth = true;
    // const statusData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('Status', 'active') : '';
    const statusData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentTeam, 'Status', 'active') : '';
    active.conflictType = statusData?.borderColor || statusData;
    active.tooltipText = statusData?.tooltipText;
    items.push(active);

    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    // this.activeModal.close(this.formObject);
    this.returnResult.emit(this.formObject);
  }

  getModalTitle(): string {
    return this.isEditing ? $localize`Edit Team` : $localize`Add New Team`;
  }

  deleteClicked() {
    this.formObject.team.itemDeleted = true;
    // this.activeModal.close(this.formObject);
    this.returnResult.emit(this.formObject);
  }

  showEditPhotoModal() {
    this.getLogo(this.formObject).pipe(take(1)).subscribe((logo) => {
      const modalRef = this.modalService.open(UploadImageModalComponent, ModalUtils.defaultMedium());
      const compInstance = modalRef.componentInstance as UploadImageModalComponent;
      compInstance.initWith(
        $localize`Crop Team Logo`,
        $localize`Crop Team Logo`,
        logo
      );
      modalRef.result.then((result) => {
        if (result) {
          this.formObject.imageToUpload = result;
        } else {
          this.removeLogo();
        }
      }, () => {
      });
    }).addTo(this.subscriptions);
  }

  removeLogo() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  getLogo(teamFormObject: TeamFormObject): Observable<string | SafeResourceUrl> {
    if (teamFormObject.imageToUpload) {
      return of(teamFormObject.imageToUpload);
    } else if (teamFormObject.existingImageId) {
      return this.imageApi.getHydratedTeamImage(teamFormObject.team, ImageSize.Original, true).pipe(catchError(err => {
        return of(null);
      }));
    } else {
      return of(null);
    }
  }

  removeBannerImageClicked() {
    if (this.formObject.existingBannerImageId) {
      this.formObject.deleteBannerImageId = this.formObject.existingBannerImageId;
      this.formObject.existingBannerImageId = null;
    }
    this.formObject.bannerImageToUpload = null;
  }

  fileList(f: CustomFile[], id: number) {
    if (id === this.bannerImageInputId) {
      this.handleBannerImageFiles(f);
    } else {
      this.handleCardImageFiles(f);
    }
  }

  handleBannerImageFiles(files: CustomFile[]) {
    if (files.length > 0) {
      this.formObject.bannerImageToUpload = files[0].url;
      this.formObject.bannerImageToUploadFormat = files[0].type;
    } else {
      this.formObject.bannerImageToUpload = undefined;
      this.formObject.bannerImageToUploadFormat = undefined;
    }
  }

  handleCardImageFiles(files: CustomFile[]) {
    files.forEach(f => {
      const cardImageFormObject = new CardImageFormObject();
      cardImageFormObject.imageToUpload = f.url;
      cardImageFormObject.imageToUploadFormat = f.type;
      this.formObject.cardImageFormObjects.push(cardImageFormObject);
    });
  }

  removeCardImgClicked(cardImageFormObject: CardImageFormObject) {
    if (cardImageFormObject.existingImageId) {
      cardImageFormObject.deleteImageId = cardImageFormObject.existingImageId;
      cardImageFormObject.existingImageId = null;
    }
    cardImageFormObject.imageToUpload = null;
    cardImageFormObject.imageToUploadFormat = null;
  }

  //reason- concurrent issue
  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }

  // assignBorderColor(property): string {
  //   if (this.currentUserChanges.includes(property) && this.anotherUserChanges.includes(property)) {
  //     return 'red';
  //   } else if (this.anotherUserChanges.includes(property)) {
  //     return 'orange';
  //   } else if (this.currentUserChanges.includes(property)) {
  //     return 'blue';
  //   } else {
  //     return 'none';
  //   }
  // }


  // assignConflictIssueProperty(label, property, subProperty = null) {
  //   let tooltipText: any = '';
  //   const borderColor: string = this.assignBorderColor(property);

  //   if (borderColor === 'blue') {
  //     tooltipText = 'The ' + label + ' has not changed';
  //   } else if (this.currentUserChanges.includes(property) || this.anotherUserChanges.includes(property)) {
  //     const text = subProperty ? this.venueTeam[subProperty][property] : this.venueTeam[property];

  //     if (property === 'active') {
  //       tooltipText = 'Previous status: ' + (true ? 'Active' : 'Inactive');
  //     } else {
  //       tooltipText = 'Previous selected ' + label + ': ' + text;
  //     }
  //   }
  //   return { borderColor, tooltipText };
  // }

  // getObjProp(obj, propPath) {
  //   const [head, ...rest] = propPath.split('.');

  //   return !rest.length
  //     ? obj[head]
  //     : this.getObjProp(obj[head], rest.join('.'));
  // }

  setVenueObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      // const val = this.getObjProp(conflictObj, element);
      const val = ConflictRecordUtils.getObjProp(conflictObj, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }

  compareObjects(currentObj, conflictObj, refreshObj) {
    // const result1 = this.diff(currentObj, conflictObj, 'red');
    // const result2 = this.diff(currentObj, refreshObj, 'orange');
    const currentConflictObj = ConflictRecordUtils.diff(currentObj, conflictObj, 'red');
    const currentRefreshObj = ConflictRecordUtils.diff(currentObj, refreshObj, 'orange');
    this.currentUserChanges = currentRefreshObj.currentUserChanges;
    this.currentUserChangesWithNestedProp = currentRefreshObj.currentUserChangesWithNestedProp;
    this.anotherUserChanges = currentRefreshObj.anotherUserChanges;
  }

  // get(obj, path) {
  //   return path.split('.').reduce((r, e) => {
  //     if (!r) { return r; }
  //     else { return r[e] || undefined; }
  //   }, obj);
  // }

  // diff(a, b, arr, prev = '') {
  //   return Object.keys(a).reduce((r, e) => {
  //     let value: any = '';
  //     const path = prev + (prev ? '.' + e : e);
  //     if (typeof a[e] == 'boolean') {
  //       value = a[e]?.toString() === b[e]?.toString();
  //     } else {
  //       value = a[e] === this.get(b, path);
  //     }
  //     r[e] = typeof a[e] === 'object' ? this.diff(a[e], b, arr, path) : value;
  //     if (!r[e]) {
  //       if (path === 'active' || !this.exceptFieldsArr.includes(e)) {
  //         if (arr === 'red') {
  //           this.currentUserChanges.push(e);
  //           this.currentUserChangesWithNestedProp.push(path);
  //         }
  //         if (arr === 'orange') { this.anotherUserChanges.push(e); }
  //       }
  //     }
  //     return r;
  //   }, {});
  // }
  //end reason- concurrent issue
}
