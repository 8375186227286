import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {indicateOnNext} from '../../../utils/observable.extensions';
import {ResourceDomainModel} from '../../../domainModels/resource-domain-model';
import {TeamFormObject} from '../../../models/resources/team-form-object';
import {ToastService} from '../../../services/toast-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Team } from 'src/app/models/resources/team';

@Injectable()
export class AdminTeamsViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  teams$ = this.domainModel.allHydratedTeams$.pipe(indicateOnNext(this.loadingOpts, $localize`Loading Teams`));
  concurrentUpdate = new BehaviorSubject<TeamFormObject>(null);

  constructor(private domainModel: ResourceDomainModel,
              private toastService: ToastService) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  // saveTeam(teamFormObject: TeamFormObject) {
  //   this.domainModel.saveTeam(teamFormObject)
  //     .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Team`))
  //     .subscribe((t) => {
  //       this.concurrentUpdate.next(null);
  //       this.toastService.publishSuccessMessage(null, $localize`Team Saved`);
  //     }, error => {
  //       if (error.code === 400) {
  //         this.concurrentUpdate.next(teamFormObject);
  //       }
  //       this.toastService.publishError(error, $localize`Team Save Error`);
  //     });
  // }

  saveTeam(team: TeamFormObject): Observable<Team> {
    return this.teams$.firstNotNull()
      .pipe(switchMap(l => this.domainModel.saveTeam(team)))
      .pipe(indicateOnNext(this.loadingOpts, $localize`Saving Team`));
  }
}
