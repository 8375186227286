import * as moment from 'moment';
import {formatDate} from '@angular/common';


export class DateUtils {

  // Comparisons

  static unixAfterDaysAgo(ts: number, days: number): boolean {
    return (DateUtils.currentTimestamp() - days * DateUtils.unixOneDay()) < ts;
  }

  static unixAfterHoursAgo(ts: number, hours: number): boolean {
    return (DateUtils.currentTimestamp() - hours * DateUtils.unixOneHour()) < ts;
  }

  static unixAfterMinutesAgo(ts: number, minutes: number): boolean {
    return (DateUtils.currentTimestamp() - minutes * 60) < ts;
  }

  // Formatters

  static formatUnixToDateTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('lll');
  }

  static formatUnixToTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('LT');
  }

  static formatUnixToDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('ll');
  }

  static formatUnixToShorthandDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D');
  }

  static formatUnixForDateInput(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('YYYY-MM-DD');
  }

  // Unix Helpers

  static currentTimestamp(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  static unixOneMonth(): number {
    return 30 * 24 * 60 * 60;
  }

  static unixOneWeek(): number {
    return 7 * 24 * 60 * 60;
  }

  static unixOneDay(): number {
    return 24 * 60 * 60;
  }

  static unixOneHour(): number {
    return 60 * 60;
  }

  static formatDateToReadableString(date: Date): string {
    date = DateUtils.validateDate(date);
    return formatDate(date, 'MMM dd, yyyy', 'en-US');
  }

  static formatDateWithWeekdayToReadableString(date: Date): string {
    date = DateUtils.validateDate(date);
    return formatDate(date, 'EEE, MMM dd, yyyy', 'en-US');
  }

  static formatDateToReadableStringUTC(date: Date): string {
    const utcMoment = moment.utc(date);
    return moment(utcMoment).format('MMM D, yyyy');
  }

  static formatDateTimeToQueryParamString(date: Date): string {
    date = DateUtils.validateDate(date);
    return formatDate(date, 'yyyy-MM-ddTHH:mm:ssZ', 'en-US');
  }

  static formatDateToQueryParamString(date: Date): string {
    date = DateUtils.validateDate(date);
    return formatDate(date, 'MM-dd-yyyy', 'en-US');
  }

  static formatDateTimeToReadableString(date: Date): string {
    const utcMoment = moment.utc(date);
    return moment(utcMoment).local().format('llll');
  }

  static formatTimeToReadableString(date: Date): string {
    const utcMoment = moment.utc(date);
    return moment(utcMoment).local().format('h:mm a');
  }

  static formatTimeTo24HourString(date: Date): string {
    const utcMoment = moment.utc(date);
    return moment(utcMoment).local().format('HH:mm:ss');
  }

  static formatTimeDateInputString(date: Date): string {
    const utcMoment = moment.utc(date);
    return moment(utcMoment).local().format('YYYY-MM-DD');
  }

  static formatDateTimeToLongFormat(date: Date): string {
    const utcMoment = moment.utc(date);
    return moment(utcMoment).local().format('lll');
  }

  static formatDateTimeToMessageString(date: Date): string {
    const utcMoment = moment.utc(date);
    return moment(utcMoment).local().format('ddd, MMM D LT');
  }

  // returns Sunday, February 14th
  static formatDateToDayMonthString(date: Date): string {
    const utcMoment = moment.utc(date);
    return moment(utcMoment).local().format('dddd, MMMM Do');
  }

  // returns Sunday, February 14, 2021
  static formatDateToDayMonthYearString(date: Date): string {
    const utcMoment = moment.utc(date);
    return moment(utcMoment).local().format('dddd, MMMM D, YYYY');
  }

  static dateIsBefore(one, two: Date): number {
    one = DateUtils.validateDate(one);
    two = DateUtils.validateDate(two);
    return moment(one).isBefore(moment(two)) ? -1 : moment(one).isAfter(moment(two)) ? 1 : 0;
  }

  static dateRangeContains(rangeStart: Date, rangeEnd: Date, containsDate: Date): boolean {
    return (rangeStart <= containsDate && containsDate <= rangeEnd);
  }

  static dateRangeOverlaps(aStart: Date, aEnd: Date, bStart: Date, bEnd: Date): boolean {
    if (aStart <= bStart && bStart <= aEnd) {
      return true;
    } // b starts in a
    if (aStart <= bEnd && bEnd <= aEnd) {
      return true;
    } // b ends in a
    if (bStart < aStart && aEnd < bEnd) {
      return true;
    } // a in b
    return false;
  }

  static validateDate(d): Date {
    if (d instanceof Date) {
      return d;
    } else {
      return new Date(d);
    }
  }

  static ngbDateIsBeforeToday(year, month, day: number): boolean {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // ngb
    const currentDay = currentDate.getDate();
    const pastYear = (currentYear > year);
    const pastMonth = (currentYear === year && currentMonth > month);
    const pastDay = (currentYear === year && currentMonth === month && currentDay > day);
    return pastYear || pastMonth || pastDay;
  }

  static endOfDay(date: Date): Date {
    const d = new Date(date);
    d.setHours(23, 59, 59, 999);
    return d;
  }

  static startOfDay(date: Date): Date {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  static endOfDayUTC(date: Date): Date {
    const d = new Date(date);
    d.setUTCHours(23, 59, 59, 999);
    return d;
  }

  static startOfDayUTC(date: Date): Date {
    const d = new Date(date);
    d.setUTCHours(0, 0, 0, 0);
    return d;
  }

  // returns time, ex: 11:32 AM, using system Time Zone,
  static getTime(date: Date): string {
    const dateFromTimeZone = new Date(date.getTime() + date.getTimezoneOffset() * 600 * 1000 * 2);
    return dateFromTimeZone.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
  }

  static convert24HourTo12HourTimeString(timeString: string) {
    if (!timeString) {
      return '';
    }
    const H = +timeString.substr(0, 2);
    const h = H % 12 || 12;
    const ampm = (H < 12 || H === 24) ? $localize`am` : $localize`pm`;
    return h + timeString.substr(2, 3) + ampm;
  }

  static getTimeMinutes(time: string): number {
    return moment.duration(time).minutes();
  }

  static getMinuteIntervals(startTime: Date, endTime: Date, intervalMinutes: number): Date[] {
    const timeStops = [];
    const startMoment = moment(startTime).startOf('minute');
    const startInterval = startMoment.add(intervalMinutes - startMoment.minute() % intervalMinutes, 'minutes');
    while (startInterval.toDate() <= endTime) {
      timeStops.push(moment(startInterval).toDate());
      startInterval.add(intervalMinutes, 'minutes');
    }
    return timeStops;
  }

  static isTimeSameOrBefore(time1, time2) {
    const t1 = new Date();
    let parts = time1.split(':');
    t1.setHours(parts[0], parts[1], parts[2], 0);
    const t2 = new Date();
    parts = time2.split(':');
    t2.setHours(parts[0], parts[1], parts[2], 0);

    return moment(t1).isSameOrBefore(moment(t2));
  }

  static formatMinutesToHoursMinutesString(minutes: number): string {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    const hString = (h > 0) ? $localize`${h}:hours:H` : '';
    const mString = (m > 0) ? $localize`${m}:minutes:m` : '';
    return [hString, mString].join(' ');
  }
}
