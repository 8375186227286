<app-content-container [unsetMinHeight]="true">
  <app-card-row
    #cardRowComponent
    [loadingOpts]="viewModel.loadingOpts"
    [cards]="viewModel.cards$|async"
    (cardClicked)="cardClicked($event)"
    (buttonClicked)="seeAllGamesClicked()"
    (lastLoadedPageReached)="loadMorePrograms()"
    [fetchingMoreData]="viewModel.fetchingMoreData"
    [title]="viewModel.rowTitle$|async"
    [buttonTitle]="viewModel.rowCTA$|async"
    [cardCtaText]="viewModel.cardCTA$|async"
    [cardStyle]="viewModel.cardStyle$|async"
    [breakpoints]="viewModel.cardBreakpoints$|async">
  </app-card-row>
</app-content-container>
