import {Program} from './program';
import {Image} from '../image/dto/image';
import {ProgramComment} from './program-comment';
import {DeserializeHelper} from '../protocols/deserializable';
import {DatatableData} from '../protocols/datatable-data';

export class HydratedProgram extends Program implements DatatableData {
  public images: Image[];
  public comments: ProgramComment[];

  onDeserialize() {
    super.onDeserialize();
    this.images = DeserializeHelper.deserializeArray(Image, this.images);
    this.comments = DeserializeHelper.deserializeArray(ProgramComment, this.comments);
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}

