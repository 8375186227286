import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {SearchDomainModel} from '../../../../domainModels/search-domain-model';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {debounceTime, filter, map} from 'rxjs/operators';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {SearchListable} from '../../../../models/protocols/search-listable';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import Fuse from 'fuse.js';
import {SearchListItemContainer} from './search-list-item-container';

@Injectable()
export class SearchModalViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  searchListItems$: Observable<Fuse<SearchListItemContainer>> = combineLatest([
    this.resourceDomainModel.activeHydratedTeams$,
    this.resourceDomainModel.activeHydratedLeagues$,
    this.resourceDomainModel.activeHydratedEvents$,
    this.resourceDomainModel.activeHydratedVenues$,
  ]).pipe(
    filter(results => results.every(r => !!r)),
    indicateOnNext(this.loadingOpts, ''),
    map((results) => {
      const allResults = results.flatMap(r => r ?? []);
      return SearchListItemContainer.generateFuse(allResults);
    })
  );

  searchResults$: Observable<SearchListable[]> = combineLatest([
    this.searchText$,
    this.searchListItems$,
    this.resourceDomainModel.activeHydratedLeagues$,
    this.resourceDomainModel.activeHydratedEvents$,
  ]).pipe(
    debounceTime(200),
    map(([searchText, searchFuse, leagues]) => {
      if (!searchText) {
        return leagues; // show leagues by default
      }
      return searchFuse.search(searchText, {limit: 10}).map(r => r.item.searchListableValue);
    })
  );

  constructor(private domainModel: SearchDomainModel, private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}

