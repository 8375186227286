import {Plan} from './plan';
import {DeserializeHelper} from '../protocols/deserializable';
import {PricingOptions} from './pricing-options';

export class PlanFormObject {
  public plan?: Plan;
  public planId: number;
  public planExternalName: string;
  public planInternalName: string;
  public planCategory: number;
  public planPrice?: number;
  public planCurrency?: string;
  public planFrequency?: string;
  public planStatus: boolean;
  public planDescription: string;
  public pricingOptions: PricingOptions[];
  public planPricingStatus: number;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;


  static initWithPlan(plan: Plan): PlanFormObject {
    const formObject = new PlanFormObject();
    formObject.plan = plan;
    formObject.planId = plan?.id;
    formObject.planExternalName = plan?.externalName;
    formObject.planInternalName = plan?.internalName;
    formObject.planCategory = plan?.category;
    formObject.planPrice = plan?.pricingOptions[0]?.price;
    formObject.planCurrency = plan?.pricingOptions[0]?.currencyCode;
    formObject.planFrequency = plan?.pricingOptions[0]?.periodUnit;
    formObject.planPricingStatus = plan?.pricingOptions[0]?.status;
    formObject.planStatus = plan?.status === 1;
    return formObject;
  }

  setPlan(p: Plan) {
    return this.plan = p;
  }

  getCopy(): PlanFormObject {
    const planCopy = DeserializeHelper.deserializeToInstance(Plan, this.plan);
    const formObjectCopy = Object.assign(new PlanFormObject(), this);
    formObjectCopy.setPlan(planCopy);
    return formObjectCopy;
  }
  getPlanCopy(): Plan {
    const planCopy = DeserializeHelper.deserializeToInstance(Plan, this.plan);
    planCopy.internalName = this.planInternalName;
    planCopy.externalName = this.planExternalName;
    planCopy.pricingOptions = this.pricingOptions;
    planCopy.category = this.planCategory;
    planCopy.status = this.planStatus ? 1 : 2;
    planCopy.description = this.planDescription;
    return planCopy;
  }

}
