import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {LeagueFormObject} from '../../../../../models/resources/league-form-object';
import {ToastService} from '../../../../../services/toast-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {SubscriptionPlan} from '../../../../../models/account/dto/subscription-plan';
import {first, take} from 'rxjs/operators';
import {UploadImageModalComponent} from '../../../../shared/components/upload-image-modal/upload-image-modal.component';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {ConfirmationModalComponent} from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import {ConfirmationOptions} from '../../../../../models/shared/stylesheet/confirmation-options';
import {LeagueDetailsViewModel} from '../league-details-view-model';
import {AlphanumericValidatorDirective} from 'src/app/views/shared/components/form-group/validators/alphanumeric-validator.directive';
import ConflictRecordUtils from 'src/app/utils/conflict-record-utils';

@Component({
  selector: 'app-edit-league',
  templateUrl: './edit-league.component.html',
  styleUrls: ['./edit-league.component.scss'],
})
export class EditLeagueComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: LeagueFormObject;
  public updatedFormObject = new EventEmitter<void>();
  public hydrateInputObject = new EventEmitter<void>();
  public concurrentUpdateFlag: boolean = false;
  currentLeague: any = null;
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];

  constructor(
    public viewModel: LeagueDetailsViewModel,
    private toastService: ToastService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupBindings() {
    this.viewModel.league$.notNull().subscribe(league => {
      setTimeout(() => {
        this.formObject = LeagueFormObject.initWithLeague(league);
        this.currentLeague = JSON.parse(JSON.stringify(league));
        this.viewModel.updateFormItemStatesSubject$.next();
      });
    }, error => {
      this.toastService.publishError(error);
    }).addTo(this.subscriptions);

    this.viewModel.concurrentUpdate.subscribe(conflictleague => {
      // If concurrent issue occurs
      if (conflictleague) {
        this.viewModel.getLeagueOnConcurrent(conflictleague.league.id).subscribe(refreshLeague => {
          this.concurrentUpdateFlag = true;
          this.compareObjects(this.currentLeague, conflictleague.league, refreshLeague);
          this.setLeagueObjAfterConflict(conflictleague.league, refreshLeague);
          this.formObject = LeagueFormObject.initWithLeague(refreshLeague);
          this.setupFormItems();
        });
      } else {
        this.concurrentUpdateFlag = false;
        this.setupFormItems();
      }
  });
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.submitButtonText = $localize`Save League`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: LeagueFormObject) {
    this.viewModel.saveLeague(result);
  }

  //concurrent issue-code reason
  compareObjects(currentObj, conflictObj, refreshObj) {
    const currentConflictObj = ConflictRecordUtils.diff(currentObj, conflictObj, 'red');
    const currentRefreshObj = ConflictRecordUtils.diff(currentObj, refreshObj, 'orange');
    this.currentUserChanges = currentRefreshObj.currentUserChanges;
    this.currentUserChangesWithNestedProp = currentRefreshObj.currentUserChangesWithNestedProp;
    this.anotherUserChanges = currentRefreshObj.anotherUserChanges;
  }

  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }

  setLeagueObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      const val = ConflictRecordUtils.getObjProp(conflictObj, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }//concurrent issue-code reason end

  setupFormItems() {
    const userChanges = {
      currentUserChanges: this.currentUserChanges,
      anotherUserChanges: this.anotherUserChanges
    };
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const infoTitle = new FormInputItem();
    infoTitle.itemType = FormItemType.Title;
    infoTitle.label = $localize`Info`;
    items.push(infoTitle);

    const leagueName = new FormInputItem();
    leagueName.inputName = 'leagueName';
    leagueName.inputType = FormInputType.Text;
    leagueName.label = $localize`League Name`;
    leagueName.placeholder = $localize`League Name`;
    leagueName.bindingProperty = 'league.name';
    leagueName.required = true;
    const leagueNameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentLeague,
        'League Name', 'name') : '';
    leagueName.conflictType = leagueNameData?.borderColor || leagueNameData;
    leagueName.tooltipText = leagueNameData?.tooltipText;
    items.push(leagueName);

    const leagueAbbreviation = new FormInputItem();
    leagueAbbreviation.inputName = 'leagueAbbreviation';
    leagueAbbreviation.inputType = FormInputType.Text;
    leagueAbbreviation.label = $localize`League Abbreviation`;
    leagueAbbreviation.placeholder = $localize`League Abbreviation`;
    leagueAbbreviation.bindingProperty = 'league.abbreviation';
    leagueAbbreviation.required = true;
    leagueAbbreviation.customValidator = new AlphanumericValidatorDirective();
    const leagueAbbreviationData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentLeague,
        'League Abbreviation', 'abbreviation') : '';
    leagueAbbreviation.conflictType = leagueAbbreviationData?.borderColor || leagueAbbreviationData;
    leagueAbbreviation.tooltipText = leagueAbbreviationData?.tooltipText;
    items.push(leagueAbbreviation);

    const subscriptionPlan = new FormInputItem();
    subscriptionPlan.itemType = FormItemType.Dropdown;
    subscriptionPlan.inputName = 'subscriptionPlanId';
    subscriptionPlan.label = $localize`Subscription Plan`;
    subscriptionPlan.placeholder = $localize`Choose a Subscription Plan`;
    subscriptionPlan.bindingProperty = 'league.subscriptionPlanId';
    subscriptionPlan.dropdownIsObject = true;
    subscriptionPlan.required = true;
    subscriptionPlan.dropdownOptions = [];
    const subscriptionPlanData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentLeague,
        'Subscription Plan', 'subscriptionPlanId') : '';
    subscriptionPlan.conflictType = subscriptionPlanData?.borderColor || subscriptionPlanData;
    subscriptionPlan.tooltipText = subscriptionPlanData?.tooltipText;
    items.push(subscriptionPlan);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'league.active';
    active.customClass = 'mb-4 mt-0';
    active.valueChanged.subscribe(v => {
      if (!v[0]) {
        this.openDeactivateLeagueModal();
      }
    }).addTo(this.subscriptions);
    const statusData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentLeague, 'Status', 'active') : '';
    active.conflictType = statusData?.borderColor || statusData;
    active.tooltipText = statusData?.tooltipText;
    items.push(active);

    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    items.push(FormInputItem.generateDivider());

    this.formItems = items;
    this.setupFormBindings();
  }

  setupFormBindings() {
    this.viewModel.updateFormItemStatesSubject$.subscribe(() => {
      setTimeout(() => {
        this.updateFormItemStates();
      });
    }).addTo(this.subscriptions);

    this.viewModel.subscriptionPlans$.notNull().subscribe(s => {
      setTimeout(() => {
        this.setSubscriptionPlanOptions(s);
      });
    }).addTo(this.subscriptions);
  }

  cancel() {
    this.router.navigate(['..'], {relativeTo: this.activatedRoute}).then();
  }

  setSubscriptionPlanOptions(subscriptionPlans: SubscriptionPlan[]) {
    setTimeout(() => {
      const subscriptionPlanInput = this.formItems.find(f => f.inputName === 'subscriptionPlanId');
      subscriptionPlanInput.dropdownOptions = subscriptionPlans;
    });
  }

  updateFormItemStates() {
    if (this.formItems.length === 0) {
      return;
    }
  }

  showEditPhotoModal() {
    this.viewModel.getLogo(this.formObject).pipe(take(1)).subscribe((logo) => {
      const modalRef = this.modalService.open(UploadImageModalComponent, ModalUtils.defaultMedium());
      const compInstance = modalRef.componentInstance as UploadImageModalComponent;
      compInstance.initWith(
        $localize`Add League Logo`,
        $localize`Crop League Logo`,
        logo
      );
      modalRef.result.then((result) => {
        if (result) {
          this.formObject.imageToUpload = result;
        } else {
          this.removeLogo();
        }
      }, () => {
      });
    }).addTo(this.subscriptions);
  }

  removeLogo() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  openDeactivateLeagueModal(): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Deactivate League`;
    // eslint-disable-next-line max-len
    opts.bodyText = $localize`Deactivating a league will limit actions that admins can take with that league. Are you sure you want to deactivate this league?\n\nThis action can be reversed from the Leagues tab. `;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Deactivate League`;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((deactivate) => {
      if (!deactivate) {
        const activeInput = this.formItems.find(i => i.inputName === 'active');
        activeInput?.setInputFormControlValue(true);
      }
    });
  }
}
