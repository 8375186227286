export default class ConflictRecordUtils {
  static currentUserChanges: any = [];
  static currentUserChangesWithNestedProp: any = [];
  static anotherUserChanges: any = [];
  static currentVenue: any = null;
  static advertisementList: string[] = ['Teams', 'Venues', 'Leagues', 'Events', 'Dates'];
  static exceptFieldsArr = [
    'timeStampVal',
    'updatedDate',
    'createdDate',
    '_isScalar',
    '_subscribe',
    'project',
    'predicate',
    'presignedUrl',
    'active',
    'updatedById',
    'cameraId'
  ];

  // updateObjProp(obj, value, propPath) {
  //   const [head, ...rest] = propPath.split('.');

  //   if (!rest.length) {
  //     obj[head] = value;
  //   } else {
  //     this.updateObjProp(obj[head], value, rest.join('.'));
  //   }
  // }

  static getObjProp(obj, propPath) {
    const [head, ...rest] = propPath.split('.');

    return !rest.length
      ? obj[head]
      : this.getObjProp(obj[head], rest.join('.'));
  }

  static assignBorderColor(property): string {
    if (this.currentUserChanges.includes(property) && this.anotherUserChanges.includes(property)) {
      return 'red';
    } else if (this.anotherUserChanges.includes(property)) {
      return 'orange';
    } else if (this.currentUserChanges.includes(property)) {
      return 'blue';
    } else {
      return 'none';
    }
  }

  static assignConflictIssueProperty(changes, object, label, property, subProperty = null) {
    let tooltipText: any = '';

    const borderColor: string = ConflictRecordUtils.assignBorderColor(property);

    if (this.advertisementList.includes(label)) {
      tooltipText = (borderColor === 'blue') ? 'The ' + label + ' list has not changed'
        : 'The ' + label + ' list has been updated';
    } else if (borderColor === 'blue') {
      tooltipText = 'The ' + label + ' has not changed';
    } else if (changes.currentUserChanges.includes(property) || changes.anotherUserChanges.includes(property)) {
      if (property === 'active') {
        tooltipText = 'Previous status: ' + (object[property] ? 'Active' : 'Inactive');
      } else {
        property = (property === 'countryId') ? 'country' : (property === 'stateId' ? 'state'
          : (property === 'advertisementPriorityId' ? 'advertisementPriority' : property));
        const text = subProperty ? object[subProperty][property] : object[property];
        tooltipText = 'Previous selected ' + label + ': ' + text;
      }
    }
    return { borderColor, tooltipText };
  }

  static get(obj, path) {
    return path.split('.').reduce((r, e) => {
      if (!r) { return r; }
      else { return r[e] || undefined; }
    }, obj);
  }

  static diff(a, b, arr, prev = '') {
    return Object.keys(a).reduce((r, e) => {
      let value: any = '';
      const path = prev + (prev ? '.' + e : e);
      if (typeof a[e] == 'boolean') {
        value = a[e]?.toString() === b[e]?.toString();
      } else {
        if (Array.isArray(a[e]) && Array.isArray(b[e])) {
          value = a[e].length === b[e].length;
        } else {
          value = a[e] === this.get(b, path);
        }
      }
      // const value = a[e] === this.get(b, path);
      r[e] = (typeof a[e] === 'object' && a[e] !== null && !Array.isArray(a[e])) ? this.diff(a[e], b, arr, path) : value;

      if (!r[e]) {
        if (path === 'active' || !this.exceptFieldsArr.includes(e)) {
          if (arr === 'red') {
            this.currentUserChanges.push(e);
            this.currentUserChangesWithNestedProp.push(path);
          }
          if (arr === 'orange') { this.anotherUserChanges.push(e); }
        }
      }
      return r;
    }, {
      currentUserChanges: this.currentUserChanges,
      currentUserChangesWithNestedProp: this.currentUserChangesWithNestedProp,
      anotherUserChanges: this.anotherUserChanges
    });
  }
}
