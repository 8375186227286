import { Component, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { VenueDetailsViewModel } from '../venue-details-view-model';
import { FormInputItem, FormInputType, FormItemType } from '../../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../../models/shared/stylesheet/form-group-styling';
import { FormOptions } from '../../../../../models/shared/stylesheet/form-options';
import { VenueFormObject } from '../../../../../models/resources/venue-form-object';
import { VenueStream } from '../../../../../models/resources/venue-stream';
import { TeamFormObject } from '../../../../../models/resources/team-form-object';
import { CountryType } from '../../../../../models/lookup/country-type';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { debounceTime, first, take } from 'rxjs/operators';
import { EditVenueStreamModalComponent } from '../../edit-venue-stream-modal/edit-venue-stream-modal.component';
import { ModalUtils } from '../../../../../utils/modal-utils';
import { SubscriptionPlan } from '../../../../../models/account/dto/subscription-plan';
import { EditTeamModalComponent } from '../../../shared/edit-team-modal/edit-team-modal.component';
import { UploadImageModalComponent } from '../../../../shared/components/upload-image-modal/upload-image-modal.component';
import {
  ConfirmationModalComponent
} from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import ConflictRecordUtils from '../../../../../utils/conflict-record-utils';
@Component({
  selector: 'app-edit-venue',
  templateUrl: './edit-venue.component.html',
  styleUrls: ['./edit-venue.component.scss'],
})
export class EditVenueComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: VenueFormObject;
  // public concurrentUpdate: VenueFormObject = null;
  public concurrentUpdateFlag: boolean = false;

  countries: CountryType[] = [];
  currentVenue: any = null;
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];
  exceptFieldsArr = [
    'timeStampVal',
    'updatedDate',
    'createdDate',
    '_isScalar',
    '_subscribe',
    'project',
    'predicate',
    'presignedUrl',
    'active'
  ];

  constructor(
    public viewModel: VenueDetailsViewModel,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupBindings() {
    this.setupFormStyling();

    // default get request for venue
    combineLatest([this.viewModel.venue$, this.viewModel.countryTypes$.notNull()])
      .pipe(debounceTime(100))
      .subscribe(([venue, countryTypes]) => {
        this.currentVenue = JSON.parse(JSON.stringify(venue));
        this.formObject = VenueFormObject.initWithVenue(venue);
        this.countries = countryTypes;
        this.setupFormItems();
      }).addTo(this.subscriptions);

    this.viewModel.concurrentUpdate.subscribe(conflictObject => {
      // If concurrent issue occurs
      if (conflictObject) {
        combineLatest([this.viewModel.refreshHydratedVenue$, this.viewModel.countryTypes$.notNull()])
          .pipe(debounceTime(100))
          .subscribe(([refreshVenue, countryTypes]) => {
            this.concurrentUpdateFlag = true;
            // Compare venue objects to check concurrent issue
            this.compareObjects(this.currentVenue, conflictObject, refreshVenue);

            this.setVenueObjAfterConflict(conflictObject, refreshVenue);
            this.formObject = VenueFormObject.initWithVenue(refreshVenue);
            this.countries = countryTypes;
            this.setupFormItems();
          }).addTo(this.subscriptions);
      } else {
        this.concurrentUpdateFlag = false;
        this.setupFormItems();
      }
    });
    //Code commented to solve get venue after conflicted venue saved
    // this.viewModel.concurrentUpdate.subscribe(conflictObject => {
    //   // If concurrent issue occurs
    //   if (conflictObject) {
    //     combineLatest([this.viewModel.refreshHydratedVenue$, this.viewModel.countryTypes$.notNull()])
    //       .pipe(debounceTime(100))
    //       .subscribe(([refreshVenue, countryTypes]) => {
    //         this.concurrentUpdateFlag = true;
    //         // Compare venue objects to check concurrent issue
    //         this.compareObjects(this.currentVenue, conflictObject, refreshVenue);

    //         this.setVenueObjAfterConflict(conflictObject, refreshVenue);
    //         this.formObject = VenueFormObject.initWithVenue(refreshVenue);
    //         this.countries = countryTypes;
    //         this.setupFormItems();
    //       }).addTo(this.subscriptions);
    //   } else {
    //     // default get request for venue
    //     combineLatest([this.viewModel.venue$, this.viewModel.countryTypes$.notNull()])
    //       .pipe(debounceTime(100))
    //       .subscribe(([venue, countryTypes]) => {
    //         // Assign active venue
    //         this.concurrentUpdateFlag = false;
    //         this.currentVenue = JSON.parse(JSON.stringify(venue));
    //         setTimeout(() => {
    //           this.formObject = VenueFormObject.initWithVenue(venue);
    //         });
    //         this.countries = countryTypes;
    //         this.setupFormItems();
    //       }).addTo(this.subscriptions);
    //   }
    // }, error => {
    //   // this.toastService.publishError(error);
    // });

    // combineLatest([this.viewModel.venue$, this.viewModel.countryTypes$.notNull()])
    //   .pipe(debounceTime(100))
    //   .subscribe(([venue, countryTypes]) => {
    //     this.formObject = VenueFormObject.initWithVenue(venue);
    //     this.countries = countryTypes;
    //     this.setupFormItems();
    //   }).addTo(this.subscriptions);
  }

  setupFormBindings() {
    this.viewModel.subscriptionPlans$.notNull().pipe(debounceTime(100)).subscribe(sp => {
      this.setSubscriptionPlanOptions(sp);
    }).addTo(this.subscriptions);
  }

  setupViews() {
    this.setupFormOptions();
  }

  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }

  // getObjProp(obj, propPath) {
  //   const [head, ...rest] = propPath.split('.');

  //   return !rest.length
  //     ? obj[head]
  //     : this.getObjProp(obj[head], rest.join('.'));
  // }

  setVenueObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      const val = ConflictRecordUtils.getObjProp(conflictObj.venue, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }

  // assignBorderColor(property): string {
  //   if (this.currentUserChanges.includes(property) && this.anotherUserChanges.includes(property)) {
  //     return 'red';
  //   } else if (this.anotherUserChanges.includes(property)) {
  //     return 'orange';
  //   } else if (this.currentUserChanges.includes(property)) {
  //     return 'blue';
  //   } else {
  //     return 'none';
  //   }
  // }

  // assignConflictIssueProperty(label, property, subProperty = null) {
  //   let tooltipText: any = '';

  //   // const borderColor: string = this.currentUserChanges.includes(property) ? 'red' : (
  //   //   this.anotherUserChanges.includes(property) ? 'orange' : 'none'
  //   // );

  //   const borderColor: string = this.assignBorderColor(property);

  //   if (borderColor === 'blue') {
  //     tooltipText = 'The ' + label + ' has not changed';
  //   } else if (this.currentUserChanges.includes(property) || this.anotherUserChanges.includes(property)) {
  //     const text = subProperty ? this.currentVenue[subProperty][property] : this.currentVenue[property];
  //     if (property === 'active') {
  //       tooltipText = 'Previous status: ' + (true ? 'Active' : 'Inactive');
  //     } else {
  //       tooltipText = 'Previous selected ' + label + ': ' + text;
  //     }
  //   }
  //   return { borderColor, tooltipText };
  // }

  setupFormItems() {
    const userChanges = {
      currentUserChanges: this.currentUserChanges,
      anotherUserChanges: this.anotherUserChanges
    };

    const items: FormInputItem[] = [];

    const venueName = new FormInputItem();
    venueName.inputName = 'venueName';
    venueName.inputType = FormInputType.Text;
    venueName.label = $localize`Venue Name`;
    venueName.placeholder = $localize`Enter Venue Name`;
    venueName.bindingProperty = 'venue.name';
    venueName.required = true;
    venueName.overrideFullWidth = true;
    // const nameData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('name', 'name') : '';
    const nameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentVenue, 'name', 'name') : '';
    venueName.conflictType = nameData?.borderColor || nameData;
    venueName.tooltipText = nameData?.tooltipText;
    items.push(venueName);

    const address1 = new FormInputItem();
    address1.inputName = 'addressLine1';
    address1.inputType = FormInputType.Text;
    address1.label = $localize`Address Line 1`;
    address1.placeholder = $localize`Address`;
    address1.bindingProperty = 'venue.address.addressLine1';
    address1.required = true;
    // address1.conflictType = this.concurrentUpdateFlag ? this.assignConflictPriority('addressLine1') : "";
    // const address1Data: any = this.concurrentUpdateFlag ?
    //   this.assignConflictIssueProperty('Address Line 1', 'addressLine1', 'address') : '';
    const address1Data: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentVenue, 'Address Line 1', 'addressLine1', 'address') : '';
    address1.conflictType = address1Data?.borderColor || address1Data;
    address1.tooltipText = address1Data?.tooltipText;
    items.push(address1);

    const address2 = new FormInputItem();
    address2.inputName = 'address2';
    address2.inputType = FormInputType.Text;
    address2.label = $localize`Address Line 2`;
    address2.placeholder = $localize`Apartment, building, floor (optional)`;
    address2.bindingProperty = 'venue.address.addressLine2';
    // address2.conflictType = this.concurrentUpdateFlag ? this.assignConflictPriority('addressLine2') : "";
    // const address2Data: any = this.concurrentUpdateFlag ?
    //   this.assignConflictIssueProperty('Address Line 2', 'addressLine2', 'address') : '';
    const address2Data: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentVenue, 'Address Line 2', 'addressLine2', 'address') : '';
    address2.conflictType = address2Data?.borderColor || address2Data;
    address2.tooltipText = address2Data?.tooltipText;
    items.push(address2);

    const venueLocation = new FormInputItem();
    venueLocation.inputName = 'venueLocation';
    venueLocation.inputType = FormInputType.Text;
    venueLocation.label = $localize`Location`;
    venueLocation.placeholder = $localize`Enter city or town name`;
    venueLocation.bindingProperty = 'venue.address.city';
    venueLocation.required = true;
    // venueLocation.conflictType = this.concurrentUpdateFlag ? this.assignConflictPriority('city') : "";
    // const locationData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('city', 'city', 'address') : '';
    const locationData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentVenue, 'city', 'city', 'address') : '';
    venueLocation.conflictType = locationData?.borderColor || locationData;
    venueLocation.tooltipText = locationData?.tooltipText;
    items.push(venueLocation);

    const venueCountry = new FormInputItem();
    venueCountry.itemType = FormItemType.Dropdown;
    venueCountry.inputName = 'venueCountry';
    venueCountry.label = $localize`Country`;
    venueCountry.placeholder = $localize`Choose a country`;
    venueCountry.bindingProperty = 'venue.address.countryId';
    venueCountry.required = true;
    venueCountry.dropdownIsObject = true;
    venueCountry.dropdownOptions = this.countries;
    // venueCountry.conflictType = this.concurrentUpdateFlag ? this.assignConflictPriority('countryId') : "";
    // const countryData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('country', 'country', 'address') : '';
    const countryData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentVenue, 'country', 'countryId', 'address') : '';
    venueCountry.conflictType = countryData?.borderColor || countryData;
    venueCountry.tooltipText = countryData?.tooltipText;
    venueCountry.valueChanged.subscribe(([countryId]) => {
      this.setProvinceOptions(countryId);
    });
    items.push(venueCountry);

    const venueProvince = new FormInputItem();
    venueProvince.itemType = FormItemType.Dropdown;
    venueProvince.inputName = 'venueProvince';
    venueProvince.label = $localize`Province`;
    venueProvince.placeholder = $localize`Choose a province`;
    venueProvince.bindingProperty = 'venue.address.stateId';
    venueProvince.required = true;
    venueProvince.dropdownIsObject = true;
    const provinceData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentVenue, 'state', 'stateId', 'address') : '';
    venueProvince.conflictType = provinceData?.borderColor || provinceData;
    venueProvince.tooltipText = provinceData?.tooltipText;
    // Country Code 1 = Canada
    venueProvince.dropdownOptions = this.countries?.find(
      c => c.id === this.getCountryId())?.states.sort((a, b) => a.name.localeCompare(b.name));
    items.push(venueProvince);

    const venuePostalCode = new FormInputItem();
    venuePostalCode.inputName = 'venuePostalCode';
    venuePostalCode.inputType = FormInputType.Text;
    venuePostalCode.label = $localize`Postal Code`;
    venuePostalCode.bindingProperty = 'venue.address.postalCode';
    venuePostalCode.required = true;
    // venuePostalCode.conflictType = this.concurrentUpdateFlag ? this.assignConflictPriority('postalCode') : "";
    // const postalCodeData: any = this.concurrentUpdateFlag ?
    // this.assignConflictIssueProperty('Postal Code', 'postalCode', 'address') : '';
    const postalCodeData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentVenue, 'Postal Code', 'postalCode', 'address') : '';
    venuePostalCode.conflictType = postalCodeData?.borderColor || postalCodeData;
    venuePostalCode.tooltipText = postalCodeData?.tooltipText;
    items.push(venuePostalCode);

    const subscriptionPlan = new FormInputItem();
    subscriptionPlan.itemType = FormItemType.Dropdown;
    subscriptionPlan.inputName = 'subscriptionPlanId';
    subscriptionPlan.label = $localize`Subscription Plan`;
    subscriptionPlan.placeholder = $localize`Choose a Subscription Plan`;
    subscriptionPlan.bindingProperty = 'venue.subscriptionPlanId';
    subscriptionPlan.dropdownIsObject = true;
    subscriptionPlan.required = true;
    subscriptionPlan.dropdownOptions = [];
    // subscriptionPlan.conflictType = this.concurrentUpdateFlag ? this.assignConflictPriority('subscriptionPlanId') : "";
    // const subscriptionData: any = this.concurrentUpdateFlag ?
    //   this.assignConflictIssueProperty('Subscription Plan', 'subscriptionPlanId') : '';
    const subscriptionData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentVenue, 'Subscription Plan', 'subscriptionPlanId') : '';
    subscriptionPlan.conflictType = subscriptionData?.borderColor || subscriptionData;
    subscriptionPlan.tooltipText = subscriptionData?.tooltipText;
    items.push(subscriptionPlan);

    const hiddenInput = new FormInputItem();
    hiddenInput.inputName = 'hiddenInput';
    hiddenInput.inputType = FormInputType.Text;
    hiddenInput.label = $localize``;
    hiddenInput.bindingProperty = 'null';
    hiddenInput.hideInput = true;
    items.push(hiddenInput);

    const venueIsActive = new FormInputItem();
    venueIsActive.itemType = FormItemType.Switch;
    venueIsActive.inputName = 'active';
    venueIsActive.label = $localize`Active`;
    venueIsActive.bindingProperty = 'venue.active';
    // const venueStatusData: any = this.concurrentUpdateFlag ? this.assignConflictIssueProperty('Status', 'active') : '';
    const venueStatusData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentVenue, 'Status', 'active') : '';
    venueIsActive.conflictType = venueStatusData?.borderColor || venueStatusData;
    venueIsActive.tooltipText = venueStatusData?.tooltipText;
    venueIsActive.valueChanged.subscribe(v => {
      if (!v[0]) {
        this.openDeactivateVenueModal();
      }
    }).addTo(this.subscriptions);
    items.push(venueIsActive);


    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    items.push(FormInputItem.generateDivider());

    this.formItems = items;
    this.setupFormBindings();
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'forgot-password-button-container';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save Venue`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: VenueFormObject) {
    this.viewModel.saveVenue(result);
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute }).then();
  }

  setSubscriptionPlanOptions(subscriptionPlans: SubscriptionPlan[]) {
    const subscriptionPlanInput = this.formItems.find(f => f.inputName === 'subscriptionPlanId');
    subscriptionPlanInput.dropdownOptions = subscriptionPlans;
  }

  showEditPhotoModal() {
    this.viewModel.getLogo(this.formObject).pipe(take(1)).subscribe((logo) => {
      const modalRef = this.modalService.open(UploadImageModalComponent, ModalUtils.defaultMedium());
      const compInstance = modalRef.componentInstance as UploadImageModalComponent;
      compInstance.initWith(
        $localize`Add Venue Image`,
        $localize`Crop Venue Image`,
        logo
      );
      modalRef.result.then((result) => {
        if (result) {
          this.formObject.imageToUpload = result;
        } else {
          this.removeLogo();
        }
      }, () => {
      });
    }).addTo(this.subscriptions);
  }

  removeLogo() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  public setProvinceOptions(countryId: number) {
    const province = this.formItems?.find(f => f.inputName === 'venueProvince');
    province.dropdownOptions = this.countries?.find(
      c => c.id === countryId)?.states.sort((a, b) => a.name.localeCompare(b.name));
    const option = JSON.parse(JSON.stringify(province?.dropdownOptions[0]));
    province.setInputFormControlValue(option.id);
  }

  // assignConflictPriority(property): string {
  //   // return (this.currentUserChanges.includes(property) && this.anotherUserChanges.includes(property)) ? 'red' : 'orange'
  //   return this.currentUserChanges.includes(property) ? 'red' : (this.anotherUserChanges.includes(property) ? 'orange' : 'none')
  // }

  openDeactivateVenueModal(): void {
    const modalRef = this.modalService.open(
      ConfirmationModalComponent,
      ModalUtils.confirmationModalOptions()
    );
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    const opts = new ConfirmationOptions();
    opts.title = $localize`Deactivate Venue`;
    // eslint-disable-next-line max-len
    opts.bodyText = $localize`Deactivating a venue will limit actions that admins can take with that venue. Are you sure you want to deactivate this venue?\n\nThis action can be reversed from the Venues tab. `;
    opts.cancelText = $localize`Cancel`;
    opts.continueText = $localize`Deactivate Venue`;
    compInstance.setConfirmationOptions(opts);
    modalRef.result.then((deactivate) => {
      if (!deactivate) {
        const activeInput = this.formItems.find(i => i.inputName === 'active');
        activeInput?.setInputFormControlValue(true);
      }
    });
  }

  compareObjects(currentObj, conflictObj, refreshObj) {
    // const result1 = this.diff(currentObj, conflictObj.venue, 'red');
    // const result2 = this.diff(currentObj, refreshObj, 'orange');

    const currentConflictObj = ConflictRecordUtils.diff(currentObj, conflictObj.venue, 'red');
    const currentRefreshObj = ConflictRecordUtils.diff(currentObj, refreshObj, 'orange');
    this.currentUserChanges = currentRefreshObj.currentUserChanges;
    this.currentUserChangesWithNestedProp = currentRefreshObj.currentUserChangesWithNestedProp;
    this.anotherUserChanges = currentRefreshObj.anotherUserChanges;
  }

  getCountryId(): number {
    const countryId = this.formObject?.venue?.address?.countryId;
    return countryId ? countryId : CountryType.getCanadaCountryCode();
  }

  // filterFalsy(obj) {
  //   return Object.keys(obj).reduce((acc, key) => {
  //     if (!obj[key]) {
  //       acc[key] = obj[key];
  //     }

  //     return acc;
  //   }, {});
  // }

  // get(obj, path) {
  //   return path.split('.').reduce((r, e) => {
  //     if (!r) { return r; }
  //     else { return r[e] || undefined; }
  //   }, obj);
  // }

  // diff(a, b, arr, prev = '') {
  //   return Object.keys(a).reduce((r, e) => {
  //     let value: any = '';
  //     const path = prev + (prev ? '.' + e : e);
  //     if (typeof a[e] == 'boolean') {
  //       value = a[e]?.toString() === b[e]?.toString();
  //     } else {
  //       value = a[e] === this.get(b, path);
  //     }
  //     // const value = a[e] === this.get(b, path);
  //     r[e] = typeof a[e] === 'object' ? this.diff(a[e], b, arr, path) : value;

  //     if (!r[e]) {
  //       if (path === 'active' || !this.exceptFieldsArr.includes(e)) {
  //         if (arr === 'red') {
  //           this.currentUserChanges.push(e);
  //           this.currentUserChangesWithNestedProp.push(path);
  //         }
  //         if (arr === 'orange') { this.anotherUserChanges.push(e); }
  //       }
  //     }
  //     return r;
  //   }, {});
  // }
}
