import {ChangeDetectorRef, Component} from '@angular/core';
import {TopNavViewModel} from './top-nav-view-model';
import {BaseComponent} from '../../../models/base/base-component';
import {NavigationEnd, Router} from '@angular/router';
import {ToastService} from '../../../services/toast-service';
import {SearchDomainModel} from '../../../domainModels/search-domain-model';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  providers: [TopNavViewModel],
})
export class TopNavComponent extends BaseComponent {

  public currentNavUrl: string;
  public navItems: TopNavItem[] = [];

  constructor(
    public viewModel: TopNavViewModel,
    private router: Router,
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
    private searchDomainModel: SearchDomainModel,
  ) {
    super();
  }

  setupViews() {
    this.bindUrlParams();
    this.setupNavItems();
  }

  setupBindings() {
  }

  navigateToRoot() {
    this.router.navigate(['']);
  }

  private bindUrlParams() {
    this.currentNavUrl = this.router.url;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.urlChanged(evt);
    });
  }

  private urlChanged(nav: NavigationEnd) {
    this.currentNavUrl = nav.urlAfterRedirects;
    this.cd.detectChanges();
  }

  isNavItemActive(navItem: TopNavItem): boolean {
    return this.currentNavUrl === navItem.identifier;
  }

  private setupNavItems() {
    this.navItems = [
      new TopNavItem('/home', $localize`Home`, null, true),
      new TopNavItem('/home/leagues', $localize`Leagues`, null, true),
      new TopNavItem('/home/events', $localize`Events`, null, true),
      new TopNavItem('/home/venues', $localize`Venues`, null, true),
      new TopNavItem('search', $localize`Search`, 'assets/icons/light/outline/search.svg', false),
      new TopNavItem('profile', $localize`Account`, null, false),
    ];
  }

  navItemClicked(navItem: TopNavItem) {
    switch (navItem.identifier) {
      case 'search':
        this.searchDomainModel.showSearchModal();
        break;
      case 'profile':
        this.profileNavItemClicked();
        break;
      default:
        this.router.navigate([navItem.identifier]);
    }
  }

  profileNavItemClicked() {
    this.viewModel.sessionContainer$.pipe(take(1)).subscribe(s => {
      if (!!s) {
        this.router.navigate(['account']);
      } else {
        this.viewModel.signInClicked();
      }
    });
  }
}

export class TopNavItem {
  title?: string;
  iconSrc?: string;
  identifier: string;
  hideOnMobile: boolean;

  constructor(identifier: string, title: string, iconSrc: string, hideOnMobile: boolean) {
    this.title = title;
    this.iconSrc = iconSrc;
    this.identifier = identifier;
    this.hideOnMobile = hideOnMobile;
  }
}
