<app-content-container [narrow]="true">
  <div class="py-4 h-100 position-relative min-height-20">
    <app-loading [hidden]="!(viewModel.loadingOpts?.isLoading)"
                 [options]="viewModel.loadingOpts">
    </app-loading>
    <app-form-group
      *ngIf="formItems?.length > 0"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (formCancelled)="cancel()"
      (formSubmitted)="formSubmitted($event)">
      <div class="d-block w-100">
        <div class="venue-photo-container" (click)="showEditPhotoModal()">
          <div class="venue-photo mr-3">
            <img [src]="formObject?.imageToUpload" alt=""
                 *ngIf="formObject.imageToUpload">
            <img [src]="(formObject?.venue?.imgSrc$|async) || ''" alt=""
                 *ngIf="formObject.existingImageId && formObject.imageToUpload === null && !formObject.deleteImageId">
          </div>
          <div class="photo-text">
            <ng-container
              *ngIf="!(formObject.imageToUpload || formObject.existingImageId)"
              i18n>Add a Venue Image
            </ng-container>
            <ng-container
              *ngIf="formObject.imageToUpload || formObject.existingImageId"
              i18n>Change Venue Image
            </ng-container>
          </div>
        </div>
      </div>
    </app-form-group>
  </div>
</app-content-container>
