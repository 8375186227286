import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EditAdvertisingPartnerModalViewModel} from './edit-advertising-partner-modal-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {FormInputItem, FormInputType, FormItemType} from '../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../models/shared/stylesheet/form-options';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AdvertisingPartner} from '../../../../models/resources/advertising-partner';
import { DeserializeHelper } from 'src/app/models/protocols/deserializable';
import { AdvertisingPartnerDetailsViewModel } from '../advertising-partner-details/advertising-partner-details-view-model';
import ConflictRecordUtils from 'src/app/utils/conflict-record-utils';

@Component({
  selector: 'app-edit-advertising-partner-modal',
  templateUrl: './edit-advertising-partner-modal.component.html',
  styleUrls: ['./edit-advertising-partner-modal.component.scss'],
  providers: [EditAdvertisingPartnerModalViewModel],
})
export class EditAdvertisingPartnerModalComponent extends BaseComponent {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: AdvertisingPartner = new AdvertisingPartner();

  isEditing: boolean = false;
  @Output() returnResult: EventEmitter<any> = new EventEmitter();
  @Input() concurrentUpdateTeam: EventEmitter<any> = new EventEmitter();
  public concurrentUpdateFlag: boolean = false;
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];
  @Input() currentPartner: AdvertisingPartner;

  constructor(
    public viewModel: EditAdvertisingPartnerModalViewModel,
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  setupBindings() {
    this.viewModel.advertPartner$.notNull().subscribe(partner => {
      setTimeout(() => {
        this.currentPartner = (partner);
      });
    }, error => {
      // this.toastService.publishError(error);
    }).addTo(this.subscriptions);

    this.concurrentUpdateTeam.subscribe(conflictObject => {
      // If concurrent issue occurs
      if (conflictObject) {
        this.viewModel.getAdvertisingPartnerAfterConcurrent(conflictObject.id).subscribe(refreshPartner => {
          this.concurrentUpdateFlag = true;
          this.compareObjects(this.currentPartner, conflictObject, refreshPartner);
          this.setAdvertisingObjAfterConflict(conflictObject, refreshPartner);
          this.formObject = DeserializeHelper.deserializeToInstance(AdvertisingPartner, refreshPartner);
          this.setupFormItems();
        });
      } else {
        this.concurrentUpdateFlag = false;
        this.setupFormItems();
      }
    });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupFormItems() {
    const userChanges = {
      currentUserChanges: this.currentUserChanges,
      anotherUserChanges: this.anotherUserChanges
    };
    const items: FormInputItem[] = [];

    const name = new FormInputItem();
    name.inputName = 'name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Name`;
    name.bindingProperty = 'name';
    name.required = true;
    const nameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentPartner, 'name', 'name') : '';
    name.conflictType = nameData?.borderColor || nameData;
    name.tooltipText = nameData?.tooltipText;
    items.push(name);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'active';
    active.overrideFullWidth = true;
    const statusData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentPartner, 'Status', 'active') : '';
    active.conflictType = statusData?.borderColor || statusData;
    active.tooltipText = statusData?.tooltipText || statusData;
    items.push(active);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    // this.activeModal.close(this.formObject);
    this.returnResult.emit(this.formObject);
  }

  getModalTitle(): string {
    return this.isEditing ? $localize`Edit Advertising Partner` : $localize`Create Advertising Partner`;
  }

//concurrent issue code reason
  compareObjects(currentObj, conflictObj, refreshObj) {
    const currentConflictObj = ConflictRecordUtils.diff(currentObj, conflictObj, 'red');
    const currentRefreshObj = ConflictRecordUtils.diff(currentObj, refreshObj, 'orange');
    this.currentUserChanges = currentRefreshObj.currentUserChanges;
    this.currentUserChangesWithNestedProp = currentRefreshObj.currentUserChangesWithNestedProp;
    this.anotherUserChanges = currentRefreshObj.anotherUserChanges;
  }

  setAdvertisingObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      const val = ConflictRecordUtils.getObjProp(conflictObj, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }

  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }//concurrent issue code reason end
}
