import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TabBarComponent} from './components/tab-bar/tab-bar.component';
import {MaterialModule} from './material/material.module';
import {TestTabComponent} from './components/tab-bar/test-tab/test-tab.component';
import {TabContentComponent} from './components/tab-bar/tab-content/tab-content.component';
import {TabContentContainerDirective} from './components/tab-bar/tab-content/tab-content-container.directive';
import {LoadingComponent} from './components/loading/loading.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ColorPickerModule} from 'ngx-color-picker';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {PillComponent} from './components/pills/pill/pill.component';
import {PillContainerComponent} from './components/pills/pill-container/pill-container.component';
import {DropDownComponent} from './components/drop-down/drop-down.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormGroupComponent} from './components/form-group/form-group.component';
import {LetterOnlyValidatorDirective} from './components/form-group/validators/letter-only-validator.directive';
import {AlphanumericValidatorDirective} from './components/form-group/validators/alphanumeric-validator.directive';
import {PasswordValidatorDirective} from './components/form-group/validators/password-validator.directive';
import {CheckboxContainerComponent} from './components/checkboxes/checkbox-container/checkbox-container.component';
import {CheckboxComponent} from './components/checkboxes/checkbox/checkbox.component';
import {DropDownMenuComponent} from './components/drop-down-menu/drop-down-menu.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PhoneValidatorDirective} from './components/form-group/validators/phone-validator.directive';
import {AbandonFormComponent} from './modals/abandon-form/abandon-form.component';
import {LocationPickerComponent} from './modals/location-picker/location-picker.component';
import {CardDeckComponent} from './components/card-deck/card-deck.component';
import {CardComponent} from './components/card-deck/card/card.component';
import {
  ConfirmationCodeValidatorDirective
} from './components/form-group/validators/confirmation-code-validator.directive';
import {UploadItemComponent} from './components/upload-asset/upload-item/upload-item.component';
import {UploadAssetComponent} from './components/upload-asset/upload-asset.component';
import {DndDirective} from './directives/dnd-directive';
import {HeaderComponent} from './components/header/header.component';
import {DataTableComponent} from './components/data-table/data-table.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CardTableComponent} from './components/card-table/card-table.component';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {LabelGroupComponent} from './components/label-group/label-group.component';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {ReorderModalComponent} from './components/reorder-modal/reorder-modal.component';
import {ReorderListComponent} from './components/reorder-list/reorder-list.component';
import {ActionListComponent} from './components/action-list/action-list.component';
import {ActionComponent} from './components/action-list/action/action.component';
import {RangeSliderComponent} from './components/range-slider/range-slider.component';
import {ActiveComponent} from './components/active/active.component';
import {SingleSelectionListComponent} from './components/single-selection-list/single-selection-list.component';
import {SegmentedControlComponent} from './components/segmented-control/segmented-control.component';
import {LogoUploadComponent} from './components/logo-upload/logo-upload.component';
import {ThemeCarouselImageComponent} from './components/card-deck/theme-carousel-image/theme-carousel-image.component';
import {NgxPopperModule} from 'ngx-popper';
import {IMaskModule} from 'angular-imask';
import {FeatureMarqueeComponent} from './components/feature-marquee/feature-marquee.component';
import {ContentContainerComponent} from './components/content-container/content-container.component';
import {CardRowComponent} from './components/card-row/card-row.component';
import {SwiperModule} from 'swiper/angular';
import {DetailBackButtonComponent} from './components/detail-back-button/detail-back-button.component';
import {FavouritePickerComponent} from './components/favourite-picker/favourite-picker.component';
import {FavouriteTeamPipe} from './pipes/favourite-team.pipe';
import {IvsVideoPlayerComponent} from './components/ivs-video-player/ivs-video-player.component';
import {FeedbackModalComponent} from './components/feedback-modal/feedback-modal.component';
import {ChangePasswordModalComponent} from './components/change-password-modal/change-password-modal.component';
import {UploadImageModalComponent} from './components/upload-image-modal/upload-image-modal.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DetailTitleComponent} from './components/detail-title/detail-title.component';
import {SearchModalComponent} from './components/search-modal/search-modal.component';
import {
  FavouritePickerRowComponent
} from './components/favourite-picker/favourite-picker-row/favourite-picker-row.component';
import {AuthModalComponent} from './modals/auth-modal/auth-modal.component';
import {SignInFormComponent} from './modals/auth-modal/sign-in-form/sign-in-form.component';
import {ForgotPasswordFormComponent} from './modals/auth-modal/forgot-password-form/forgot-password-form.component';
import {ResetPasswordFormComponent} from './modals/auth-modal/reset-password-form/reset-password-form.component';
import {NewPasswordFormComponent} from './modals/auth-modal/new-password-form/new-password-form.component';
import {SignUpFormComponent} from './modals/auth-modal/sign-up-form/sign-up-form.component';
import {VenueStreamPickerModalComponent} from './modals/venue-stream-picker-modal/venue-stream-picker-modal.component';
import {FooterComponent} from './components/footer/footer.component';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {RadioButtonComponent} from './components/radiobuttons/radiobutton/radiobutton.component';
import {
  RadiobuttonContainerComponent
} from './components/radiobuttons/radiobutton-container/radiobutton-container.component';

@NgModule({
  providers: [],
  declarations: [
    TabBarComponent,
    TestTabComponent,
    TabContentComponent,
    TabContentContainerDirective,
    DropDownComponent,
    PillComponent,
    PillContainerComponent,
    LoadingComponent,
    ProgressBarComponent,
    FormGroupComponent,
    LetterOnlyValidatorDirective,
    AlphanumericValidatorDirective,
    PasswordValidatorDirective,
    ConfirmationCodeValidatorDirective,
    PhoneValidatorDirective,
    CheckboxContainerComponent,
    CheckboxComponent,
    DropDownMenuComponent,
    AbandonFormComponent,
    LocationPickerComponent,
    CardDeckComponent,
    CardComponent,
    DataTableComponent,
    UploadItemComponent,
    UploadAssetComponent,
    DndDirective,
    HeaderComponent,
    FooterComponent,
    CardTableComponent,
    BreadcrumbsComponent,
    LabelGroupComponent,
    ConfirmationModalComponent,
    ReorderModalComponent,
    ReorderListComponent,
    ActionListComponent,
    ActionComponent,
    ReorderListComponent,
    RangeSliderComponent,
    ActiveComponent,
    SingleSelectionListComponent,
    SegmentedControlComponent,
    LogoUploadComponent,
    ThemeCarouselImageComponent,
    FeatureMarqueeComponent,
    ContentContainerComponent,
    CardRowComponent,
    DetailBackButtonComponent,
    FavouritePickerComponent,
    FavouritePickerRowComponent,
    FavouriteTeamPipe,
    IvsVideoPlayerComponent,
    FeedbackModalComponent,
    ChangePasswordModalComponent,
    UploadImageModalComponent,
    DetailTitleComponent,
    SearchModalComponent,
    AuthModalComponent,
    SignInFormComponent,
    ForgotPasswordFormComponent,
    ResetPasswordFormComponent,
    NewPasswordFormComponent,
    SignUpFormComponent,
    VenueStreamPickerModalComponent,
    RadioButtonComponent,
    RadiobuttonContainerComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSpinnerModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    NgbTooltipModule,
    IMaskModule,
    NgxPopperModule,
    SwiperModule,
    ImageCropperModule,
    ButtonsModule,
  ],
  exports: [
    // Shared Components
    TabBarComponent,
    MaterialModule,
    TestTabComponent,
    LoadingComponent,
    NgxSpinnerModule,
    DropDownComponent,
    PillComponent,
    PillContainerComponent,
    ColorPickerModule,
    ProgressBarComponent,
    FormsModule,
    ReactiveFormsModule,
    FormGroupComponent,
    LetterOnlyValidatorDirective,
    AlphanumericValidatorDirective,
    CheckboxComponent,
    CheckboxContainerComponent,
    BsDropdownModule,
    DropDownMenuComponent,
    AbandonFormComponent,
    CardDeckComponent,
    CardComponent,
    DataTableComponent,
    UploadItemComponent,
    UploadAssetComponent,
    HeaderComponent,
    FooterComponent,
    CardTableComponent,
    BreadcrumbsComponent,
    LabelGroupComponent,
    ConfirmationModalComponent,
    ReorderModalComponent,
    RangeSliderComponent,
    ActionListComponent,
    ActiveComponent,
    SingleSelectionListComponent,
    SegmentedControlComponent,
    LogoUploadComponent,
    FeatureMarqueeComponent,
    ContentContainerComponent,
    CardRowComponent,
    IvsVideoPlayerComponent,
    FeedbackModalComponent,
    DetailBackButtonComponent,
    FavouritePickerComponent,
    FavouritePickerRowComponent,
    ChangePasswordModalComponent,
    UploadImageModalComponent,
    DetailTitleComponent,
    SearchModalComponent,
    AuthModalComponent,
    ForgotPasswordFormComponent,
    VenueStreamPickerModalComponent,
    RadioButtonComponent,
    RadiobuttonContainerComponent,
  ],
})
export class SharedModule {
}
