<div class="mt-4">
  <div class="stream-note" i18n>
    NOTE: This information will become available 30 minutes before the program start time and will be available until 30
    minutes after the program has completed.
  </div>
  <form class="program-stream-form">
    <label class="custom-textfield-label" for="amazonIngestStreamUrl" i18n>Ingest Server</label>
    <div class="stream-text-field-container">
      <input class="custom-textfield"
             [readOnly]="true"
             [ngModel]="(viewModel.program$|async).amazonIngestStreamUrl"
             id="amazonIngestStreamUrl"
             name="amazonIngestStreamUrl"
             i18n-placeholder
             placeholder="Ingest Server"
             type="text">
      <button class="custom-button text-button ml-2 mb-4"
              [hidden]="!((viewModel.program$|async).amazonIngestStreamUrl)"
              (click)="copyIngestUrlToClipboard()"
              i18n>
        Copy
      </button>
    </div>
  </form>
  <form class="program-stream-form">
    <label class="custom-textfield-label" for="amazonIngestStreamKey" i18n>Ingest Server Key</label>
    <div class="stream-text-field-container">
      <input class="custom-textfield"
             [readOnly]="true"
             [ngModel]="(viewModel.program$|async).amazonIngestStreamKey"
             id="amazonIngestStreamKey"
             name="amazonIngestStreamKey"
             i18n-placeholder
             placeholder="Ingest Server Key"
             [type]="hideIngestServerKey ? 'password' : 'text'">
      <button class="custom-button text-button color-mid-grey ml-2 mb-4"
              (click)="hideIngestServerKey = !hideIngestServerKey"
              [hidden]="!((viewModel.program$|async).amazonIngestStreamKey)">
        <ng-container *ngIf="!hideIngestServerKey" i18n>Hide</ng-container>
        <ng-container *ngIf="hideIngestServerKey" i18n>View</ng-container>
      </button>
      <button class="custom-button text-button ml-2 mb-4"
              [hidden]="!((viewModel.program$|async).amazonIngestStreamKey)"
              (click)="copyIngestKeyToClipboard()"
              i18n>
        Copy
      </button>
    </div>
  </form>
  <hr>
  <div class="stream-note" i18n>
    NOTE: The Pixellot stream urls will become available 5 minutes before the program start time.
  </div>
  <form class="program-stream-form">
    <label class="custom-textfield-label" for="pixellotStreamUrl" i18n>Pixellot HD Stream Url</label>
    <div class="stream-text-field-container">
      <input class="custom-textfield"
             [readOnly]="true"
             [ngModel]="(viewModel.program$|async).pixellotHDStreamUrl"
             id="pixellotStreamUrl"
             name="pixellotStreamUrl"
             i18n-placeholder
             placeholder="Pixellot Stream Url"
             type="text">
      <button class="custom-button text-button ml-2 mb-4"
              [hidden]="!((viewModel.program$|async).pixellotHDStreamUrl)"
              (click)="copyPixellotStreamUrlToClipboard()"
              i18n>
        Copy
      </button>
    </div>
  </form>
  <form class="program-stream-form">
    <label class="custom-textfield-label" for="pixellotPanoStreamUrl" i18n>Pixellot Pano Stream Url</label>
    <div class="stream-text-field-container">
      <input class="custom-textfield"
             [readOnly]="true"
             [ngModel]="(viewModel.program$|async).pixellotPANOStreamUrl"
             id="pixellotPanoStreamUrl"
             name="pixellotPanoStreamUrl"
             i18n-placeholder
             placeholder="Pixellot Pano Stream Url"
             type="text">
      <button class="custom-button text-button ml-2 mb-4"
              [hidden]="!((viewModel.program$|async).pixellotPANOStreamUrl)"
              (click)="pixellotPanoStreamUrl()"
              i18n>
        Copy
      </button>
    </div>
  </form>
</div>
