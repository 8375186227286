import {Component, OnInit} from '@angular/core';
import {NavItem} from '../../../models/shared/nav-item';
import {NavigationEnd, Router} from '@angular/router';
import {SessionService} from '../../../services/session-service';
import {Viewable} from '../../../models/protocols/viewable';
import '../../../utils/observable.extensions';
import {AccountDomainModel} from '../../../domainModels/account-domain-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {CustomError} from '../../../models/shared/custom-error';
import {ToastService} from '../../../services/toast-service';
import {Routable} from '../../../models/protocols/routable';
import {NavItemId} from '../../../models/enum/shared/nav-item-id.enum';
import {BaseComponent} from '../../../models/base/base-component';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-sidebar-navigation',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss']
})
export class SidebarNavComponent extends BaseComponent implements OnInit, Viewable, Routable {

  public loadingOpts: LoadingOptions = LoadingOptions.defaultLight();
  public currentLocationName: string = 'Location';
  public currentNavUrl: string;
  public badgeContent = new Map<string, string>();
  public navItems: NavItem[] = [];
  public dev = !environment.production;

  constructor(
    private router: Router,
    private session: SessionService,
    private accountDomainModel: AccountDomainModel,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.bindUrlParams();
    this.setupBindings();
    this.setNavItems();
    this.setupViews();
  }

  bindUrlParams() {
    this.currentNavUrl = this.router.url;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.urlChanged(evt);
    });
  }

  setupViews() {
    this.loadingOpts.fullscreen = true;
    this.loadingOpts.zIndex = 100;
  }

  setupBindings() {
    // bind the active location name to the Location menu option
    const locSub = this.session.sessionContainer.notNull().subscribe((_) => {
      this.setNavItems();
    });
    this.pushSub(locSub);
  }

  setNavItems() {
    this.navItems = [];
    // Users
    this.navItems.push({
      id: NavItemId.Route,
      name: $localize`Users`,
      routerPath: 'admin/users',
      iconSrc: 'assets/icons/light/outline/users.svg',
      iconFilledSrc: 'assets/icons/light/solid/users.svg',
      locationPicker: false,
    });
    // Sports
    this.navItems.push({
      id: NavItemId.Route,
      name: $localize`Sports`,
      routerPath: 'admin/sports',
      iconSrc: 'assets/icons/light/outline/globe-alt.svg',
      iconFilledSrc: 'assets/icons/light/solid/globe-alt.svg',
      locationPicker: false,
    });
    // Leagues
    this.navItems.push({
      id: NavItemId.Route,
      name: $localize`Leagues`,
      routerPath: 'admin/leagues',
      iconSrc: 'assets/icons/light/outline/flag.svg',
      iconFilledSrc: 'assets/icons/light/solid/flag.svg',
      locationPicker: false,
    });
    // Venues
    this.navItems.push({
      id: NavItemId.Route,
      name: $localize`Venues`,
      routerPath: 'admin/venues',
      iconSrc: 'assets/icons/light/outline/location-marker.svg',
      iconFilledSrc: 'assets/icons/light/solid/location-marker.svg',
      locationPicker: false,
    });
    // Events
    this.navItems.push({
      id: NavItemId.Route,
      name: $localize`Events`,
      routerPath: 'admin/events',
      iconSrc: 'assets/icons/light/outline/event-icon.svg',
      iconFilledSrc: 'assets/icons/light/solid/event-icon.svg',
      locationPicker: false,
    });
    // Teams
    this.navItems.push({
      id: NavItemId.Route,
      name: $localize`Teams`,
      routerPath: 'admin/teams',
      iconSrc: 'assets/icons/light/outline/speakerphone.svg',
      iconFilledSrc: 'assets/icons/light/solid/speakerphone.svg',
      locationPicker: false,
    });
    // Plans
    this.navItems.push({
      id: NavItemId.Route,
      name: $localize`Plans`,
      routerPath: 'admin/plans',
      iconSrc: 'assets/icons/light/outline/money.svg',
      iconFilledSrc: 'assets/icons/light/solid/money.svg',
      locationPicker: false,
    });
    // Ads
    this.navItems.push({
      id: NavItemId.Route,
      name: $localize`Advertisements`,
      routerPath: 'admin/advertisements',
      iconSrc: 'assets/icons/light/outline/currency-dollar.svg',
      iconFilledSrc: 'assets/icons/light/solid/currency-dollar.svg',
      locationPicker: false,
    });
    // Profile
    this.navItems.push({
      id: NavItemId.Route,
      name: $localize`Profile`,
      routerPath: 'admin/profile',
      iconSrc: 'assets/icons/light/outline/user-circle.svg',
      iconFilledSrc: 'assets/icons/light/solid/user-circle.svg',
      locationPicker: false,
    });
    // Sign Out
    this.navItems.push({
      id: NavItemId.SignOut,
      name: $localize`Sign Out`,
      routerPath: 'sign-out',
      iconSrc: 'assets/icons/light/outline/logout.svg',
      iconFilledSrc: 'assets/icons/light/solid/logout.svg',
      locationPicker: false
    });
  }

  navigateToRoot() {
    this.router.navigate(['/admin/users']).then();
  }

  navItemSelected(navItem: NavItem) {
    if (navItem.routerPath === 'sign-out') {
      this.signOut();
    } else {
      this.router.navigate([navItem.routerPath]).then(() => {
      });
    }
  }

  signOut() {
    const loadingMess = $localize`Signing Out`;
    this.loadingOpts.addRequest(loadingMess);
    setTimeout(() => {
      this.accountDomainModel.signOut();
      this.router.navigate(['auth/sign-in/admin']).then(() => {
        // dont remove loading spinner or the ui flashes before navigating
        window.location.reload();
      });
    }, 1000);
  }

  getBadgeContentForNavItem(navItem: NavItem): string {
    return this.badgeContent.get(navItem.routerPath);
  }

  isNavItemActive(navItem: NavItem): boolean {
    return this.currentNavUrl.indexOf(navItem.routerPath) >= 0;
  }

  private urlChanged(nav: NavigationEnd) {
    this.currentNavUrl = nav.url;
  }

  multilineNavLabel(i: NavItem): boolean {
    return i.name.length > 12;
  }

}
