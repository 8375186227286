import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {EventDetailsViewModel} from '../event-details-view-model';
import {FormInputItem, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {EventFormObject} from '../../../../../models/resources/event-form-object';
import {ToastService} from '../../../../../services/toast-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalUtils} from '../../../../../utils/modal-utils';
import {AddVenueModalComponent} from '../../../shared/add-venue-modal/add-venue-modal.component';
import {HydratedVenue} from '../../../../../models/resources/hydrated-venue';

@Component({
  selector: 'app-event-venues',
  templateUrl: './event-venues.component.html',
  styleUrls: ['./event-venues.component.scss'],
})
export class EventVenuesComponent extends BaseComponent {

  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: EventFormObject;
  public updatedFormObject = new EventEmitter<void>();
  private addVenueClicked = new EventEmitter();
  private removeVenueClicked = new EventEmitter<HydratedVenue>();

  constructor(
    public viewModel: EventDetailsViewModel,
    private toastService: ToastService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    this.setupFormItems();
  }

  setupBindings() {
    this.viewModel.event$.notNull().subscribe(event => {
      setTimeout(() => {
        this.formObject = EventFormObject.initWithEvent(event);
        this.viewModel.updateFormItemStatesSubject$.next();
      });
    }, error => {
      this.toastService.publishError(error);
    }).addTo(this.subscriptions);

    this.addVenueClicked.subscribe(() => {
      this.showAddVenueModal();
    }).addTo(this.subscriptions);

    this.removeVenueClicked.subscribe((v) => {
      v.itemDeleted = true;
      this.saveEventVenue(v);
    }).addTo(this.subscriptions);
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    this.formStyling.includePadding = false;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: EventFormObject) {
  }

  setupFormItems() {
    const items: FormInputItem[] = [];

    const venuesTitle = new FormInputItem();
    venuesTitle.itemType = FormItemType.Title;
    venuesTitle.label = $localize`Venues`;
    venuesTitle.titleButtonText = $localize`Add a Venue`;
    venuesTitle.titleButtonClicked = this.addVenueClicked;
    venuesTitle.overrideFullWidth = true;
    items.push(venuesTitle);

    const venues = new FormInputItem();
    venues.inputName = 'associatedVenues';
    venues.itemType = FormItemType.List;
    venues.bindingProperty = 'event.associatedVenues';
    venues.listItemButtonText = $localize`Remove`;
    venues.listItemClicked = this.removeVenueClicked;
    items.push(venues);

    this.formItems = items;
  }

  cancel() {
  }

  private showAddVenueModal() {
    const modalRef = this.modalService.open(
      AddVenueModalComponent,
      ModalUtils.addVenueModalOptions(),
    );
    modalRef.result.then((selectedVenue: HydratedVenue) => {
      this.addVenue(selectedVenue);
    }, reason => {});
  }

  private addVenue(selectedVenue: HydratedVenue) {
    if (selectedVenue) {
      const existingAssociatedVenue = this.formObject.event.associatedVenues?.find(av => av.id === selectedVenue.id);
      if (existingAssociatedVenue && existingAssociatedVenue.itemDeleted) {
        existingAssociatedVenue.itemDeleted = false;
        this.saveEventVenue(existingAssociatedVenue);
      } else if (!existingAssociatedVenue) {
        selectedVenue.itemCreated = true;
        if (this.formObject.event.associatedVenues == null) {
          this.formObject.event.associatedVenues = [];
        }
        this.formObject.event.associatedVenues.push(selectedVenue);
        this.saveEventVenue(selectedVenue);
      }
    }
  }

  saveEventVenue(venueVenue: HydratedVenue) {
    this.viewModel.saveEventVenueAssociation(venueVenue).subscribe((updatedEventVenue) => {
      this.toastService.publishSuccessMessage($localize`Event Venue Saved`, null);
      const index = this.formObject.event.associatedVenues.indexOf(venueVenue);
      if (!updatedEventVenue) {
        this.formObject.event.associatedVenues.splice(index, 1);
      }
    }, error => {
      this.toastService.publishErrorMessage(error?.message, $localize`Event Venue Save Failed`);
    });
  }
}
