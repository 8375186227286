import { Component, EventEmitter } from '@angular/core';
import { ShowDetailsViewModel } from './show-details-view-model';
import { BaseComponent } from '../../../../models/base/base-component';
import { FormInputItem, FormInputType, FormItemType } from '../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../models/shared/stylesheet/form-group-styling';
import { FormOptions } from '../../../../models/shared/stylesheet/form-options';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { CustomFile } from '../../../../models/shared/custom-file';
import { SubscriptionPlan } from '../../../../models/account/dto/subscription-plan';
import { ShowFormObject } from '../../../../models/program/show-form-object';
import { combineLatest } from 'rxjs';
import ConflictRecordUtils from '../../../../utils/conflict-record-utils';

@Component({
  selector: 'app-show-details',
  templateUrl: './show-details.component.html',
  styleUrls: ['./show-details.component.scss'],
  providers: [ShowDetailsViewModel],
})
export class ShowDetailsComponent extends BaseComponent {

  public readonly imageUploadId = 1;
  public readonly videoUploadId = 2;
  public formItems: FormInputItem[] = [];
  public formStyling = new FormGroupStyling();
  public formOptions = new FormOptions();
  public formObject: ShowFormObject;
  public updatedFormObject = new EventEmitter<void>();
  public hydrateInputObject = new EventEmitter<void>();

  currentShow: any = null;
  public concurrentUpdateFlag: boolean = false;
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];

  constructor(
    public viewModel: ShowDetailsViewModel,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
  }

  setupBindings() {
    combineLatest([this.viewModel.show$.notNull(), this.viewModel.showUploads$])
      .pipe(debounceTime(100))
      .subscribe(([show, uploads]) => {
        this.currentShow = JSON.parse(JSON.stringify(show));
        this.formObject = ShowFormObject.initWithShow(show, uploads);
        if (this.formItems.length === 0) {
          this.setupFormItems();
        } else {
          this.viewModel.updateFormItemStatesSubject$.next();
        }
      }).addTo(this.subscriptions);

    this.viewModel.formErrorMessage.pipe(debounceTime(100)).subscribe(error => {
      const banner = this.formItems.find(f => f.alertBannerId === 'banner');
      if (banner) {
        banner.alertBannerMessage = error;
      }
    }).addTo(this.subscriptions);

    this.viewModel.concurrentUpdate.subscribe(conflictObject => {
      // If concurrent issue occurs
      if (conflictObject) {
        combineLatest([this.viewModel.refreshHydratedShow$, this.viewModel.showUploads$])
          .pipe(debounceTime(100))
          .subscribe(([refreshShow, uploads]) => {
            this.compareObjects(this.currentShow, conflictObject.show, refreshShow);
            this.setShowObjAfterConflict(conflictObject.show, refreshShow);
            this.concurrentUpdateFlag = true;
            this.formObject = ShowFormObject.initWithShow(refreshShow, uploads);
            this.setupFormItems();
          }).addTo(this.subscriptions);
      } else {
        this.concurrentUpdateFlag = false;
      }
    });
  }

  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }

  setShowObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      const val = ConflictRecordUtils.getObjProp(conflictObj, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }

  compareObjects(currentObj, conflictObj, refreshObj) {
    const currentConflictObj = ConflictRecordUtils.diff(currentObj, conflictObj, 'red');
    const currentRefreshObj = ConflictRecordUtils.diff(currentObj, refreshObj, 'orange');
    this.currentUserChanges = currentRefreshObj.currentUserChanges;
    this.currentUserChangesWithNestedProp = currentRefreshObj.currentUserChangesWithNestedProp;
    this.anotherUserChanges = currentRefreshObj.anotherUserChanges;
  }

  setupFormBindings() {
    this.viewModel.updateFormItemStatesSubject$.pipe(debounceTime(100)).subscribe(() => {
      this.updateFormItemStates();
    }).addTo(this.subscriptions);
  }

  setupFormItems() {
    const userChanges = {
      currentUserChanges: this.currentUserChanges,
      anotherUserChanges: this.anotherUserChanges
    };

    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const name = new FormInputItem();
    name.inputName = 'Show Name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Show Name`;
    name.bindingProperty = 'show.name';
    name.overrideFullWidth = true;
    name.required = true;
    const nameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentShow, 'name', 'name') : '';
    name.conflictType = nameData?.borderColor || nameData;
    name.tooltipText = nameData?.tooltipText;
    items.push(name);

    const description = new FormInputItem();
    description.itemType = FormItemType.Textarea;
    description.inputName = 'description';
    description.label = $localize`Show Description`;
    description.placeholder = $localize`Write a custom show description`;
    description.bindingProperty = 'show.description';
    description.required = true;
    description.overrideFullWidth = true;
    description.autoGrowTextArea = true;
    description.autoGrowMinRows = 6;
    description.autoGrowMaxRows = 6;
    description.customClass = 'mb-3';
    const descriptionData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.currentShow, 'description', 'description') : '';
    description.conflictType = descriptionData?.borderColor || descriptionData;
    description.tooltipText = descriptionData?.tooltipText;
    items.push(description);
    //
    // const subscriptionPlan = new FormInputItem();
    // subscriptionPlan.itemType = FormItemType.Dropdown;
    // subscriptionPlan.inputName = 'subscriptionPlanId';
    // subscriptionPlan.label = $localize`Subscription Plan`;
    // subscriptionPlan.placeholder = $localize`Choose a Subscription Plan`;
    // subscriptionPlan.bindingProperty = 'show.subscriptionPlanId';
    // subscriptionPlan.dropdownIsObject = true;
    // subscriptionPlan.required = true;
    // subscriptionPlan.enabled = true;
    // subscriptionPlan.dropdownOptions = [];
    // items.push(subscriptionPlan);

    items.push(FormInputItem.generateDivider());

    const projectedContent = new FormInputItem();
    projectedContent.itemType = FormItemType.ProjectedContent;
    items.push(projectedContent);

    items.push(FormInputItem.generateDivider());

    this.formItems = items;
    this.setupFormBindings();
    this.viewModel.updateFormItemStatesSubject$.next();
  }

  updateFormItemStates() {
    if (this.formItems.length === 0) {
      return;
    }
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 2;
    this.formStyling.includePadding = false;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'left';
    this.formStyling.primaryButtonClass = 'mr-3';
    this.formStyling.primaryButtonContainerClass = 'd-flex flex-row-reverse justify-content-end';
    this.formStyling.submitButtonText = $localize`Save Show`;
    this.formStyling.resetButtonText = '';
    this.formStyling.cancelButtonText = $localize`Cancel`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = true;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted(result: ShowFormObject) {
    this.viewModel.saveShow(result);
  }

  fileList(f: CustomFile[], id: number) {
    if (id === this.imageUploadId) {
      if (f.length > 0) {
        this.formObject.imageToUpload = f[0].url;
        this.formObject.imageToUploadFormat = f[0].type;
      } else {
        this.formObject.imageToUpload = undefined;
        this.formObject.imageToUploadFormat = undefined;
      }
    } else if (id === this.videoUploadId) {
      this.formObject.videoToUpload = f.length > 0 ? f[0] : undefined;
    }
  }

  removeImageClicked() {
    if (this.formObject.existingImageId) {
      this.formObject.deleteImageId = this.formObject.existingImageId;
      this.formObject.existingImageId = null;
    }
    this.formObject.imageToUpload = null;
  }

  removeVideoClicked() {
    if (this.formObject.existingVideo) {
      this.formObject.deleteVideoId = this.formObject.existingVideo.id;
      this.formObject.existingVideo = null;
    }
    this.formObject.videoToUpload = null;
  }

  cancel() {
    this.router.navigate(['../..'], { relativeTo: this.activatedRoute, fragment: 'shows' }).then();
  }
}
