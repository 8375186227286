<app-content-container *ngIf="(viewModel.events$|async)?.length > 0" [unsetMinHeight]="true">
  <div class="featured-events-container">
    <div class="title-sm featured-events-title" i18n>Featured Events</div>
    <div class="row">
      <div class="col-6 col-md-4 col-lg-3 col-xl-2" *ngFor="let l of viewModel.events$|async">
        <div class="event-logo-container" (click)="eventClicked(l)">
        <img *ngIf="l.imgSrc$|async" [src]="l.imgSrc$|async" alt="">
        <div class="event-name" *ngIf="!(l.imgSrc$|async)">{{l.name}}</div>
        </div>
      </div>
    </div>
    <hr *ngIf="!hideBottomDivider">
  </div>
</app-content-container>
