<div class="d-flex mt-3 h-100 min-height-15">
  <app-loading
    [hidden]="!viewModel.programLoadingOpts?.isLoading"
    [options]="viewModel.programLoadingOpts">
  </app-loading>
  <app-data-table
    [tableData]="dataProvider"
    [tableFilter]="datatableFilter"
    [resetTable]="resetTable"
    [updateTableData]="updateTableData"
    [filterApplied]="tableFilterChanged"
    (rowClicked)="programClicked($event)"
    (secondaryButtonClicked)="programUploaderClicked()"
    (ctaButtonClicked)="addNewProgramClicked()"
    [tableOptions]="datatableOptions">
  </app-data-table>
  <app-program-feedback
    [hidden]="!viewModel.selectedFeedbackProgram"
    (closeButtonClicked)="viewModel.selectedFeedbackProgram = null"
    [program]="viewModel.selectedFeedbackProgram">
  </app-program-feedback>
</div>
