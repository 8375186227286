import {Deserializable} from '../../protocols/deserializable';
import {MediaType} from '../../enum/dto/media-type.enum';

export class VideoUpload implements Deserializable {
  public id: string;
  public fileName: string;
  public assetName: string;
  public uploadStatusId: UploadStatusType;
  public uploadStatus: string;
  public mediaType: MediaType;

  onDeserialize() {
  }
}

export enum UploadStatusType {
  InProgress = 1,
  Completed = 2,
}

