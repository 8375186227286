export class Radiobutton {

  public label: string;
  public selected: boolean;
  public disabled: boolean;
  public description: string;
  public subDescription: string;
  public id: number|string;
  public altId: number|string;
  public planDuration: string;

  public planPrice: string;

  constructor(label: string, selected = false, disabled = false, description?, id?, subDescription?, altId?, planDuration?) {
    this.label = label;
    this.selected = selected;
    this.disabled = disabled;
    this.description = description;
    this.subDescription = subDescription;
    this.planPrice = subDescription;
    this.planDuration = planDuration;
    this.id = id;
    this.altId = altId;
  }

  getPaymentDuration(): string {
    switch (true) {
      case (typeof this.id === 'string' && this.id.toLowerCase().startsWith($localize`event_`) && this.planDuration === 'day'):
        return $localize`Daily Pass`;
      case (typeof this.id === 'string' && this.id.toLowerCase().startsWith($localize`event_`)):
        return $localize`Event Pass`;
      case (this.planDuration === 'year'):
        return $localize`Annually`;
      case (this.planDuration === 'month'):
        return $localize`Monthly`;
      case (this.planDuration === 'day'):
        return $localize`Daily`;
      default:
        return this.planDuration.slice(0, 1).toUpperCase() + this.planDuration.slice(1);
    }
  }
}
