import {Component, EventEmitter} from '@angular/core';
import {BaseComponent} from '../../../models/base/base-component';
import {PlansViewModel} from './plans-view-model';
import {TabBarItem} from '../../../models/shared/stylesheet/tab-bar-item';
import {UsersViewModel} from '../users/users-view-model';
import {AdminUsersComponent} from '../users/admin-users/admin-users.component';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
  providers: [PlansViewModel],
})
export class PlansComponent extends BaseComponent {

  tabs: TabBarItem[] = [];

  constructor(
    public viewModel: PlansViewModel
  ) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
    this.setupTabBar();
  }

  setupTabBar() {
    this.tabs = [];
    const discounts = new TabBarItem(AdminUsersComponent, 2, $localize`Discounts`, '');
    this.tabs.push(...[ discounts]);
  }
}
