import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DefaultLayoutComponent} from './views/default/default-layout/default-layout.component';
import {AppAsideModule, AppSidebarModule} from '@coreui/angular';
import {SidebarNavComponent} from './views/default/sidebar-nav/sidebar-nav.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {StylesheetModule} from './views/stylesheet/stylesheet.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AuthInterceptorInterceptor} from './services/interceptors/auth-interceptor.interceptor';
import {SharedModule} from './views/shared/shared.module';
import {RouteReuseStrategy} from '@angular/router';
import {CacheRouteReuseStrategy} from './services/strategy/cache-route-reuse.strategy';
import { NgxPopperModule } from 'ngx-popper';
import {TopNavComponent} from './views/default/top-nav/top-nav.component';
import {SwiperModule} from 'swiper/angular';
import {AuthModule} from './views/auth/auth.module';
import {AdminModule} from './views/admin/admin.module';
import {TeamModule} from './views/team/team.module';
import 'src/app/utils/replay-subject.extensions';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    ...APP_CONTAINERS,
    SidebarNavComponent,
    TopNavComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppSidebarModule,
    AppAsideModule,
    AdminModule,
    AuthModule,
    TeamModule,
    StylesheetModule,
    SwiperModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      maxOpened: 5,
      toastClass: 'custom-toast ngx-toastr'
    }),
    NgxPopperModule.forRoot({
      applyClass: 'custom-popper-content',
      applyArrowClass: 'd-none',
      hideOnClickOutside: true,
      hideOnScroll: true,
      preventOverflow: false,
      positionFixed: true,
      trigger: 'click',
      placement: 'bottom-end',
      styles: {
        'background-color': '#FEFEFE'
      },
      popperModifiers: {
        flip: {
          behavior: ['right', 'bottom', 'top']
        }
      }
    }),
    SharedModule,
  ],
  providers: [
    NgbActiveModal,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
