import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Radiobutton} from '../../../../../models/shared/stylesheet/radiobutton';
import {RadiobuttonContainerOptions} from '../../../../../models/shared/stylesheet/radiobutton-container-options';

@Component({
  selector: 'app-radiobutton-container',
  templateUrl: './radiobutton-container.component.html',
  styleUrls: ['./radiobutton-container.component.scss'],
})
export class RadiobuttonContainerComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() radiobuttons: Radiobutton[] = [];
  @Input() radioContainerOptions: RadiobuttonContainerOptions = new RadiobuttonContainerOptions(false);
  @Output() changed = new EventEmitter<Radiobutton[]>();

  @Input() isBoxed: boolean = false;

  public cid = Math.random().toString(24);
  public refresh = new EventEmitter<any>();
  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.radiobuttons.length > 0) {
      setTimeout(() => {
        const selectedRadio = this.radiobuttons.find(rb => rb.selected);
        if (selectedRadio) {
          // User has already selected a radio button, so keep that selection
          this.emitToOutput();
        } else {
          // User has not selected anything, select the last item in the array as default
          this.radiobuttons[this.radiobuttons.length - 1].selected = true;
          this.emitToOutput();
        }
      }, 50);
    }
  }


  public selectSingleOptionClicked(radiobutton: Radiobutton) {
    this.radioContainerOptions.touched = true;
    this.radiobuttons.forEach((rb) => {
      if(rb !== radiobutton) {
        rb.selected = false;
      }
    });
    this.emitToOutput();
  }
  emitToOutput() {
    this.changed.emit(this.radiobuttons);
  }

}
