import {Component, Input} from '@angular/core';
import {FeaturedEventsRowViewModel} from './featured-events-row-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {HydratedEvent} from '../../../../models/resources/hydrated-event';
import {Router} from '@angular/router';

@Component({
  selector: 'app-featured-events-row',
  templateUrl: './featured-events-row.component.html',
  styleUrls: ['./featured-events-row.component.scss'],
  providers: [FeaturedEventsRowViewModel],
})
export class FeaturedEventsRowComponent extends BaseComponent {

  @Input() hideBottomDivider: boolean = false;

  constructor(
    public viewModel: FeaturedEventsRowViewModel,
    public router: Router,
  ) {
    super();
  }

  setupViews() {
  }

  setupBindings() {
  }

  eventClicked(l: HydratedEvent) {
    this.router.navigate([`/home/events/${l.id}`]).then();
  }

}
