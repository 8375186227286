import {Injectable} from '@angular/core';
import {SessionService} from '../services/session-service';
import {BaseDomainModel} from '../models/base/base-domain-model';
import '../utils/observable.extensions';
import {ProgramApi} from '../api/program-api';
import {forkJoin, Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {ImageApi} from '../api/image-api';
import {Program} from '../models/program/program';
import {ProgramFormObject} from '../models/program/program-form-object';
import {GenerateUploadUrlRequest} from '../models/image/requests/generate-upload-url-request';
import {MediaType} from '../models/enum/dto/media-type.enum';
import * as moment from 'moment-timezone';
import {HydratedProgram} from '../models/program/hydrated-program';
import {ImageSize} from '../models/enum/dto/image-size.enum';
import {HydratedShow} from '../models/program/hydrated-show';
import {ShowFormObject} from '../models/program/show-form-object';
import {Show} from '../models/program/show';
import {VideoUpload} from '../models/image/dto/video-upload';
import {isObservable} from 'rxjs/internal-compatibility';
import {SeasonApi} from '../api/season-api';
import {Season} from '../models/resources/season';

@Injectable({
  providedIn: 'root'
})
export class AdminProgramDomainModel extends BaseDomainModel {

  constructor(
    private sessionService: SessionService,
    private programApi: ProgramApi,
    private seasonApi: SeasonApi,
    private imageApi: ImageApi,
  ) {
    super();
    this.init();
  }

  public init() {
  }

  getLeagueHydratedPrograms(leagueId: number): Observable<HydratedProgram[]> {
    return this.programApi.getLeagueHydratedPrograms(leagueId);
  }

  getEventHydratedPrograms(eventId: number): Observable<HydratedProgram[]> {
    return this.programApi.getEventHydratedPrograms(eventId);
  }

  getVenueHydratedPrograms(venueId: number): Observable<HydratedProgram[]> {
    return this.programApi.getVenueHydratedPrograms(venueId);
  }

  getHydratedShow(showId: string): Observable<HydratedShow> {
    return this.programApi.getHydratedShow(showId)
      .pipe(tap(p => p.imgSrc$ = this.imageApi.getHydratedShowImage(p, ImageSize.Medium)));
  }

  getLeagueHydratedShows(leagueId: number): Observable<HydratedShow[]> {
    return this.programApi.getAdminLeagueHydratedShows(leagueId);
  }

  getEventHydratedShows(eventId: number): Observable<HydratedShow[]> {
    return this.programApi.getAdminEventHydratedShows(eventId);
  }

  saveProgram(programFormObject: ProgramFormObject): Observable<any> {
    const program = this.getProgramFromProgramFormObject(programFormObject);
    let saveReq: Observable<Program>;
    if (!!programFormObject.leagueId) {
      saveReq = !programFormObject.program.id ?
        this.programApi.createLeagueProgram(program, programFormObject.leagueId) :
        this.programApi.updateLeagueProgram(program, programFormObject.leagueId);
    }
    if (!!programFormObject.venueId) {
      saveReq = !programFormObject.program.id ?
        this.programApi.createVenueProgram(program, programFormObject.venueId) :
        this.programApi.updateVenueProgram(program, programFormObject.venueId);
    }
    if (!!programFormObject.eventId) {
      saveReq = !programFormObject.program.id ?
        this.programApi.createEventProgram(program, programFormObject.eventId) :
        this.programApi.updateEventProgram(program, programFormObject.eventId);
    }
    return saveReq.pipe(
      switchMap(updatedProgram =>
        forkJoin([
          this.removeProgramImageRequest(programFormObject, updatedProgram),
          this.createProgramImageRequest(programFormObject, updatedProgram)
        ])
      )
    );
  }

  completeProgram(program: Program): Observable<any> {
    return this.programApi.completeProgram(program, program.id, program.leagueId);
  }

  deleteLeagueProgram(program: Program): Observable<any> {
    return this.programApi.deleteLeagueProgram(program.leagueId, program.id);
  }

  deleteEventProgram(program: Program): Observable<any> {
    return this.programApi.deleteEventProgram(program.eventId, program.id);
  }

  saveShow(showFormObject: ShowFormObject): Observable<any> {
    const show = this.getShowFromShowFormObject(showFormObject);
    let saveReq: Observable<Show>;
    if (!!showFormObject.leagueId) {
      saveReq = !showFormObject.show.id ?
        this.programApi.createLeagueShow(show, showFormObject.leagueId) :
        this.programApi.updateLeagueShow(show, showFormObject.leagueId);
    }
    if (!!showFormObject.eventId) {
      saveReq = !showFormObject.show.id ?
        this.programApi.createEventShow(show, showFormObject.eventId) :
        this.programApi.updateEventShow(show, showFormObject.eventId);
    }
    return saveReq.pipe(
      switchMap(updatedShow =>
        forkJoin([
          this.uploadShowVideoRequest(showFormObject, updatedShow),
          this.deleteShowVideoRequest(showFormObject, updatedShow),
          this.removeShowImageRequest(showFormObject, updatedShow),
          this.createShowImageRequest(showFormObject, updatedShow)
        ])
      )
    );
  }

  uploadShowVideoRequest(showFormObject: ShowFormObject, updatedShow: Show): Observable<any> {
    if (!showFormObject.videoToUpload) {
      return of(null);
    }
    return this.programApi.uploadShowVideo(updatedShow.id, showFormObject.videoToUpload.file);
  }

  deleteShowVideoRequest(showFormObject: ShowFormObject, updatedShow: Show): Observable<any> {
    if (!showFormObject.deleteVideoId) {
      return of(null);
    }
    return this.programApi.deleteShowUpload(updatedShow.id, showFormObject.deleteVideoId);
  }

  createProgramImageRequest(programFormObject: ProgramFormObject, updatedProgram: Program): Observable<any> {
    if (!programFormObject.imageToUpload) {
      return of(null);
    }

    const uploadLogoReq = new GenerateUploadUrlRequest();
    uploadLogoReq.mediaType = programFormObject.imageToUploadFormat as MediaType;
    uploadLogoReq.fileName = new Date().getTime() + programFormObject.imageToUploadFormat.replace('image/', '.');
    return this.imageApi.createProgramImage(updatedProgram.id, uploadLogoReq).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, programFormObject.imageToUpload.toString(), uploadLogoReq.fileName)
        .pipe(tap(a => this.imageApi.imageUploadSuccessful('program', updatedProgram.id, programFormObject.imageToUpload)));
    }));
  }

  createShowImageRequest(showFormObject: ShowFormObject, updatedShow: Show): Observable<any> {
    if (!showFormObject.imageToUpload) {
      return of(null);
    }

    const uploadLogoReq = new GenerateUploadUrlRequest();
    uploadLogoReq.mediaType = showFormObject.imageToUploadFormat as MediaType;
    uploadLogoReq.fileName = new Date().getTime() + showFormObject.imageToUploadFormat.replace('image/', '.');
    return this.imageApi.createShowImage(updatedShow.id, uploadLogoReq).pipe(switchMap(uploadLogoAsset => {
      const uploadUrl = uploadLogoAsset.links[0].presignedUrl;
      return this.imageApi.putImageUploadUrl(uploadUrl, showFormObject.imageToUpload.toString(), uploadLogoReq.fileName)
        .pipe(tap(a => this.imageApi.imageUploadSuccessful('show', updatedShow.id, showFormObject.imageToUpload)));
    }));
  }

  removeProgramImageRequest(programFormObject: ProgramFormObject, updatedProgram: Program): Observable<any> {
    if (!programFormObject.deleteImageId) {
      return of(null);
    }
    return this.programApi.deleteProgramImage(updatedProgram.id, programFormObject.deleteImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('program', updatedProgram.id);
      }));
  }

  removeShowImageRequest(showFormObject: ShowFormObject, updatedShow: Show): Observable<any> {
    if (!showFormObject.deleteImageId) {
      return of(null);
    }
    return this.programApi.deleteShowImage(updatedShow.id, showFormObject.deleteImageId)
      .pipe(tap(() => {
        this.imageApi.clearCachedImages('show', updatedShow.id);
      }));
  }

  getProgramFromProgramFormObject(programFormObject: ProgramFormObject): Program {
    const program = Object.assign(new Program(), programFormObject.program);
    program.imgSrc$ = undefined;
    if (programFormObject.programTime && moment(programFormObject.programDate).isValid()) {
      const programDateString = moment(programFormObject.programDate).format(moment.HTML5_FMT.DATE);
      const programStartMoment = moment(`${programDateString}T${programFormObject.programTime}`, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      program.startDateUtc = programStartMoment.tz(programFormObject.programTimeZone, true).toDate();
    }

    if (!program.name) {
      program.name = undefined;
    }
    if (!program.homeTeamId) {
      program.homeTeamId = undefined;
    }
    if (!program.visitingTeamId) {
      program.visitingTeamId = undefined;
    }
    if (!program.leagueId) {
      program.leagueId = undefined;
    }
    if (!program.eventId) {
      program.eventId = undefined;
    }
    if (!program.venueId) {
      program.venueId = undefined;
    }
    if (!program.durationInMinutes) {
      program.durationInMinutes = undefined;
    }
    if (!program.pixellotEventStartTimeOffsetInMinutes) {
      program.pixellotEventStartTimeOffsetInMinutes = undefined;
    }
    if (!program.programStatusId) {
      program.programStatusId = undefined;
    }
    if (!program.playCount) {
      program.playCount = undefined;
    }
    return program;
  }

  getShowFromShowFormObject(showFormObject: ShowFormObject): Show {
    const show = Object.assign(new Show(), showFormObject.show);
    show.imgSrc$ = undefined;
    return show;
  }

  getHydratedProgram(programId: string): Observable<HydratedProgram> {
    return this.programApi.getHydratedProgram(programId)
      .pipe(tap(p => p.imgSrc$ = this.imageApi.getHydratedProgramImage(p, ImageSize.Medium)));
  }

  getHydratedProgramById(programId: string): Observable<HydratedProgram> {
    return this.programApi.getHydratedProgram(programId);
  }

  getShowUploads(showId: string): Observable<VideoUpload[]> {
    return this.programApi.getShowUploads(showId);
  }
}
