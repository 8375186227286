<app-content-container *ngIf="(viewModel.leagues$|async)?.length > 0" [unsetMinHeight]="true">
  <div class="featured-leagues-container">
    <div class="title-sm featured-leagues-title" i18n>Featured Leagues</div>
    <div class="row">
      <div class="col-6 col-md-4 col-lg-3 col-xl-2" *ngFor="let l of viewModel.leagues$|async">
        <div class="league-logo-container" (click)="leagueClicked(l)">
        <img *ngIf="l.imgSrc$|async" [src]="l.imgSrc$|async" alt="">
        <div class="league-name" *ngIf="!(l.imgSrc$|async)">{{l.name}}</div>
        </div>
      </div>
    </div>
    <hr *ngIf="!hideBottomDivider">
  </div>
</app-content-container>
