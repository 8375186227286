import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {AccountDomainModel} from '../../../domainModels/account-domain-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {ToastService} from '../../../services/toast-service';
import {Router} from '@angular/router';
import {indicate} from '../../../utils/observable.extensions';
import {SessionService} from '../../../services/session-service';
import {HydratedUser} from '../../../models/account/dto/hydrated-user';

@Injectable()
export class ProfileViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false, true, 200);
  fetchUserSubject = new BehaviorSubject<void>(undefined);
  user$ = combineLatest(
    [this.fetchUserSubject,
      this.domainModel.sessionContainer$.pipe(map(s => s?.user))
    ]).pipe(map(([, u]) => u));

  constructor(private domainModel: AccountDomainModel,
              private toastService: ToastService,
              private sessionService: SessionService,
              private router: Router,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  updateUser(user: HydratedUser) {
    this.domainModel.updateAdmin(user)
      .pipe(indicate(this.loadingOpts, $localize`Saving User`))
      .subscribe((updatedUser) => {
        const remember = this.sessionService.sessionContainer.getValue()?.rememberSession;
        const userRole = this.sessionService.getRole();
        user.timeStampVal = updatedUser.timeStampVal;
        this.sessionService.setUser(user, userRole, false, remember);
        this.fetchUserSubject.next();
        this.toastService.publishSuccessMessage($localize`User Updated`, null);
      }, error => {
        this.toastService.publishError(error);
      });
  }
}
