import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {ResourceDomainModel} from '../../../domainModels/resource-domain-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {indicateOnNext} from '../../../utils/observable.extensions';

@Injectable()
export class PlansViewModel extends BaseViewModel {

  loadingOpts = LoadingOptions.defaultLight(false, false);
  // plans$ = this.domainModel.allPlans$.pipe(indicateOnNext(this.loadingOpts, $localize`Loading Plans`));

  constructor(private domainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}
