import { Component, EventEmitter } from '@angular/core';
import { AdminUsersViewModel } from './admin-users-view-model';
import { BaseComponent } from '../../../../models/base/base-component';
import { Tabbable } from '../../../../models/protocols/tabbable';
import { DatatableOptions } from '../../../../models/shared/stylesheet/datatable-options';
import { DatatableFilter } from '../../../../models/shared/datatable-filter';
import { BaseDatatableDataProvider } from '../../../../models/base/base-datatable-data-provider';
import { DatatableData } from '../../../../models/protocols/datatable-data';
import { DatatableColumn, DatatableColumnType } from '../../../../models/shared/stylesheet/datatable-column';
import { User } from '../../../../models/account/dto/user';
import {
  DatatableColumnFilter,
  DatatableColumnFilterListItem
} from '../../../../models/shared/stylesheet/datatable-column-filter';
import { InviteUserModalComponent } from '../invite-user-modal/invite-user-modal.component';
import { ModalUtils } from '../../../../utils/modal-utils';
import { CreateAdminRequest } from '../../../../models/account/requests/create-admin-request';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditUserModalComponent } from '../edit-user-modal/edit-user-modal.component';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss'],
  providers: [AdminUsersViewModel],
})
export class AdminUsersComponent extends BaseComponent implements Tabbable {

  public datatableOptions: DatatableOptions = new DatatableOptions();
  public datatableFilter: DatatableFilter = new DatatableFilter();
  public userDataProvider: BaseDatatableDataProvider = new BaseDatatableDataProvider();
  public tableFilterChanged = new EventEmitter();
  public resetUsersTable = new EventEmitter();
  public updateUsersTableData: EventEmitter<BaseDatatableDataProvider> = new EventEmitter<BaseDatatableDataProvider>();

  constructor(
    public viewModel: AdminUsersViewModel,
    private modalService: NgbModal,
  ) {
    super();
  }

  setupViews() {
    this.setupDatatable();
  }

  setupBindings() {
    this.viewModel.admins$.notNull().subscribe(u => {
      const dataProvider = new BaseDatatableDataProvider();
      dataProvider.data = u;
      this.updateUsersTableData.next(dataProvider);
    });
  }

  userClicked(data: DatatableData) {
    const user = data as User;
    this.openEditUserModal(user);
  }

  public setupDatatable() {
    this.datatableOptions.columns = this.getDatatableColumns();
    this.datatableOptions.ctaButtonText = $localize`Invite User`;
    this.setDatatableFilter();
  }

  getDatatableColumns() {
    const columns: DatatableColumn[] = [];

    const firstName = new DatatableColumn(
      'firstName',
      $localize`First Name`,
      DatatableColumnType.Text,
      (user: User): string => {
        return user?.firstName;
      }
    );
    firstName.isDefaultSortColumn = true;
    columns.push(firstName);

    const lastName = new DatatableColumn(
      'lastName',
      $localize`Last Name`,
      DatatableColumnType.Text,
      (user: User): string => {
        return user?.lastName;
      }
    );
    columns.push(lastName);

    const email = new DatatableColumn(
      'email',
      $localize`Email`,
      DatatableColumnType.Text,
      (user: User): string => {
        return user?.email;
      }
    );
    columns.push(email);

    const status = new DatatableColumn(
      'status',
      $localize`Status`,
      DatatableColumnType.Text,
      (user: User): string => {
        return user?.active ? $localize`Active` : $localize`Inactive`;
      }
    );
    status.className = 'custom-datatable-header text-right mr-2';
    status.headerClassName = 'custom-datatable-header text-right mr-2';
    status.columnFilter = this.getStatusColumnFilter();
    columns.push(status);

    return columns;
  }

  getStatusColumnFilter(): DatatableColumnFilter {
    const items = [
      new DatatableColumnFilterListItem($localize`Active`, $localize`Active`),
      new DatatableColumnFilterListItem($localize`Inactive`, $localize`Inactive`),
      new DatatableColumnFilterListItem($localize`All`, null, true),
    ];
    return new DatatableColumnFilter(items);
  }

  private setDatatableFilter() {
    // Basic filter setup
    this.datatableFilter.enableFilterCheckbox = false;
    this.datatableFilter.enableResultsDisplayed = false;
    this.datatableFilter.searchPlaceholderText = $localize`Search by name or email`;
    // Clear query text
    this.datatableFilter.searchQueryString = '';
    // Set custom filters
    this.datatableFilter.customFilters = [];
  }

  openEditUserModal(user: User) {
    const modalRef = this.modalService.open(EditUserModalComponent, ModalUtils.defaultModalOptions());
    (modalRef.componentInstance as EditUserModalComponent).initWithUser(user);
    // modalRef.result.then((updatedUser: User) => {
    //   this.viewModel.updateAdmin(updatedUser);
    // }, () => {
    // });

    modalRef.componentInstance.modalFormData.subscribe((updatedUser: User) => {
      this.viewModel.updateAdmin(updatedUser, modalRef);
    }, reason => {
    });

    // Handle user conflict object
    this.viewModel.concurrentUpdateUser.subscribe(conflictObject => {
      modalRef.componentInstance.concurrentUpdate$.next(conflictObject);
    });
  }

  openInviteUserModal() {
    const modalRef = this.modalService.open(InviteUserModalComponent, ModalUtils.defaultModalOptions());
    modalRef.result.then((res: CreateAdminRequest) => {
      this.viewModel.createAdmin(res, modalRef);
    }, () => {
    });
  }

}
