<app-content-container [narrow]="true">
  <div class="py-4 h-100 position-relative min-height-20">
    <app-loading [hidden]="!(viewModel.loadingOpts?.isLoading)"
                 [options]="viewModel.loadingOpts">
    </app-loading>
    <app-form-group
      *ngIf="formItems?.length > 0"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (formCancelled)="cancel()"
      (formSubmitted)="formSubmitted($event)">
    </app-form-group>    
  </div>
</app-content-container>
