import { Component, Output, EventEmitter } from '@angular/core';
import { FormInputItem, FormInputType, FormItemType } from '../../../../models/shared/stylesheet/form-input-item';
import { FormGroupStyling } from '../../../../models/shared/stylesheet/form-group-styling';
import { FormOptions } from '../../../../models/shared/stylesheet/form-options';
import { CreateSportTypeRequest } from '../../../../models/lookup/requests/create-sport-type-request';
import { BaseModal } from '../../../../models/base/base-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SportType } from '../../../../models/lookup/sport-type';
import { SportsViewModel } from '../sports-view-model';
import { BaseDatatableDataProvider } from '../../../../models/base/base-datatable-data-provider';
import { Selectable } from '../../../../models/protocols/selectable';
import { DeserializeHelper } from '../../../../models/protocols/deserializable';
import ConflictRecordUtils from '../../../../utils/conflict-record-utils';
@Component({
  selector: 'app-add-new-sport-modal',
  templateUrl: './add-new-sport-modal.component.html',
  styleUrls: ['./add-new-sport-modal.component.scss']
})
export class AddNewSportModalComponent extends BaseModal {

  formItems: FormInputItem[] = [];
  formStyling = new FormGroupStyling();
  formOptions = new FormOptions();
  formObject: CreateSportTypeRequest = new CreateSportTypeRequest();
  sport: SportType = null;
  sports: SportType[] = [];
  pixellotProductionTypes: string[] = [];
  isEditing: boolean = false;

  public concurrentUpdateFlag: boolean = false;
  currentSport: any = null;
  currentUserChanges: any = [];
  currentUserChangesWithNestedProp: any = [];
  anotherUserChanges: any = [];
  isLoading: boolean = false;
  @Output() modalFormData: EventEmitter<any> = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
    private viewModel: SportsViewModel,
  ) {
    super();
  }

  cancel() {
    this.activeModal.dismiss();
    this.modalFormData.emit(null);
  }

  setupBindings() {
    this.viewModel.pixellotProductionTypes$.notNull().subscribe(s => {
      this.setupFormItems();
    }).addTo(this.subscriptions);

    this.viewModel.concurrentUpdateUser$.subscribe(conflictObject => {
      if (conflictObject) {
        this.viewModel.getSportType(conflictObject.id).subscribe(refreshSport => {
          this.concurrentUpdateFlag = true;
          this.compareObjects(this.sport, conflictObject, refreshSport);
          this.setUserObjAfterConflict(conflictObject, refreshSport);
          this.formObject = DeserializeHelper.deserializeToInstance(SportType, refreshSport);
          this.setupFormItems();
        });
      } else {
        this.concurrentUpdateFlag = false;
      }
    });
  }

  setupViews() {
    this.setupFormOptions();
    this.setupFormStyling();
    if (this.sport) {
      this.formObject.id = this.sport.id;
      this.formObject.name = this.sport.name;
      this.formObject.active = this.sport.active;
      this.formObject.pixellotEnumValue = this.sport.pixellotEnumValue;
      this.formObject.timeStampVal = this.sport.timeStampVal;
    }
  }

  setupFormItems() {
    const userChanges = {
      currentUserChanges: this.currentUserChanges,
      anotherUserChanges: this.anotherUserChanges
    };

    const items: FormInputItem[] = [];

    const name = new FormInputItem();
    name.inputName = 'name';
    name.inputType = FormInputType.Text;
    name.label = $localize`Name`;
    name.placeholder = $localize`Sport Name`;
    name.bindingProperty = 'name';
    name.required = true;
    const nameData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.sport, 'Name', 'name') : '';
    name.conflictType = nameData?.borderColor || nameData;
    name.tooltipText = nameData?.tooltipText;
    items.push(name);

    const sportType = new FormInputItem();
    sportType.inputName = 'pixellotEnumValue';
    sportType.itemType = FormItemType.Dropdown;
    sportType.label = $localize`Calibration Type`;
    sportType.bindingProperty = 'pixellotEnumValue';
    sportType.placeholder = $localize`Sport Type`;
    sportType.dropdownOptions = this.viewModel.getPixellotProductionTypeOptions();
    sportType.required = true;
    const sportTypeData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.sport, 'Type', 'pixellotEnumValue') : '';
    sportType.conflictType = sportTypeData?.borderColor || sportTypeData;
    sportType.tooltipText = sportTypeData?.tooltipText;
    items.push(sportType);

    const active = new FormInputItem();
    active.itemType = FormItemType.Switch;
    active.inputName = 'active';
    active.label = $localize`Active`;
    active.placeholder = $localize`active`;
    active.bindingProperty = 'active';
    active.overrideFullWidth = true;
    const sportStatusData: any = this.concurrentUpdateFlag ?
      ConflictRecordUtils.assignConflictIssueProperty(userChanges, this.sport, 'Status', 'active') : '';
    active.conflictType = sportStatusData?.borderColor || sportStatusData;
    active.tooltipText = sportStatusData?.tooltipText;
    items.push(active);

    this.formItems = items;
  }

  setupFormStyling() {
    this.formStyling.numberColumns = 1;
    // primary buttons
    this.formStyling.primaryButtonFloat = 'right';
    this.formStyling.cancelButtonText = $localize`Cancel`;
    this.formStyling.primaryButtonClass = 'ml-3';
    this.formStyling.primaryButtonContainerClass = 'mb-0 d-flex justify-content-end';
    this.formStyling.resetButtonText = '';
    this.formStyling.submitButtonText = $localize`Save Sport`;
  }

  setupFormOptions() {
    this.formOptions.performNonEmptyInitialValidation = false;
    this.formOptions.emitInitialValuesAfterSetup = false;
  }

  formSubmitted() {
    // this.activeModal.close(this.formObject);
    this.modalFormData.emit(this.formObject);
  }

  getModalTitle(): string {
    return this.isEditing ? $localize`Edit Sport` : $localize`Add a New Sport`;
  }

  compareObjects(currentObj, conflictObj, refreshObj) {
    const currentConflictObj = ConflictRecordUtils.diff(currentObj, conflictObj, 'red');
    const currentRefreshObj = ConflictRecordUtils.diff(currentObj, refreshObj, 'orange');
    this.currentUserChanges = currentRefreshObj.currentUserChanges;
    this.currentUserChangesWithNestedProp = currentRefreshObj.currentUserChangesWithNestedProp;
    this.anotherUserChanges = currentRefreshObj.anotherUserChanges;
  }

  setUserObjAfterConflict(conflictObj, refreshObj) {
    const changes = this.currentUserChangesWithNestedProp.filter(x => !this.anotherUserChanges.includes(x));
    changes.forEach(element => {
      const val = ConflictRecordUtils.getObjProp(conflictObj, element);
      this.updateObjProp(refreshObj, val, element);
    });
  }

  updateObjProp(obj, value, propPath) {
    const [head, ...rest] = propPath.split('.');

    if (!rest.length) {
      obj[head] = value;
    } else {
      this.updateObjProp(obj[head], value, rest.join('.'));
    }
  }
}
