<div class="datatable-wrapper">

  <!-- Filter Options -->
  <div [ngClass]="tableFilter.filterContainerClass"
       [class.sticky-modal-header]="tableOptions.stickyModalHeader"
       class="datatable-filters">

    <div class="row d-flex">
      <!-- Search Input -->
      <div *ngIf="tableFilter.enableSearch"
           class="col-12 table-filter-form">
        <div class="row d-flex justify-content-between flex-nowrap">
          <div class="col col-lg-6">
            <form class="custom-textfield-form pl-0">
              <label class="custom-textfield-label" for="searchQueryString">
                {{ tableFilter.searchLabelText }}
              </label>
              <input (keyup)="onQueryStringChange($event)"
                     [(ngModel)]="tableFilter.searchQueryString"
                     class="custom-textfield search-textfield mb-0" id="searchQueryString"
                     name="searchQueryString"
                     placeholder="{{ tableFilter.searchPlaceholderText }}"
                     type="text">
              <button (click)="clearSearch()" class="clear-search" type="reset">
                CLEAR
              </button>
              <span class="custom-textfield-error"></span>
            </form>
          </div>
          <div  class="d-flex align-items-end">

          </div>
          <div *ngIf="tableOptions.ctaButtonText?.length > 0 || tableOptions.secondaryButtonText?.length > 0" class="col-auto d-flex align-items-end">
            <button *ngIf="tableOptions.secondaryButtonText?.length > 0"
                    (click)="secondaryButtonClicked.emit()"
                    class="custom-button table-cta-button ml-3"
                    [ngClass]="tableOptions.secondaryButtonClass">
              <img *ngIf="tableOptions.secondaryButtonIconSrc?.length > 0"
                   [src]="tableOptions.secondaryButtonIconSrc"
                   alt="">
              {{tableOptions.secondaryButtonText}}
            </button>
            <button *ngIf="tableOptions.ctaButtonText?.length > 0"
                    (click)="ctaButtonClicked.emit()"
                    class="custom-button table-cta-button ml-3"
                    [ngClass]="tableOptions.ctaButtonClass">
              <img *ngIf="tableOptions.ctaButtonIconSrc?.length > 0"
                   [src]="tableOptions.ctaButtonIconSrc"
                   alt="">
              {{tableOptions.ctaButtonText}}
            </button>
          </div>
        </div>
      </div>

      <!--   Checkbox Filter     -->
      <div *ngIf="tableFilter.enableFilterCheckbox"
           class="col-sm-6 col-lg-3 table-filter-form">
        <app-checkbox
          (clickEvent)="filterChanged()"
          [checkbox]="tableFilter.checkbox"
          [checked]="tableFilter.checkbox?.checked"
          [disabled]="!tableFilter.enableFilterCheckbox"
          [label]="tableFilter.checkbox?.label">
        </app-checkbox>
      </div>

      <!--   Reset Filters     -->
      <div *ngIf="tableFilter.filtersAreActive()"
           class="col-sm-6 col-lg-3 table-filter-form clear-filters-container">
        <button (click)="clearFilters()"
                class="custom-button outlined-button clear-filters-button">
          Clear Filters
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">

      <!-- Iterate over custom filters -->
      <ng-container *ngFor="let cf of tableFilter.customFilters; let customFilterIndex = index">
        <div [ngClass]="{'pl-0' : customFilterIndex === 0 }"
             class="col-sm-6 col-lg-3 table-filter-form">
          <app-drop-down
            (selection)="onCustomFilterChange(cf, $event)"
            [currentSelection]="cf.selectedOption"
            [dropdowns]="cf.activeFilterOptions"
            [hint]="cf.hintOptions"
            [labelText]="cf.label">
          </app-drop-down>
        </div>
      </ng-container>

      <!-- Pagination Result Count -->
      <div *ngIf="tableFilter.enableResultsDisplayed"
           class="col-sm-6 col-lg-3 table-filter-form pr-0">
        <form class="custom-textfield-form"
              style="padding: 0;">
          <label class="custom-textfield-label" for="numberOfResults">
            Number of Results
          </label>
          <select (ngModelChange)="entriesChanged($event)" [(ngModel)]="numberOfEntriesString" class="custom-dropdown"
                  id="numberOfResults" name="numberOfResults">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </form>
      </div>

    </div>
  </div>


  <!-- Table Results -->
  <div [ngClass]="{ 'sticky-modal-header' : tableOptions.stickyModalHeader}"
       class="row datatable-content">
    <div class="col-sm-12">

      <table class="table custom-table table-hover">
        <!-- Table Header -->
        <thead>
        <tr *ngIf="tableOptions && tableOptions.columns">
          <th (click)="headerClicked(column)"
              [ngClass]="{
               'disable-sorting' : column.disableSorting,
               'filter-open': column.columnFilter?.isOpen,
               'sorting_asc': isAscending(column),
               'sorting_desc': isDescending(column)
               }"
              *ngFor="let column of tableOptions.columns"
              [ngStyle]="{ 'width': column.getWidth() }"
              class="datatable-header {{column.headerClassName}}">

            <!-- Bulk Edit Checkbox -->
            <ng-container *ngIf="column.type === columnTypes.Checkbox">
              <label class="custom-checkbox-container" for="checkbox-bulk-all">
                <input (change)="selectAllClicked($event)" [checked]="tableOptions.bulkEditPercentageChecked === 1"
                       class="custom-checkbox"
                       id="checkbox-bulk-all"
                       [ngClass]="{ 'indeterminate': tableOptions.bulkEditPercentageChecked > 0 && tableOptions.bulkEditPercentageChecked < 1 }"
                       name="checkbox-all"
                       type="checkbox">
                <span class="custom-checkbox-label">
                  {{ getBulkEditSelectionCount() }}
                </span>
                <span class="custom-checkmark"></span>
              </label>
            </ng-container>

            <!-- Column Title -->
            <ng-container *ngIf="column.type !== columnTypes.Checkbox">
              {{ column.title }}
            </ng-container>

            <!-- Sorting Icon -->
            <span class="sorting-icon"></span>

            <!-- Column Filter Icon -->
            <ng-container *ngIf="column.columnFilter?.getIconSrc()">
              <img [src]="column.columnFilter?.getIconSrc()"
                   class="col-filter-icon"
                   (click)="$event.stopPropagation(); column.columnFilter.isOpen = true;"
                   alt=""
                   popper
                   (popperOnHidden)="column.columnFilter.isOpen = false;"
                   [popperPlacement]="column.columnFilter.popperPlacement"
                   [popper]="popperContent">
              <popper-content #popperContent>
                <div class="col-filter-list"
                     *ngIf="column.columnFilter.filterType === typeUtils.DatatableColumnFilterType.SelectionList">
                  <div class="col-filter-list-item"
                       *ngFor="let item of column.columnFilter.selectionListItems"
                       (click)="$event.stopPropagation(); columnFilterListItemSelected(column.columnFilter, item); popperContent.hide();"
                       [class.active]="column.columnFilter.selectedItem === item || (column.columnFilter.selectedItem === null && item.defaultFilterItem)">
                    {{item.displayName}}
                  </div>
                </div>
                <div class="date-range-filter" (click)="$event.stopPropagation();"
                     *ngIf="column.columnFilter.filterType === typeUtils.DatatableColumnFilterType.DateRangeFilter">
                  <mat-calendar (selectedChange)="columnFilterDateSelected(column.columnFilter, $any($event));" [selected]="column.columnFilter.selectedDateRange"
                                class="date-range-picker">
                  </mat-calendar>
                  <div class="d-flex justify-content-end px-2 pb-3">
                    <div class="text-link secondary label-md mr-3"
                         (click)="clearDateColumnFilter(column.columnFilter); popperContent.hide();"
                         i18n>
                      Clear
                    </div>
                    <div class="text-link label-md"
                         [class.disabled]="column.columnFilter.selectedDateRange?.start === null || column.columnFilter.selectedDateRange?.end === null"
                         (click)="applyDateColumnFilter(column.columnFilter); popperContent.hide();"
                         i18n>
                      Apply
                    </div>
                  </div>
                </div>
              </popper-content>
            </ng-container>
          </th>
        </tr>
        </thead>
        <!-- Table Body -->
        <tbody>
        <ng-container *ngFor="let rowData of tableData.displayedData; let rowIndex = index;">
          <tr [ngClass]="{ 'expanded-parent' : rowIndex === expandedIndex}">
            <ng-container *ngFor="let column of tableOptions.columns; let colIndex = index;">

              <!-- Handle DatatableColumnType.Text -->
              <td (click)="handleRowClicked(rowData, rowIndex)"
                  class="align-middle"
                  [ngClass]="column.getCustomCellClassName(rowData)"
                  *ngIf="column.type === columnTypes.Text">
                <div [ngClass]="column.className">
                  {{ getCellContent(column, rowData) }}
                  <!-- Add inline tooltip -->
                  <button [hidden]="!getTooltipContent(column, rowData)"
                          [ngbTooltip]="getTooltipContent(column, rowData)"
                          class="inline-tooltip">
                    <img [src]="column.tooltipIconSrc" alt="">
                  </button>
                </div>
                <div class="cell-subtext" *ngIf="getCellSubText(column, rowData)">
                  {{ getCellSubText(column, rowData) }}
                  <span class="tooltip-list-button"
                        [tooltipClass]="'tooltip-list-window'"
                        [hidden]="!getTooltipListTitle(column, rowData)"
                        [ngbTooltip]="tooltipContent">
                    {{getTooltipListTitle(column, rowData)}}
                    <ng-template #tooltipContent>
                      <div *ngFor="let listItem of getTooltipListContent(column, rowData)">
                        {{listItem}}
                      </div>
                    </ng-template>
                   </span>
                </div>
              </td>

              <!-- Handle DatatableColumnType.Checkbox -->
              <td *ngIf="column.type === columnTypes.Checkbox"
                  [ngClass]="column.getCustomCellClassName(rowData)"
                  class="checkbox-td">

                <label class="custom-checkbox-container"
                       for="checkbox-{{ rowIndex }}">
                  <input (change)="selectSingleOptionClicked($event, rowData)"
                         [checked]="isChecked(rowData)"
                         [disabled]="false"
                         [ngClass]="{ 'indeterminate': isNestedParentIndeterminate(rowData) }"
                         class="custom-checkbox"
                         id="checkbox-{{ rowIndex }}"
                         name="custom-cb"
                         type="checkbox">
                  <span class="custom-checkmark"></span>
                </label>

              </td>

              <!-- Handle DatatableColumnType.Label -->
              <td (click)="handleRowClicked(rowData, rowIndex)"
                  [ngClass]="column.getCustomCellClassName(rowData)"
                  *ngIf="column.type === columnTypes.Label">
                <div [hidden]="!getCellContent(column, rowData) || getCellContent(column, rowData) === ''"
                     [ngClass]="column.className"
                     class="datatable-label">
                  <span [ngStyle]="{ 'background-color' : rowData.getColor(),
                        'color': rowData.getTextColor() }"
                        class="custom-label neutral-label">
                    {{ getCellContent(column, rowData) }}
                  </span>
                </div>
              </td>

              <!-- Handle DatatableColumnType.Icon -->
              <td (click)="handleRowClicked(rowData, rowIndex)"
                  class="align-middle"
                  [ngClass]="column.getCustomCellClassName(rowData)"
                  *ngIf="column.type === columnTypes.Icon">
                <div [hidden]="!getCellContent(column, rowData) || getCellContent(column, rowData) === ''"
                     [ngClass]="column.className">
                  <img class="cell-icon"
                       (click)="column.iconClicked(rowData); $event.stopPropagation()"
                       [src]="getCellContent(column, rowData)"
                       alt="">
                </div>
              </td>

              <!-- Handle DatatableColumnType.Buttons -->
              <td (click)="handleRowClicked(rowData, rowIndex)"
                  [ngClass]="column.getCustomCellClassName(rowData)"
                  *ngIf="column.type === columnTypes.Actions">
                <ng-container *ngFor="let act of column.actions; let actionIndex = index;">
                  <button (click)="handleRowActionClicked($event, act, rowData)"
                          [ngbTooltip]="act.tooltipText"
                          class="datatable-action-button">
                    <img [src]="act.iconSrc" alt="">
                  </button>
                </ng-container>
              </td>

            </ng-container>
          </tr>
          <!-- Nested Data -->
          <tr *ngIf="rowIndex === expandedIndex" class="no-hover">
            <td [colSpan]="tableOptions.nestedColumns.length"
                class="nested-table-data-cell">
              <!-- Nested Data Table -->
              <table class="nested-table custom-table w-100">
                <!-- Nested Table Header -->
                <thead [ngClass]="{ 'hide-header' : tableOptions.hideNestedHeader }"
                       class="nested-table-header">
                <tr>
                  <th *ngFor="let nestedCol of tableOptions.nestedColumns"
                      [ngStyle]="{ 'width': nestedCol.getWidth() }">
                    {{ nestedCol.title }}
                  </th>
                </tr>
                </thead>
                <!-- Nested Table Content -->
                <tr *ngFor="let nestedRowData of tableData.getNestedCellData(rowData); let nestedRowIndex = index;">
                  <ng-container *ngFor="let nestedColumn of tableOptions.nestedColumns; let nestedColIndex = index;">

                    <!-- Handle DatatableColumnType.Text -->
                    <td (click)="handleNestedRowClicked(nestedRowData)"
                        *ngIf="nestedColumn.type === columnTypes.Text">
                      {{ getNestedCellContent(nestedColumn, nestedRowData) }}
                      <!-- Add inline tooltip -->
                      <button [hidden]="!getTooltipContent(nestedColumn, nestedRowData)"
                              [ngbTooltip]="getTooltipContent(nestedColumn, nestedRowData)"
                              class="inline-tooltip">
                        <img [src]="nestedColumn?.tooltipIconSrc" alt="">
                      </button>
                    </td>

                    <!-- Handle DatatableColumnType.Checkbox -->
                    <td *ngIf="nestedColumn.type === columnTypes.Checkbox"
                        class="checkbox-td">

                      <label class="custom-checkbox-container"
                             for="nested-checkbox-{{ nestedRowIndex }}">
                        <input (change)="selectSingleOptionClicked($event, nestedRowData)"
                               [checked]="isChecked(nestedRowData)"
                               [disabled]="isPreselected(nestedRowData)"
                               class="custom-checkbox"
                               id="nested-checkbox-{{ nestedRowIndex }}"
                               name="custom-cb"
                               type="checkbox">
                        <span class="custom-checkmark"></span>
                      </label>

                    </td>

                    <!-- Handle DatatableColumnType.Label -->
                    <td *ngIf="nestedColumn.type === columnTypes.Label">
                      <div
                        [hidden]="!getNestedCellContent(nestedColumn, nestedRowData) || getNestedCellContent(nestedColumn, nestedRowData) === '' "
                        [ngClass]="nestedColumn.className"
                        class="datatable-label">
                        <span [ngStyle]="{ 'background-color' : nestedRowData.getColor(),
                        'color': nestedRowData.getTextColor() }"
                              class="custom-label neutral-label">
                          {{ getNestedCellContent(nestedColumn, nestedRowData) }}
                        </span>
                      </div>
                    </td>

                    <!-- Handle DatatableColumnType.Buttons -->
                    <td *ngIf="nestedColumn.type === columnTypes.Actions">
                      <ng-container *ngFor="let act of nestedColumn.actions; let actionIndex = index;">
                        <button (click)="handleNestedRowActionClicked($event, act, nestedRowData)"
                                [ngbTooltip]="act.tooltipText"
                                class="datatable-action-button">
                          <img [src]="act.iconSrc" alt="">
                        </button>
                      </ng-container>
                    </td>

                  </ng-container>
                </tr>
              </table>
            </td>
          </tr>

        </ng-container>
        </tbody>
      </table>

      <div [hidden]="tableData.displayedData.length > 0"
           class="no-results-container">
        <div class="no-results-title">
          {{ getNoResultsTitle() }}
        </div>
        <div class="no-results-body">
          {{ getNoResultsText() }}
        </div>
        <button (click)="resetSearchAndFilters()"
                class="custom-button preferred-button no-results-button">
          Reset Search
        </button>
      </div>

      <!--   Footer   -->
      <div [hidden]="tableOptions.hideFooter || tableData.displayedData.length === 0" class="row mt-3"
           [ngClass]="tableOptions.footerClassName">
        <!--   Results Position   -->
        <div class="col-sm-12 col-lg-5 position-text-container">
          <div class="position-text">
            Showing
            <span class="position-text-bold">{{beginAmount() + 1}}</span>
            to
            <span
              class="position-text-bold">{{ endAmount() < tableData.filteredData.length ? endAmount() : tableData.filteredData.length}}</span>
            of
            <span class="position-text-bold">{{tableData.filteredData.length}}</span>
            results
          </div>
        </div>
        <!--   Pagination   -->
        <div class="col-sm-12 col-lg-7">
          <div class="pagination-container">
            <ul class="pagination">
              <li class="page-item previous" [class.disabled]="previousDisabled()">
                <a (click)="previousClicked()" tabindex="0" class="page-link">
                  <img class="pagination-arrow" [src]="'/assets/icons/dark/outline/arrow-left.svg'" alt=""/>
                </a>
              </li>
              <ng-container *ngFor="let page of this.pages()">
                <li *ngIf="isPageNumber(page)" class="page-item page-item-number"
                    [class.active]="isActivePage(page)">
                  <a class="page-link" (click)="pageClicked(page)">
                    {{ page }}
                  </a>
                </li>

                <li *ngIf="page === '...'" class="page-item page-item-ellipses">
                  <a aria-controls="DataTables_Table_0" data-dt-idx="5" tabindex="0"
                     class="page-link">
                    ...
                  </a>
                </li>
              </ng-container>
              <li class="paginate_button page-item next" [class.disabled]="nextDisabled()">
                <a (click)="nextClicked()" aria-controls="DataTables_Table_0" data-dt-idx="5" tabindex="0"
                   class="page-link">
                  <img class="pagination-arrow" [src]="'/assets/icons/dark/outline/arrow-right.svg'" alt=""/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
